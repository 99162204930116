import loginReducer from './loginSlice';
import authReducer from './authSlice';
import createPqrsReducer from './createPqrsSlice';

const rootReducer = {
  login: loginReducer,
  auth: authReducer,
  createPqrs: createPqrsReducer
};

export default rootReducer