import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';
import { User } from "../../models/dbModels/User";
import sharedServices from "../../services/sharedService";

export interface AuthState {
  isAuth: Boolean,
  userData?: User,
}

const initialState: AuthState = {
  isAuth: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    isAuthChange: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
      if(!action.payload) sharedServices.deleteToken();
    },
    userDataChange: (state, action: PayloadAction<User>) => {
      state.userData = action.payload;
    }
  }
})

export const { isAuthChange, userDataChange } = authSlice.actions;

export default authSlice.reducer;