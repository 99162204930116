import { useRef, useState, useEffect } from "react";
import { Field, FieldProps, Formik, FormikHelpers } from "formik";
import Button from "../../shared/button";
import * as S from "./EmailRecovery.style";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import InputForm from "../../shared/inputForm";
import SelectForm from "../../shared/selectForm";
import { useMutation } from "@apollo/client";
import { MUTATION_RECOVERY_EMAIL, MUTATION_RESET_PASS } from "../../../graphql/mutations/Auth";
import { TYPE_DOCUMENTS } from "../../../utils/constants";
import ReCAPTCHA from "react-google-recaptcha";
import ModalContainer from "../../shared/modals/modalContainer";
import Portal from "../../shared/modals/portal";
import CustomModal from "../../shared/modals/customModal";

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

function EmailRecovery() {
  const {t} = useTranslation("global");
  const [resMessage, setResMessage] = useState<string>('');
  const [errorCaptcha, setErrorCaptcha] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const emailRecoveryCaptchaRef = useRef<any>(null);

  const [emailRecovery, { loading: emailRecoveryLoading, error: emailRecoveryError, reset: emailRecoveryReset}] = useMutation(MUTATION_RECOVERY_EMAIL);

  interface  emailRecoveryFields {
    typeDocument: string,
    document: string,
  }  
  

  const handleSubmit = (values: emailRecoveryFields, formikHelpers: FormikHelpers<emailRecoveryFields>) => {
    
      // if (emailRecoveryCaptchaRef.current.getValue()) {
      //   console.log(emailRecoveryCaptchaRef.current.getValue()); 
      // }

      if (emailRecoveryCaptchaRef.current.getValue()) {
        setErrorCaptcha(false);
        resetPassFunction(values, formikHelpers);
      } else {
        setErrorCaptcha(true);
        setResMessage("Error de Captcha");
        emailRecoveryCaptchaRef.current.reset();
      }
  }

  const resetPassFunction = async (values: emailRecoveryFields, formikHelpers: FormikHelpers<emailRecoveryFields>) => {
      try {
        const { data : {
          recordarCorreo: { message },
        },
      } 
      = await emailRecovery({
        variables: {
          tipoDni: values.typeDocument.toString(),
          numerodni: values.document.toString().toLowerCase(),
          captchaToken: emailRecoveryCaptchaRef.current.getValue()
        },
      });

      setResMessage(message);
      formikHelpers.resetForm();
      emailRecoveryCaptchaRef.current.reset();
    } catch (err: any) {
      setError(true);
      setResMessage(err.message);
      setErrorCaptcha(false);
      formikHelpers.resetForm();
      emailRecoveryCaptchaRef.current.reset();
    }
  }

  const cleanErrors = () => {
    setError(false);
    emailRecoveryReset();
    setResMessage("");
    setErrorCaptcha(false);
  }

  const emailRecoveryFormInitialValues: emailRecoveryFields = {
    typeDocument: '',
    document: ''
  }

  // const handleSubmit = async (values: resetPassFields, { resetForm }: FormikHelpers<resetPassFields>) => {
  //   console.log("coco", values);
  //   setPassChanged(true);
  // };

  return (
    <S.EmailRecovery>
      {
        (emailRecoveryLoading || emailRecoveryError || errorCaptcha || resMessage || error) && 
        <Portal>
          <ModalContainer>
            <CustomModal
              title={
                emailRecoveryLoading 
                ? "Cargando" 
                : (emailRecoveryError || errorCaptcha || error) 
                  ? "Error"
                  : "Recuperación de email"
              }
              description={
                emailRecoveryLoading 
                ? "" 
                : resMessage
              }
              type={
                emailRecoveryLoading 
                ? "loading" 
                : (emailRecoveryError || errorCaptcha || error) 
                  ? "failed"
                  : "success"
              }
              action={cleanErrors}
            />
          </ModalContainer>
        </Portal>
      }
      <div className="email-recovery">
        <div className="email-recovery__left">
          <div className="box">
            <i className="box__icon icon icon-recuperar-correo"/>
            <div className="box__text">{t("emailRecovery.pretitle")}</div>
            <div className="box__text-big">{t("emailRecovery.title")}</div>
          </div>
        </div>
        <div className="email-recovery__right">
          <Formik
            initialValues={emailRecoveryFormInitialValues}
            validationSchema={Yup.object({
              typeDocument: Yup.string()
                .required(t("validation.required")),
              document: Yup.string()
                .required(t("validation.required")),
            })}
            onSubmit={handleSubmit}
          >
            {({handleSubmit, values, handleChange}) => (
                <form className="email-recovery-form" onSubmit={handleSubmit}>
                  <div className="inputs-container">

                    {/* <div className="inputs-flex inputs-flex--password"> */}

                      <div className="input-content">
                        <div className="input-label">
                          <span>{t("register.typeDocument")}</span>
                        </div>
                        <div className="double-input">
                        <Field name="typeDocument">
                          {(props: FieldProps) => (
                            <SelectForm
                              {...props}
                              items={TYPE_DOCUMENTS}
                            />  
                          )}
                        </Field>
                        </div>
                      </div>

                      <div className="input-content">
                        <div className="input-label">
                          <span>{t("resetPass.document")}</span>
                        </div>
                        <div className="double-input">
                        <Field name="document">
                          {(props: FieldProps) => (
                            <InputForm
                            type={values.typeDocument === "Pasaporte"  || values.typeDocument === "Documento extranjero"  ? "text" : "number"}
                              {...props}
                            /> 
                          )}
                        </Field>
                        </div>
                      </div>

                    </div>
                  {/* </div> */}
                  <div className="captcha-container">
                    {
                      // env !== 'test' && env !== 'development' &&
                      <ReCAPTCHA
                        sitekey={reCaptchaKey!}
                        ref={emailRecoveryCaptchaRef}
                      />
                    }
                  </div>
                  <div className="button-container">
                    <Button 
                    type={emailRecoveryLoading ? "button" : "submit"}
                      text={
                        !emailRecoveryLoading
                        ? t("resetPass.buttonText")
                        : `${t("global.loading")}...`
                      }
                      fontSize={17}
                    />
                  </div>
                </form>
            )}
          </Formik>
        </div>
      </div>
    </S.EmailRecovery>
  );
}

export default EmailRecovery;
