import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as S from "./Header.style";
import { ROUTES } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { isAuthChange } from "../../../redux/reducers/authSlice";
import { RootState } from "../../../redux/store";
import Popup from "reactjs-popup";

const migracionTitle = `${process.env.REACT_APP_STORAGE_ASSETS}/migracion-title.png`;
const migracionIcon = `${process.env.REACT_APP_STORAGE_ASSETS}/migracion-icon.png`;
const migracionLogo = `${process.env.REACT_APP_STORAGE_ASSETS}/migracion-logo.png`;
const migracionLogoBlanco = `${process.env.REACT_APP_STORAGE_ASSETS}/migracion-logo-blanco.png`;

function Header() {
  const navigate = useNavigate();
  const { START, LOGIN, PQRS_LIST, PROFILE } = ROUTES;
  const [ inputText, setInputText ] = useState<string>('');
  const [t] = useTranslation("global");
  const [menuActived,  setMenuActived] = useState<boolean>(false);
  const dispatch = useDispatch();

  
  const { isAuth, userData } = useSelector((state: RootState) => state.auth);

  const getText = (e: string) => {
    setInputText(e);
  }

  return (
    <S.Header menuActived={menuActived}>
      <div className="banner">
        <div className="banner__logo">
          <img 
            className="banner__logo-icon"
            alt="Centro de consulta ciudadana" 
            src={migracionIcon}
          />
          <img 
            className="banner__logo-title"
            alt="Centro de consulta ciudadana" 
            src={migracionTitle}
          />
        </div>
        <img 
          className="banner__logo-right"
          alt="Gobierno de Colombia" 
          src={migracionLogo}
        />
      </div>
      <div className="menu">
        <div className="menu__tabs">
          <div className="menu__tabs-close">
            <i className="icon icon-cerrar" onClick={() => setMenuActived(false)}/>
          </div>
          <div 
            className="tab" 
            onClick={
              () => {
                navigate(START.route)
                setMenuActived(false)
              }
            }
          >
            <i className="icon icon-menu-inicio tab__icon"/>
            <div className="tab__name">{t("menu.start")}</div>
          </div>
          <div className="tab" 
            onClick={
              () => {
                window.open('https://www.migracioncolombiac3.gov.co/')
                setMenuActived(false)
              }
            }
          >
            <i className="icon icon-menu-informacin tab__icon"/>
            <div className="tab__name">{t("menu.generalInfo")}</div>
          </div>
          <div className="menu__tabs-footer">
            <img 
              className="menu__tabs-footer-img" 
              alt="Migración" 
              src={migracionLogoBlanco}
            />
            <div className="social-networks">
              <div className="social-networks__icons">
                <i className="icon icon-facebook social-networks__icon"/>
                <i className="icon icon-instagram social-networks__icon"/>
                <i className="icon icon-twitter social-networks__icon"/>
                <i className="icon icon-youtube social-networks__icon"/>
              </div>
              <div className="social-networks__at">
                @MigracionCol
              </div>
            </div>
          </div>
        </div>
        <div className="right-menu">
          <div className="search-input">
            {/* <RoundInput
              getText={getText}
            /> */}
          </div>
          <div className="right-menu__options">
            <div className="right-menu__option right-menu__option--hide-menu" onClick={() => setMenuActived(true)}>
              <i className="icon icon-menu right-menu__option-icon"/>
              <div className="right-menu__option-name">{t("menu.menu")}</div>
            </div>

            {
              !isAuth 
              ? <div className="right-menu__option" onClick={() => navigate(LOGIN.route)} >
                <i className="icon icon-inicio-de-sesin right-menu__option-icon"/>
                <div className="right-menu__option-name">{t("menu.signIn")}</div>
              </div>
              : <Popup
                trigger={
                  <div className="right-menu__option" >
                    <i className="icon icon-inicio-de-sesin right-menu__option-icon"/>
                    <div className="right-menu__option-name">
                      { userData ? `${userData.primerNombre} ${userData.primerApellido}` : '' }
                    </div>
                  </div>
                }
                keepTooltipInside
                position="bottom center"
                arrowStyle={{"color": "transparent"}}
              >
                { <S.UserOptions>
                    <div className="menu">
                      <div className="menu__tabs">
                        {/* <div className="menu__tabs-close">
                          <i className="icon icon-cerrar" onClick={() => setMenuActived(false)}/>
                        </div> */}
                        <div className="tab" onClick={() => navigate(PROFILE.route)}>
                          <i className="icon icon-inicio-de-sesin tab__icon "/>
                          <div className="tab__name">{t("menu.myProfile")}</div>
                        </div>
                        <div className="tab" onClick={() => navigate(PQRS_LIST.route)}>
                          <i className="icon icon-listado-de-pqrs tab__icon "/>
                          <div className="tab__name">{t("menu.myPqrs")}</div>
                        </div>
                        <div className="tab" 
                          onClick={() => {
                            dispatch(isAuthChange(false))
                            window.location.reload();
                          }}
                        >
                          <i className="icon icon-cerrar-sesion tab__icon tab__icon--close"/>
                          <div className="tab__name">{t("menu.signOut")}</div>
                        </div>
                        <div className="menu__tabs-footer">
                          <img 
                            className="menu__tabs-footer-img" 
                            alt="Migración" 
                            src={migracionLogoBlanco}
                          />
                          <div className="social-networks">
                            <div className="social-networks__icons">
                              <i className="icon icon-facebook social-networks__icon"/>
                              <i className="icon icon-instagram social-networks__icon"/>
                              <i className="icon icon-twitter social-networks__icon"/>
                              <i className="icon icon-youtube social-networks__icon"/>
                            </div>
                            <div className="social-networks__at">
                              @MigracionCol
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </S.UserOptions>
                }
              </Popup>  
            }
          </div>
        </div>
        
      </div>
    </S.Header>
  );
}

export default Header;