import styled from "styled-components";
import { getHexOpacity } from '../../../utils/pipes';

export const EmailVerified = styled.div`
  display: flex;
  height: 500px;
  width: 100%;
  overflow-y: auto;
  .content-email-verified {
    text-align: center;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: auto;
    .content-email-verified__title {
      color: ${({theme}) => theme.darkBlue};
      font-family: "Poppins Bold";
    }
    .message {
      font-family: 'Open Sans';
      font-size: 16px;
      margin-top: 10px;
      color: ${({theme}) => theme.onBackground};
    }
    .email-container {
      background-color: ${({theme}) => `${theme.primaryColor}${getHexOpacity(0.1)}`};
      padding: 8px;
      border-radius: 10px;
      color: ${({theme}) => theme.primaryColor};
      margin: 25px 0;
      font-family: "Nunito ExtraBold";
    }
    .button-container {
      width: 267px;
      margin: 60px auto 0;
      .button-message {
        margin-top: 20px;
        padding: 0 15px;
      }
    }
  }
`;
