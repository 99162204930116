import styled from "styled-components";
const bannerBackground = require("../../../assets/banner-background.png");

interface Props {
  menuActived?: boolean;
}

export const Header = styled.div<Props>`
  .banner {
    display: flex;
    padding: 25px 0 15px;
    background-color: ${({theme}) => theme.white};
    background-image: url(${bannerBackground});
    /* background-repeat: no-repeat; */
    /* background-repeat: round; */
    background-size: contain;
    position: relative;
    &__logo {
      position: relative;
      margin: auto;
      display: flex;
      align-items: center;
      display: flex;
      justify-content: center;
      &-icon {
        width: 130px;
      }
      &-title {
        width: 450px;
        padding: 0 10px;
        box-sizing: border-box;
      }
    }
    &__logo-right {
      position: absolute;
      width: 170px;
      right: 0;
      margin: 0 5% 0 0
    }
  }
  .menu {
    padding: 8px 10%;
    justify-content: space-around;
    background-color: ${({theme}) => theme.darkBlue};
    color: ${({theme}) => theme.white};
    display: flex;
    font-family: 'Poppins';
    width: 100%;
    box-sizing: border-box;
    &__tabs {
      display: flex;
      width: 100%;
      &-close {
        display: none;
      }
      &-footer {
        display: none;
      }
    }
  }
  .tab {
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
    border-right: 1px ${({theme}) => theme.white} solid;
    inline-size: max-content;
    &__icon {
      margin-right: 5px;
    }
  }
  .right-menu {
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: 10px;
    justify-content: space-evenly;
  }
  .right-menu__options {
    display: flex;
    justify-content: space-between;
    .right-menu__option {
      width: 100%;
      margin: 0 10px;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
      inline-size: max-content;
      &-icon {
        margin-right: 5px;
      }
      &--hide-menu {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 980px) {
    .banner {
      background-size: cover;
    }
    .banner__logo {
      flex-direction: column;
    }
    .menu {
      padding: 8px 8px;
    }
    .menu__tabs {
      display: ${({menuActived}) => menuActived ? 'block' : 'none'};
      /* display: none; */
      position: absolute;
      background-color: ${({theme}) => theme.darkBlue};
      margin-top: 26px;
      z-index: 1;
      height: 450px;
      padding: 40px 20px;
      box-sizing: border-box;
      &-close {
        display: block;
        margin-bottom: 10px;
        font-size: 23px;
        cursor: auto;
        i {
          cursor: pointer;
          justify-content: end;
        }
      }
      &-footer {
        margin-top: 100px;
        display: block;
        width: 100%;
        text-align: center;
        &-img {
          height: 50px;
          padding-left: 35px;
        }
        .social-networks {
          display: flex;
          align-items: center;
          justify-content: center;
          &__icons {
            display: flex;
            border-bottom: 1px solid ${({theme}) => theme.orange};
            padding: 5px 0;
            i {
              margin-right: 3px;
              cursor: pointer;
            }
            margin-right: 4px;
          }
          &__at {
            font-family: 'Poppins Light';
            font-size: 13px;
          }
        }
      }
    }
    .tab {
      font-size: 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0 10px;
      border: none;
      inline-size: max-content;
      margin: 15px 0;
      &__icon {
        margin-right: 5px;
        font-size: 17px;
      }
    }
    .right-menu {
      margin-left: 0;
      display: flex;
      flex-direction: column-reverse;
      /* height: 55px; */
      justify-content: end;
    }
    .right-menu__options {
      width: 100%;
      .right-menu__option--hide-menu {
        display: flex;
      }
    }
    .search-input {
      width: 100%;
    }
    .banner__logo-right {
      width: 136px;
    }
  }

  @media only screen and (max-width: 500px) {
    .banner__logo-right {
      width: 95px;
    }
    .banner__logo-icon {
      width: 60px !important;
    }
    .banner__logo-title {
      width: 300px !important;
    }
  }
`;


export const UserOptions = styled.div<Props>`
  .menu {
    padding: 8px 10%;
    justify-content: space-around;
    background-color: ${({theme}) => theme.darkBlue};
    color: ${({theme}) => theme.white};
    display: flex;
    font-family: 'Poppins';
    width: 100%;
    box-sizing: border-box;
    &__tabs {
      display: block;
      position: absolute;
      background-color: #022D61;
      z-index: 1;
      height: 310px;
      padding: 20px 35px;
      box-sizing: border-box;
      &-close {
        display: block;
        margin-bottom: 10px;
        font-size: 23px;
        cursor: auto;
        i {
          justify-content: end;
        }
      }
      &-footer {
        margin-top: 50px;
        display: block;
        width: 100%;
        text-align: center;
        &-img {
          height: 50px;
          padding-left: 35px;
        }
        .social-networks {
          display: flex;
          align-items: center;
          justify-content: center;
          &__icons {
            display: flex;
            border-bottom: 1px solid ${({theme}) => theme.orange};
            padding: 5px 0;
            i {
              margin-right: 3px;
              cursor: pointer;
            }
            margin-right: 4px;
          }
          &__at {
            font-family: 'Poppins Light';
            font-size: 13px;
          }
        }
      }
    }
  }
  .tab {
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
    border: none;
    inline-size: max-content;
    padding: 10px 0;
    border-bottom: .5px solid white;
    width: 100%;
    &__icon {
      margin-right: 10px;
      font-size: 25px;
      &--close {
        color: red;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    
  }
`