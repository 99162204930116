import axios from "axios";

const BASE_URL = process.env.REACT_APP_ENDPOINT_CLOUD_FUNCTIONS;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    // 'Accept': 'application/json',
    // 'Authorization': sharedService.getToken()
  }
})

const httpGet = (url: string) => {
  return axiosInstance.get(url);
}

const httpPost = (url: string, data: any) => {  
  return axiosInstance.post(url, data);
}

const httpPatch = (url: string, data: any) => {
  return axiosInstance.patch(url, data);
}

const httpPut = (url: string, data: any) => {
  return axiosInstance.put(url, data);
}

const httpDelete = (url: string) => {
  return axiosInstance.delete(url);
}

const httpServices = {
  httpGet,
  httpPost,
  httpPatch,
  httpPut,
  httpDelete
}

export default httpServices;