import styled from "styled-components";

export const Pages = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #DCDDE2;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  min-height: 100vh;
`;
