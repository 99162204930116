import styled, { keyframes, ThemeConsumer } from "styled-components";

const barAnimation = () => keyframes`
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
`;

export const CreatePQRS = styled.div`
  display: flex;
  .create-pqrs {
    display: flex;
    width: 100%;
    justify-content: center;
    &__left {
      align-self: center;
      height: 100%;
      background-color: ${({theme}) => theme.white};
      width: 25%;
      .box-title {
        width: 220px;
        border-radius: 20px;
        background-color: ${({theme}) => theme.white};
        margin: 60px auto;
        text-align: center;
        &__icon {
          justify-content: center;
          font-size: 65px;
          color: ${({theme}) => theme.softBlue};
        }
        &__text {
          color: ${({theme}) => theme.blackText};
          font-size: 13px;
          margin-top: 5px;
        }
        &__text-big {
          color: ${({theme}) => theme.darkBlue};
          font-size: 30px;
          font-family: 'Poppins Bold';
          border-bottom: 2px solid #18A1D5;
          line-height: 32px;
          width: fit-content;
          display: flex;
          margin: auto;
        }
        &__description {
          font-size: 9px;
          font-weight: 600;
          margin-top: 5px; 
          font-display: inline;
        }
        &__description-color {
          font-family: 'Poppins Bold';
          color: ${({theme}) => theme.darkBlue};
        }
      }
      .tab-steps {
        width: 220px;
        margin: auto;
        font-size: 10px;
        .box-tab {
          padding: 8px 10px;
          border-left: ${({theme}) => theme.greyTable} 3px solid;
          color: ${({theme}) => theme.blackText};
          &--selected {
            border-left: ${({theme}) => theme.softBlue} 3px solid;
            color: ${({theme}) => theme.darkBlue};
            font-family: 'Poppins Bold';
          }
          &__number {
            display: none;
          }
        }
      }
    }
    &__right {
      width: 75%;
      align-self: center;
      min-height: 550px;
      display: flex;
      flex-direction: column;
      .response-message {
        margin: 19px 0;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins Bold';
        color: ${({theme}) => theme.darkBlue};
      }
      .title-form {
        font-family: 'Poppins Bold';
        color: ${({theme}) => theme.darkBlue};
        margin: 50px 9% 30px;
        font-size: 16px;
      }
      .create-pqrs-form {
        margin: 30px 8%; 
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .inputs-container {
          justify-content: flex-start;
          align-items: center;
          display: flex;
          width: 100%; 
          flex-wrap: wrap;
          max-width: 920px;
          .input-content {
            min-width: 275px;
            display: flex;
            flex-direction: column;
            margin: 0 15px;
            position: relative;
            .input-label {
              display: flex;
              font-size: 13px;
              padding-bottom: 1px;
              align-items: center;
              span {
                opacity: 0.7;
              }
              .tooltip-container {
                margin-left: 10px;
              }
            }
            .description-request {
              position: absolute;
              top: -90px;
              background-color: white;
              height: 110px;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              padding: 5px 10px;
              overflow: hidden;
              &__top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: ${({theme}) => theme.softBlue};
              }
              &__icon {
                font-size: 10px;
                cursor: pointer;
              }
              &__title {
                font-family: 'Poppins Bold';
                font-size: 13px;
              }
              &__description-text {
                font-size: 10px;
                line-height: 11px;
              }
            }
          }
          &--documents {
            align-items: initial;
          }
          .inputs-flex{
            display: flex;
            /* overflow: hidden; */
          }
          &--second-step {
            max-width: 820px;
          }
        }
        .document-content {
          margin: 20px;
          width: auto;
          .input-document {
            span {
              font-size: 12.5px;
              padding-bottom: 1px;
              opacity: 0.7;
              margin-left: 10px;
            }
            .box-input {
              padding: 10px;
              box-sizing: border-box;
            }
          }
          .box-input-container--disabled{
            opacity: 0.4;
          }
          .documents-uploaded {
            margin-top: 25px;
            width: 100%;
            &--disabled {
              opacity: 0.4;
            }
            &__list {
              border-radius: 10px;
              overflow: auto;
              &--disabled {
                opacity: 0.4;
              }
            }
            &__document-type {
              margin: 2px 20px;
              font-size: 10px;
              font-style: oblique;
            }
          }
        }
        .comment-content {
          margin-top: 20px;
          width: 300px;
          span {
            font-size: 12.5px;
            padding-bottom: 1px;
            opacity: 0.7;
          }
        }
        .box-input-container {
          background-color:  ${({theme}) => theme.white};
          padding: 6px 8px 8px 6px;
          height: 85px;
          border-radius: 10px;
          .box-input {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            border: ${({theme}) => theme.greyText} dashed 1px;
            display: flex;
            margin: auto;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &__icon {
              font-size: 30px;
              color: ${({theme}) => theme.softBlue};
              margin-right: 5px;
            }
            &__title {
              font-size: 13px;
              color: ${({theme}) => theme.greyText};
            }
            &--disabled {
              cursor: default;
            }
          }
        }
        .document-uploaded {
          display: flex;
          align-items: center;
          background-color: ${({theme}) => theme.white};
          margin-bottom: 2px;
          padding: 5px 15px;
          &__info {
            width: -webkit-fill-available;
          }
          &__icon {
            margin-right: 5px;
            font-size: 13px;
            color: ${({theme}) => theme.red};
          }
          &__title {
            font-size: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 200px;
          }
          &__icon-delete {
            margin-left: auto;
            font-size: 13px;
            color: ${({theme}) => theme.greyText};
            padding-left: 10px;
            cursor: pointer;
          }
        }        
        .box-accept {
          background-color: ${({theme}) => theme.white}; 
          border-radius: 10px;
          max-width: 820px;
          margin: 20px;
          padding: 20px;
          box-sizing: border-box;
          text-align: center;
          font-size: 14px;
          &--disabled {
            opacity: 0.4;
          }
          &__description {
            margin-top: 10px;
            font-size: 12px;
            opacity: 0.8;
            padding: 0 50px;
            text-align: justify;
          }
          &__input-container {
            margin-top: 20px;
            display: flex;
            justify-content: center;
          }
          &__input-radius {
            display: flex;
            width: 90px;
            justify-content: space-around;
            margin-left: 10px;
          }
        }
        .list-container {
          font-size: 10px;
          margin-bottom: 20px;
        }
        .captcha-container{
          align-self: center;
        }
        .buttons-container{
          justify-content: center;
          display: flex;
          margin: 20px auto;
          .button-container{
            margin: 0 20px;
            width: 150px;
          }
        }
      }
      .pqrs-consulted {
        margin: auto;
        &__form {
          background-color: ${({theme}) => theme.white};
          box-shadow: 0px 0px 9px #00000012;
          border-radius: 14px;
          padding: 50px 60px 20px 60px;
          margin: 0 auto;
          width: 500px;
        }
        &__title {
          display: flex;
          align-items: center;
          justify-content: center;
          &-icon {
            color: ${({theme}) => theme.green};
            font-size: 40px;
            margin-right: 10px;
          }
          &-text {
            font-size: 20px;
            font-family: 'Poppins Bold';
            color: ${({theme}) => theme.darkBlue};
            text-align: center;
            line-height: 22px;
          }
        }
        &__info {
          display: flex;
          justify-content: center;
          margin-top: 10px;
          align-items: center;
          .info-item {
            margin: 10px 20px;
            display: flex;
            align-items: center;
            color: ${({theme}) => theme.darkBlue};
            &__label {
              font-family: 'Poppins Bold';
              margin-right: 10px;
            }
            &__data {
              background-color: ${({theme}) => theme.greyBox};
              padding: 2px 5px;
              border-radius: 6px;
            }
          }
        }
        &__encuesta {
          font-size: 11px;
          font-weight: 600;
          margin: 20px 100px;
          &-link {
            text-decoration: underline;
            color: ${({theme}) => theme.softBlue};
            display: inline;
            cursor: pointer;
            margin-left: 5px;
          }
        }
        &__info-laws {
          font-size: 11px;
          font-weight: 600;
          .info-laws__text {
            margin: 20px 100px;
          }
        }

        &__button-container {
          display: flex;
          margin: 60px auto 0px;
          width: 150px;
        }
      }
    }
  } 

  @media only screen and (max-width: 980px) {
    .create-pqrs {
      overflow: hidden;
      flex-direction: column;
      &__left {
        padding: 0 0 20px 0;
      }
    }
    .create-pqrs {
      .title-form {
        text-align: center;
      }
      &__right {
        width: 100%;
      }
      &__left {
        /* background-color: red; */
        background-color: transparent;
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        height: 145px;
        padding-bottom: 0px !important;
        justify-content: flex-end;
        .box-title {
          width: 80%;
          background-color: transparent;
          margin: 10px auto 0px auto;
        }
        .tab-steps {
          background-color: white;
          width: 100%;
          padding: 6px;
          box-sizing: border-box;
          font-size: 10px;
          display: flex;
          justify-content: center;
          margin: 0;
          .box-tab {
            padding: 4px 10px 0px;
            border-top: ${({theme}) => theme.greyTable} 3px solid;
            border-left: none;
            color: ${({theme}) => theme.blackText};
            width: 30px;
            white-space: nowrap;
            display: flex;
            &--selected {
              border-top: ${({theme}) => theme.softBlue} 3px solid;
              border-left: none;
              color: ${({theme}) => theme.darkBlue};
              font-family: 'Poppins Bold';
            }
            &__number {
              font-size: 15px;
              display: inline;
              margin: 0 5px 0 10px;
              color: ${({theme}) => theme.softBlue};
              font-weight: 500;
            }
            &__text {
              opacity: 0;
            }
          }
        }
      }
    }
    .document-content {
      width: auto !important;
      margin: 20px 0 !important;
    }
    .comment-content {
      align-self: center;
      padding: 0 15px;
      box-sizing: border-box;
    }
    .box-accept {
      width: auto !important;
      &__description {
        padding: 0 10px !important;
      }
    }
    .inputs-flex {
      flex-direction: column;
    }    
    .inputs-container {
      width: min-content !important;
      align-self: center;
    }
    .button-container {
      width: 110px !important;
      margin: 0 5px !important;
    }
    .pqrs-consulted {
      &__title {
        flex-direction: column;
      }
      &__title-text {
        margin-top: 10px;
      }
      &__form {
        padding: 30px 20px 20px 20px !important;
        width: auto !important;
        margin: 0 20px !important;
        box-sizing: border-box;
      }
      &__encuesta {
        margin: 20px !important;
      }
      .info-laws__text {
        margin: 20px !important;
        text-align: center;
      }
    }
  }
`;

interface Props {
  activedBar?: boolean;
}

export const Loader = styled.div<Props>`
  .loader {
    margin: 0 auto;
    /* border-radius: 10px; */
    /* border: 4px solid transparent; */
    background-color: ${({theme, activedBar}) => activedBar ? theme.greyTable : theme.greenBar};
    position: relative;
    padding: 1.5px;
    width: 100%;
  }
  .loader:before {
    content: '';
    /* border: 1px solid #000;  */
    border-radius: 10px;
    position: absolute;
    top: -4px; 
    right: -4px; 
    bottom: -4px; 
    left: -4px;
  }
  .loader .loaderBar { 
    position: absolute;
    /* border-radius: 10px; */
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: ${({theme, activedBar}) =>  activedBar ? theme.softBlue : ''}; 
    width: 0;
    animation: ${() => barAnimation() } 2s linear infinite;
  }
`