import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Pages from "../pages/Pages";
import routesList from "./routesLists/routesList";

interface  Props {
  existToken: boolean;
}

function RoutesIndex({existToken}: Props) {

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Pages authed={existToken}/>}>
          {
            routesList.map(route => (
              <Route
                {...route}
                element={
                  route.totalAccess
                  ? <route.component />
                  : route.onlyPrivate && existToken
                    ? <route.component /> 
                    : route.onlyPublic && !existToken
                      ? <route.component /> 
                      : <Navigate to={{ pathname: "/" }} />
                }
              />
            ))
          }
        </Route>

        <Route path="*" element={<>error 404</>} />
      
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesIndex;