import styled from "styled-components";

export const Profile = styled.div`
  display: flex;
  .profile {
    display: flex;
    margin: auto;
    /* flex-direction: column; */
    margin: 60px 0;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    &__title {
      margin-bottom: 10px;
      font-size: 20px;
      font-family: "Poppins Bold";
      color: ${({theme}) => theme.darkBlue};
      margin-left: 10px;
    }
    &__button-update-profile {
      width: 100px;
      height: 30px;
      margin-bottom: 20px;
      margin-left: 10px;
    }
    &__form-container {
      margin: 20px;
      align-self: center;
      .profile-form {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .inputs-container {
          justify-content: flex-start;
          align-items: center;
          display: flex;
          width: 100%; 
          flex-wrap: wrap;
          max-width: 920px;
          .input-content {
            min-width: 275px;
            display: flex;
            flex-direction: column;
            margin: 0 15px;
            position: relative;
            .input-label {
              display: flex;
              font-size: 13px;
              padding-bottom: 1px;
              align-items: center;
              span {
                opacity: 0.7;
              }
              .tooltip-container {
                margin-left: 10px;
              }
            }
          }
          &--password {
            margin-top: 40px;
          }
          /* } */
        }
        .button-container{
          width: 250px;
          height: 35px;
          justify-content: center;
          display: flex;
          margin: 25px auto 40px;
        }
      }
      &--password-change {
        padding-top: 30px;
        border-top: 1px solid #707070;
        align-self: center;
      }
    }
  }

  @media only screen and (max-width: 980px) {
    .profile {
      flex-direction: column;
      &__left {
        padding: 0 0 20px 0;
      }
    }
    .inputs-flex {
      flex-direction: column;
    }
    .inputs-container {
      width: min-content !important;
    }
  }
`;
