import styled from "styled-components";

export const ListOfPQRS = styled.div`
  display: flex;
  .list {
    display: flex;
    margin: auto;
    /* flex-direction: column; */
    margin: 60px 0;
    width: 100%;
    justify-content: center;
    &__left {
      align-self: center;
      padding: 50px 30px 50px 0;
      .box {
        width: 220px;
        box-shadow: 0px 0px 9px #00000012;
        border-radius: 20px;
        background-color: ${({theme}) => theme.white};
        margin: 20px;
        padding: 40px 40px;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        &__icon {
          justify-content: center;
          font-size: 65px;
          color: ${({theme}) => theme.softBlue};
        }
        &__text {
          color: ${({theme}) => theme.blackText};
          font-size: 13px;
          margin-top: 5px;
        }
        &__text-big {
          color: ${({theme}) => theme.darkBlue};
          font-size: 30px;
          font-family: 'Poppins Bold';
          border-bottom: 2px solid #18A1D5;
          line-height: 32px;
          width: fit-content;
          display: flex;
          margin: auto;
        }
      }
    }
    &__right {
      align-self: center;
      .response-message {
        margin: 19px 0;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins Bold';
        color: ${({theme}) => theme.darkBlue};
      }
      .pqrs-list-form {
        display: flex;
        /* flex-direction: column; */
        box-sizing: border-box;
        .inputs-container {
          align-self: center;
          display: flex;
          .input-content {
            width: 240px;
            display: flex;
            flex-direction: column;
            margin: 0 20px 0 20px;
            .input-label {
              display: flex;
              font-size: 13px;
              padding-bottom: 1px;
              align-items: initial;
              span {
                opacity: 0.7;
              }
            }
          }
        }
        .button-container{
          width: 200px;
          justify-content: center;
          display: flex;
          /* margin: auto; */
          margin: 20px 15px;
        }
      }

      .pqrs-list-container {
        padding: 20px;
        .pqrs-pagination {
          justify-content: center;
          display: flex;
          align-items: center;
          &__icon {
            color: ${({theme}) => theme.softBlue};
            cursor: pointer;
          }
        }
        .pqrs-table-container {
          width: 900px;
          .pqrs-table {
            margin-top: 10px;
            border-spacing: 0px 7px;
            &__th {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            th {
              font-size: 13px;
              align-items: center;
              padding: 5px 10px;
              .tooltip-container {
                margin-left: 5px;
              }
            }
            tbody {
              tr {
              }
            }
            .pqrs-item {
              cursor: pointer;
              font-size: 14px;
              text-align: center;
              background-color: ${({theme}) => theme.white};
              color: ${({theme}) => theme.softBlue};
              box-shadow: 6px 8px 13px -5px rgba(0,0,0,0.27);
              -webkit-box-shadow: 6px 8px 13px -5px rgba(0,0,0,0.27);
              -moz-box-shadow: 6px 8px 13px -5px rgba(0,0,0,0.27);
              td {
                border-collapse: collapse;
                border-right: 1px solid ${({theme}) => theme.softBlue};
                padding: 9px 20px;
              }
              &__numeration {
                display: flex;
                align-items: center;
              }
              &__numeration-number {
                margin-left: 25px;
                font-size: 25px;
                font-family: 'Poppins Bold';
              }
              &__numeration-icon {
                cursor: pointer;
              }
              &__edition-icon {
                justify-content: center;
                font-size: 20px;
                cursor: pointer;
              }
              &--selected {
                background-color: ${({theme}) => theme.softBlue};
                color: ${({theme}) => theme.white};
                td {
                  border-collapse: collapse;
                  border-right: 1px solid ${({theme}) => theme.white};
                  padding: 9px 20px;
                }
              }
              &__details-tr {
                transform: translateY(-3px);
              }
              &__details {
                display: flex;
                width: 100%;
                border: 1px #99e1fd solid;
                .detail-item {
                  flex-basis: 100%;
                  background-color: #e4eff3;
                  padding: 15px 0 5px 0;
                  &__title {
                    font-family: "Open Sans Bold";
                    font-size: 14px;
                    padding: 10px 15px 0 10px;
                    &-item-point {
                      color: ${({theme}) => theme.softBlue};
                      display: initial;
                      margin-right: 5px;
                      position: absolute;
                      margin-left: -10px;
                    }
                    /* text-align: center; */
                  }
                  &__warning {
                    font-family: "Open Sans Bold";
                    font-size: 14px;
                    padding: 10px 15px 0 10px;
                    color: #002A5D;
                    font-style: italic;
                    margin: 10px 0;
                    .required-indicator {
                      display: contents;
                    }
                    /* text-align: center; */
                  }
                  &__descript {
                    font-family: "Open Sans SemiBold";
                    font-size: 13px;
                    padding: 2px 15px 0px 15px;
                    &--no-top {
                      padding: 0px 15px 0px 15px;
                    }
                    &--fade {
                      opacity: 0.5;
                      font-style: italic;
                    }
                    /* text-align: center; */
                  }
                  &__document-list{
                    padding: 4px 0 5px 0;
                    /* .document-item:nth-of-type(odd) {
                    } */
                    .document-item {
                      &__icon {

                      }
                      background-color: white;
                      margin: 4px;
                      font-family: "Open Sans SemiBold";
                      padding: 0 15px;
                      /* text-align: center; */
                      font-size: 13px;
                      cursor: pointer;
                    }
                  }
                }
                .detail-table-item {
                  border: 1px white solid;
                  background-color: #18A1D5;
                  color: white;
                  text-align: center;
                  padding: 3px 10px;
                  display: none;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1350px) {
    .list {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 980px) {

    .pqrs-list-form {
      flex-direction: column;
      .inputs-container {
        flex-direction: column;
      }
      .button-container{
        margin: auto !important;
      }
    }

    .list {
      flex-direction: column;
      &__left {
        padding: 0 0 20px 0;
      }
      &__right {
        width: auto;
      }
    }

    .pqrs-list-container {
      &__info {
        flex-direction: column;
      }
      &__form {
        padding: 50px 20px 20px 20px !important;
      }
      &__title {
        text-align: center;
      }
    }

    .pqrs-table-container {
      width: auto !important;
      .pqrs-table {
        th {
          display: none;
        }
        .pqrs-item {
          td {
            text-align: left;
            font-size: 17px;
            border-collapse: unset !important;
            border-right: none !important;
          }
          &__responsive-hide {
            display: none;
          }
          &__details {
            flex-direction: column;
            .detail-item {
              &__title {
                text-align: center;
              }
              &__warning {
                text-align: center;
              }
              &__descript {
                text-align: center;
              }
              &__document-list{
                .document-item {
                  text-align: center;
                }
              }
            }
            .detail-table-item {
              display: block !important;
            }
          }
        }
      }
    }
    /* .detail-table-item {
      display: block !important;
    } */
  }
`;
