import styled from "styled-components";

export const Login = styled.div`
  display: flex;
  .login {
    display: flex;
    margin: auto;
    /* flex-direction: column; */
    margin: 60px 0;
    width: 100%;
    justify-content: center;
    &__left {
      align-self: center;
      padding: 50px 100px 50px 0;
      .box {
        width: 245px;
        box-shadow: 0px 0px 9px #00000012;
        border-radius: 20px;
        background-color: ${({theme}) => theme.white};
        margin: 20px;
        padding: 60px 17px;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        &__icon {
          justify-content: center;
          font-size: 65px;
          color: ${({theme}) => theme.softBlue};
        }
        &__text {
          color: ${({theme}) => theme.blackText};
          font-size: 13px;
          margin-top: 5px;
        }
        &__text-big {
          color: ${({theme}) => theme.darkBlue};
          font-size: 30px;
          font-family: 'Poppins Bold';
          border-bottom: 2px solid #18A1D5;
          line-height: 32px;
          width: fit-content;
          display: flex;
          margin: auto;
        }
      }
    }
    &__right {
      align-self: center;
      .response-message {
        margin: 19px 0;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins Bold';
        color: ${({theme}) => theme.darkBlue};
      }
      .login-form {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        align-items: center;
        /* .inputs-container {
          .inputs-flex {
            width: 500px;
            justify-content: center;
            align-items: center;
            display: flex; */
            /* width: 100%; */
            .input-content {
              width: 500px;
              display: flex;
              flex-direction: column;
              margin: 0 10px;
              .input-label {
                font-size: 13px;
                padding-bottom: 1px;
                span {
                  opacity: 0.7;
                }
              }
            /* }
          } */
        }
        .captcha-container {
          align-self: center;
          margin-top: 10px;
        }
        .list-container {
          font-size: 10px;
          margin-bottom: 20px;
        }
        .button-container{
          width: 200px;
          justify-content: center;
          display: flex;
          margin: auto;
          font-size: 18px;
          margin-top: 20px;
        }
        .link-container {
          font-size: 12px;
          text-align: right;
          /* margin: 0px 0px 10px; */
          transform: translateY(-15px);
        }
      }
    }
  }

  @media only screen and (max-width: 980px) {
    .login {
      flex-direction: column;
      &__left {
        padding: 0 0 20px 0;
      }
    }
    .input-content {
      width: 260px !important;
    }
  }
`;