import React, { useRef, useState, useEffect, useContext  } from "react";
import { FieldProps, useField } from "formik";
import * as S from "./InputForm.style";
import * as P from "../../../utils/pipes";
import { ThemeContext } from 'styled-components';
import { useTranslation } from "react-i18next";

interface Props extends FieldProps {
  placeholder?: string, 
  icon?: string,
  disabled?: boolean,
  type?: string,
  padding?: string,
  height?: number,
  fontSize?: string,
  textarea?: boolean,
  errorOption?: boolean,
  copyPasteBlocked?: boolean,
  setErrors?: () => void,
}

declare global {
  interface Window {
    SpeechRecognition: any;
    webkitSpeechRecognition: any;
  }
}

function InputForm({ 
  placeholder, 
  icon,
  disabled,
  type,
  padding,
  height,
  fontSize,
  textarea,
  errorOption = true,
  copyPasteBlocked,
  setErrors,
  ...props
}: Props) {
  const {i18n: { language }} = useTranslation("global");
  const container = useRef<HTMLDivElement>(null);
  const textInput = useRef<HTMLInputElement | null>(null);
  const textAreaInput = useRef<HTMLTextAreaElement | null>(null);
  const [field, meta, helpers] = useField(props.field.name);
  const [active, setActive] = useState(false);
  const [seePass, setSeePass] = useState(false);
  const theme = useContext(ThemeContext);
  const { setValue } = helpers;
  const [activedMic, setActivedMic] = useState(false);
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const [ recognition ] = useState(SpeechRecognition ? new SpeechRecognition() : null);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (container.current && !container.current.contains(e.target)) {
        setActive(false)
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const activeInput = () => {
    if (!disabled) {
      textInput.current?.focus(); 
      setActive(true);
    } 
  } 

  const btnStopRecord = () => {
    recognition.stop();
    setActivedMic(false);
  };

  const btnStartRecord = () => {
    const languageSetting = P.getMicLanguage(language);
    recognition.lang = languageSetting;
    recognition.continuous = true;
    // recognition.interimResults = false;
    recognition.start();
    recognition.onresult = (event: any) => {
      const results = event.results;
      const frase = results[results.length - 1][0].transcript;
      if(field.value !== undefined) field.value += frase;
      else field.value = frase;
      setValue(field.value)
    }
    setActivedMic(true);
  };

  return (
    <S.InputForm 
      padding={padding} 
      active={active} 
      error={meta} 
      disabled={disabled} 
      fontSize={fontSize}
      activedMic={activedMic}
      height={height}
    >
        <div className="input-container" onClick={() => activeInput()} ref={container}>
          <i className={'icon-input-form icon zaia-' + icon} style={{ color: active ? theme.inputSelected : theme.inputPlaceholder }} />
          {textarea && SpeechRecognition
            ? <>
              <div className='mic-container' onClick={() => !activedMic ? btnStartRecord() : btnStopRecord()}>
                <i className='mic-icon icon zaia-microphone'/>
              </div>
            </>
            : null
          }
          {
            textarea ? 
            <textarea 
              ref={textAreaInput}
              {...field} 
              disabled={disabled}
              placeholder={placeholder}
              maxLength={1000}
            /> : 
            <input 
              ref={textInput}
              {...field} 
              disabled={disabled}
              type={seePass ? "text" : type}
              placeholder={placeholder}
              
              onSelect={
                copyPasteBlocked
                ? (e) => {
                  e.preventDefault();
                  return false;
                }
                : undefined
              }

              onDrop={
                copyPasteBlocked
                ? (e) => {
                  e.preventDefault();
                  return false;
                }
                : undefined
              }

              onDrag={
                copyPasteBlocked
                ? (e) => {
                  e.preventDefault();
                  return false;
                }
                : undefined
              }

              onCopy={
                copyPasteBlocked
                ? (e) => {
                  e.preventDefault();
                  return false;
                }
                : undefined
              }

              onCut={
                copyPasteBlocked
                ? (e) => {
                  e.preventDefault();
                  return false;
                }
                : undefined
              }

              onPaste={
                copyPasteBlocked
                ? (e) => {
                  e.preventDefault();
                  return false;
                }
                : undefined
              }

              autoComplete={copyPasteBlocked ? "off" : "on"}
            />
          }
          {
            type === "password" && field?.value ? 
            <i 
              className={
                'icon-input-pass icon icon-' + 
                (seePass ? "visibility" : 'invisible')
              } 
              data-testid="button-icon-seepass" 
              onClick={() => setSeePass(!seePass)} 
              style={{ 
                color: seePass 
                ? theme.inputSelected 
                : theme.inputPlaceholder 
              }} 
            />
            : null
          }
        </div>
        {
          textarea &&
          <div className="input-counter">
            {field.value.length}/1000
          </div>
        }
        {
          errorOption ?
          <div className="input-error">{
            meta.touched && meta.error ? 
            <>
              <i className="icon zaia-icono-ayuda"></i>
              {meta.error }
            </>
            : null}
          </div> : null
        }
    </S.InputForm>
  );
}

export default InputForm;
