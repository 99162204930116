import Start from "../../pages/public/start";
import Login from "../../pages/public/login";
import Register from "../../pages/public/register";
import ConsultPQRS from "../../pages/public/consultPQRS";
import SessionOptions from "../../pages/public/sessionOptions";
import ChangePassword from "../../pages/public/changePassword";
import EmailVerified from "../../pages/public/emailVerified";
import ResetPassword from "../../pages/public/resetPassword";
import CreatePQRS from "../../pages/private/createPQRS";
import ListOfPQRS from "../../pages/private/listOfPQRS";
import Profile from "../../pages/private/profile";
import EmailRecovery from "../../pages/public/emailRecovery";

const publicRoutesList = [
  {
    id: "empty",
    key: "empty",
    path: "/",
    totalAccess: true,
    component: () => <Start />,
  },
  {
    id: "register",
    key: "register",
    path: "/register",
    onlyPublic: true,
    totalAccess: false,
    component: () => <Register />,
  },
  {
    id: "login",
    key: "login",
    path: "/login",
    onlyPublic: true,
    totalAccess: false,
    component: () => <Login />,
  },
  {
    id: "session-options",
    key: "session-options",
    path: "/session-options",
    onlyPublic: true,
    totalAccess: false,
    component: () => <SessionOptions />,
  },
  {
    id: "consult-pqrs",
    key: "consult-pqrs",
    path: "/consult-pqrs",
    totalAccess: true,
    component: () => <ConsultPQRS />,
  },
  {
    id: "reset-pass",
    key: "reset-pass",
    path: "/reset-pass",
    totalAccess: true,
    component: () => <ResetPassword />,
  },
  {
    id: "change-pass",
    key: "change-pass",
    path: "/change-pass",
    totalAccess: true,
    component: () => <ChangePassword />,
  },
  {
    id: "validateemail",
    key: "validateemail",
    path: "/validateemail",
    totalAccess: true,
    component: () => <EmailVerified />,
  },
  {
    id: "create-pqrs",
    key: "create-pqrs",
    path: "/create-pqrs",
    totalAccess: false,
    onlyPrivate: true,
    component: () => <CreatePQRS />,
  },
  {
    id: "pqrs-list",
    key: "pqrs-list",
    path: "/pqrs-list",
    totalAccess: false,
    onlyPrivate: true,
    component: () => <ListOfPQRS />,
  },
  {
    id: "create-pqrs-anonymous",
    key: "create-pqrs-anonymous",
    path: "/create-pqrs-anonymous",
    totalAccess: true,
    component: () => <CreatePQRS anonymous />,
  },
  {
    id: "profile",
    key: "profile",
    path: "/profile",
    totalAccess: false,
    onlyPrivate: true,
    component: () => <Profile />,
  },
  {
    id: "email-recovery",
    key: "email-recovery",
    path: "/email-recovery",
    totalAccess: false,
    onlyPublic: true,
    component: () => <EmailRecovery />,
  },
];

export default publicRoutesList;
