import { useRef, useState } from "react";
import { Field, FieldProps, Formik, FormikHelpers } from "formik";
import Button from "../../shared/button";
import * as S from "./ResetPassword.style";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import InputForm from "../../shared/inputForm";
import SelectForm from "../../shared/selectForm";
import { useMutation } from "@apollo/client";
import { MUTATION_RESET_PASS } from "../../../graphql/mutations/Auth";
import { TYPE_DOCUMENTS } from "../../../utils/constants";
import ReCAPTCHA from "react-google-recaptcha";
import ModalContainer from "../../shared/modals/modalContainer";
import Portal from "../../shared/modals/portal";
import CustomModal from "../../shared/modals/customModal";

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

function ResetPassword() {
  const {t} = useTranslation("global");
  const [resMessage, setResMessage] = useState<string>('');
  const [errorCaptcha, setErrorCaptcha] = useState<boolean>(false);
  const resetPassCaptchaRef = useRef<any>(null);

  const [resetPass, { loading: resetPassLoading, error: resetPassError, reset: resetResetPass}] = useMutation(MUTATION_RESET_PASS);

  interface  resetPassFields {
    typeDocument: string,
    document: string,
    email: string,
    emailConfirmation: string
  }  

  const handleSubmit = (values: resetPassFields, formikHelpers: FormikHelpers<resetPassFields>) => {
    // if (env !== 'test' && env !== 'development') {
    //   const captchaToken = await loginCaptchaRef.current.executeAsync();
      if (resetPassCaptchaRef.current.getValue()) {
        setErrorCaptcha(false);
        resetPassFunction(values, formikHelpers);
      } else {
        setErrorCaptcha(true);
        setResMessage("Error de Captcha");
        resetPassCaptchaRef.current.reset();
      }
    // } else {
      // resetPassFunction(values, formikHelpers);
    // }
  }

  const resetPassFunction = async (values: resetPassFields, formikHelpers: FormikHelpers<resetPassFields>) => {
    try {
      const {
        data: {
          recuperarContrasena: { message },
        },
      } = await resetPass({
        variables: {
          numerodni: values.document.toString().toLowerCase(), 
          email: values.email.toLowerCase(), 
          captchaToken: resetPassCaptchaRef.current.getValue()
        },
      });
      setResMessage(message);
      formikHelpers.resetForm();
      resetPassCaptchaRef.current.reset();
    } catch (err: any) {
      if(err.message === "the key 'message' was not present") {
        setResMessage("Ocurrio un error, intentelo de nuevo");
      } else {
        setResMessage(err.message);
      }
      setErrorCaptcha(false);
      formikHelpers.resetForm();
      resetPassCaptchaRef.current.reset();
    }
  }

  const cleanErrors = () => {
    resetResetPass();
    setResMessage("");
    setErrorCaptcha(false);
  }

  const resetPassFormInitialValues: resetPassFields = {
    typeDocument: '',
    document: '',
    email: '',
    emailConfirmation: '',
  }

  // const handleSubmit = async (values: resetPassFields, { resetForm }: FormikHelpers<resetPassFields>) => {
  //   console.log("coco", values);
  //   setPassChanged(true);
  // };

  return (
    <S.ResetPass>
      {
        (resetPassLoading || resetPassError || errorCaptcha || resMessage) && 
        <Portal>
          <ModalContainer>
            <CustomModal
              title={
                resetPassLoading 
                ? "Cargando" 
                : (resetPassError || errorCaptcha) 
                  ? "Error"
                  : "Contraseña Nueva"
              }
              description={
                resetPassLoading 
                ? "" 
                : resMessage
              }
              type={
                resetPassLoading 
                ? "loading" 
                : (resetPassError || errorCaptcha) 
                  ? "failed"
                  : "success"
              }
              action={cleanErrors}
            />
          </ModalContainer>
        </Portal>
      }
      <div className="reset-pass">
        <div className="reset-pass__left">
          <div className="box">
            <i className="box__icon icon icon-reestablecer-contrasea"/>
            <div className="box__text">{t("resetPass.pretitle")}</div>
            <div className="box__text-big">{t("resetPass.title")}</div>
          </div>
        </div>
        <div className="reset-pass__right">
          <Formik
            initialValues={resetPassFormInitialValues}
            validationSchema={Yup.object({
              typeDocument: Yup.string()
                .required(t("validation.required")),
              document: Yup.string()
                .required(t("validation.required")),
              email: Yup.string()
                .email(t("validation.email"))
                .required(t("validation.required")),
              emailConfirmation: Yup.string()
                .email(t("validation.email"))
                .required(t("validation.required"))
                .when("email", {
                  is: (email: string) => (email && email.length > 0 ? true : false),
                  then: Yup.string().oneOf(
                    [Yup.ref("email")],
                    `${t("validation.matchEmail")}`
                  ),
                }),
            })}
            onSubmit={handleSubmit}
          >
            {({handleSubmit, values, handleChange}) => (
                <form className="reset-pass-form" onSubmit={handleSubmit}>
                  <div className="inputs-container">

                    {/* <div className="inputs-flex inputs-flex--password"> */}

                      <div className="input-content">
                        <div className="input-label">
                          <span>{t("resetPass.email")}</span>
                        </div>
                        <div className="double-input">
                        <Field name="email">
                          {(props: FieldProps) => (
                            <InputForm 
                              type="email"
                              {...props}
                            />  
                          )}
                        </Field>
                        </div>
                      </div>

                      <div className="input-content">
                        <div className="input-label">
                          <span>{t("resetPass.emailConfirmation")}</span>
                        </div>
                        <div className="double-input">
                        <Field name="emailConfirmation">
                          {(props: FieldProps) => (
                            <InputForm 
                              type="email"
                              {...props}
                            />  
                          )}
                        </Field>
                        </div>
                      </div>

                      <div className="input-content">
                        <div className="input-label">
                          <span>{t("register.typeDocument")}</span>
                        </div>
                        <div className="double-input">
                        <Field name="typeDocument">
                          {(props: FieldProps) => (
                            <SelectForm
                              {...props}
                              items={TYPE_DOCUMENTS}
                            />  
                          )}
                        </Field>
                        </div>
                      </div>

                      <div className="input-content">
                        <div className="input-label">
                          <span>{t("resetPass.document")}</span>
                        </div>
                        <div className="double-input">
                        <Field name="document">
                          {(props: FieldProps) => (
                            <InputForm
                            type={values.typeDocument === "Pasaporte"  || values.typeDocument === "Documento extranjero"  ? "text" : "number"}
                              {...props}
                            /> 
                          )}
                        </Field>
                        </div>
                      </div>

                    </div>
                  {/* </div> */}
                  <div className="captcha-container">
                    {
                      // env !== 'test' && env !== 'development' &&
                      <ReCAPTCHA
                        sitekey={reCaptchaKey!}
                        ref={resetPassCaptchaRef}
                      />
                    }
                  </div>
                  <div className="button-container">
                    <Button 
                    type={resetPassLoading ? "button" : "submit"}
                      text={
                        !resetPassLoading
                        ? t("resetPass.buttonText")
                        : `${t("global.loading")}...`
                      }
                      fontSize={17}
                    />
                  </div>
                </form>
            )}
          </Formik>
        </div>
      </div>
    </S.ResetPass>
  );
}

export default ResetPassword;
