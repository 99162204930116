
import { gql } from "@apollo/client";

export const QUERY_GET_CATEGORIAS = gql`
  query QUERY_GET_CATEGORIAS($tipoPeticionId: Int!) {
    pmccategoria(where: {version: {_eq: "3"}, tipoPeticionId: {_eq: $tipoPeticionId}}, order_by: {id: asc}) {
      categoria
      filtroCentroFacilitador
      filtroTramiteYServicio
      id
      tipoPeticionId
      pmccategoria_a_tramiteyservicio {
        filtroTramiteYServicio
        tipoTramiteYServicio
        pmctramiteyservicio {
          id
          nombre
          tipoTramite
        }
      }
      pmcclasificacion_a_centrofacilitador {
        filtroCentroFacilitador
        tipoCentroFacilitador
        pmccentrofacilitador {
          tipoCentro
          ubicacion
          id
        }
      }
    }
  }
`
