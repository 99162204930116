import { gql } from "@apollo/client";

export const MUTATION_LOGIN = gql`
  mutation MUTATION_LOGIN(
    $email: String!, 
    $contrasena: String!
    $captchaToken: String
  ) {
    login(
      email: $email, 
      contrasena: $contrasena,
      captchaToken: $captchaToken
    ) {
      accessToken
    }
  }
`;

export const MUTATION_REGISTER = gql`
  mutation MUTATION_REGISTER(
    $contrasena: String!
    $email: String!
    $numerodni: String!
    $primerApellido: String!
    $primerNombre: String!
    $segundoApellido: String!
    $segundoNombre: String!
    $tipoDni: String!
    $telefono: String! 
    $sexo: String!, 
    $discapacidad: String!, 
    $condicionDiscapacidad: String,
    $razonSocial: String,
    $direccion: String!, 
    $celular: String!,
    $ciudadResidenciaId: Int!, 
    $ciudadnacimientoid: Int!, 
    $comunidadId: Int!, 
    $captchaToken: String
  ) {
    registro(
      contrasena: $contrasena, 
      email: $email, 
      numerodni: $numerodni, 
      primerApellido: $primerApellido, 
      primerNombre: $primerNombre, 
      segundoApellido: $segundoApellido, 
      segundoNombre: $segundoNombre, 
      tipoDni: $tipoDni,
      sexo: $sexo,
      telefono: $telefono,
      celular: $celular,
      direccion: $direccion,
      discapacidad: $discapacidad,
      condicionDiscapacidad: $condicionDiscapacidad,
      razonSocial: $razonSocial,
      ciudadnacimientoid: $ciudadnacimientoid,
      comunidadId: $comunidadId,
      ciudadResidenciaId: $ciudadResidenciaId,
      captchaToken: $captchaToken
      ) {
      message
    }
  }
`;

export const MUTATION_VERIFY_EMAIL = gql`
  mutation MUTATION_VERIFY_EMAIL(
    $tokenVerificacion: String!    
    $email: String!
  ) {
    validarEmail(
      tokenVerificacion: $tokenVerificacion,    
      email: $email
    ) {
      message
    }
  }
`;

export const MUTATION_RESET_PASS = gql`
  mutation MUTATION_RESET_PASS(
    $email: String!
    $numerodni: String!
    $captchaToken: String
  ) {
    recuperarContrasena(
      email: $email,
      numerodni: $numerodni
      captchaToken: $captchaToken
    ) {
      message
    }
  }
`;

export const MUTATION_RECOVERY_EMAIL = gql`
  mutation MUTATION_RECOVERY_EMAIL(
    $tipoDni: String!
    $numerodni: String!
    $captchaToken: String
  ) {
    recordarCorreo(
      tipoDni: $tipoDni,
      numerodni: $numerodni
      captchaToken: $captchaToken
    ) {
      message
    }
  }
`;

export const MUTATION_CHANGE_PASSWORD = gql`
  mutation MUTATION_CHANGE_PASSWORD(
    $contrasena: String!, 
    $nuevaContrasena: String!
  ) {
    cambiarContrasena(
      contrasena: $contrasena, 
      nuevaContrasena: $nuevaContrasena
      ) {
      message
    }
  }
`

export const MUTATION_ID_EXISTANCE = gql`
  mutation MUTATION_ID_EXISTANCE(
    $numerodni: String!
  ) {
    dniDisponible(numerodni: $numerodni) {
      message
    }
  }
`

export const MUTATION_EMAIL_EXISTANCE = gql`
  mutation MUTATION_EMAIL_EXISTANCE(
    $email: String!
  ) {
    emailDisponible(email: $email) {
      message
    }
  }
`

export const MUTATION_EMAIL_VALIDATION = gql`
  mutation MUTATION_EMAIL_VALIDATION(
    $email: String!
    $captchaToken: String
  ) {
    verificarEmailRegistro(email: $email, captchaToken: $captchaToken) {
      message
    }
  }
`
export const MUTATION_EMAIL_VALIDATION_TOKEN = gql`
  mutation MUTATION_EMAIL_VALIDATION_TOKEN(
    $email: String!
    $token: String!
    $captchaToken: String
  ) {
    verificarEmailTokenRegistro(email: $email, codigoVerif: $token, captchaToken: $captchaToken) {
      message
    }
  }
`