import styled from "styled-components";

export const RegisterForm = styled.div`
  .response-message {
    margin: 19px 0;
    text-align: center;
    font-size: 16px;
    font-family: 'Poppins Bold';
    color: ${({theme}) => theme.darkBlue};
  }
  .register-form {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .inputs-container {
      justify-content: flex-start;
      align-items: center;
      display: flex;
      width: 100%; 
      flex-wrap: wrap;
      max-width: 610px;
      .input-content {
        min-width: 275px;
        display: flex;
        flex-direction: column;
        margin: 0 15px;
        position: relative;
        .input-label {
          display: flex;
          font-size: 13px;
          padding-bottom: 1px;
          align-items: center;
          span {
            opacity: 0.7;
          }
          .tooltip-container {
            margin-left: 10px;
          }
        }
      }
      &--password {
        margin-top: 40px;
      }
      .inputs-flex {
        display: flex;
      }
    }
    .captcha-container {
      align-self: center;
      margin-bottom: 20px;
    }
    .list-container {
      font-size: 10px;
      margin-bottom: 20px;
    }
    .button-container{
      width: 200px;
      justify-content: center;
      display: flex;
      margin: auto;
    }
  }
  .box-accept {
    background-color: ${({theme}) => theme.white}; 
    border-radius: 10px;
    max-width: 820px;
    margin: 20px;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    &--disabled {
      opacity: 0.4;
    }
    &__description {
      margin-top: 10px;
      font-size: 12px;
      opacity: 0.8;
      padding: 0 50px;
      text-align: justify;
    }
    &__input-container {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
    &__input-radius {
      display: flex;
      width: 90px;
      justify-content: space-around;
      margin-left: 10px;
    }
  }
  .habeasError{
    font-size: 9px;
    color: ${({theme}) => theme.red};
    text-align: center;
    margin-top: 20px;
  }
`;