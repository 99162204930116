import React, { useRef, useState, useEffect, useContext } from "react";
import { FieldProps, useField } from "formik";
import { ThemeContext } from 'styled-components';
import * as S from "./SelectForm.style";
import { SelectItem } from "../../../models/logicModels/SelectItem.js";

interface Props extends FieldProps {
  placeholder?: string, 
  icon?: string,
  padding?: string,
  items?: SelectItem[],
  height?: string,
  fontSize?: string,
  errorOption?: boolean,
  disabled?: boolean,
  itemsDisabled?: SelectItem[],
  setErrors?: () => void,
}

function SelectForm({ 
  placeholder, 
  icon,
  setErrors,
  padding,
  items= [],
  itemsDisabled = [],
  height,
  fontSize,
  disabled,
  errorOption = true,
  ...props
}: Readonly<Props>) {  

  const container = useRef<HTMLDivElement>(null);
  const textInput = useRef<HTMLInputElement | null>(null);
  const [field, meta, helpers] = useField(props.field.name);
  const [listActive, setListActive] = useState<boolean>(false);
  const { setValue } = helpers;
  const theme = useContext(ThemeContext);  

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (container.current && !container.current.contains(e.target)) {
        setListActive(false)
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  function handleChange(item: string | number | undefined) {
    setValue(item)
    setListActive(false);
  }
  

  return (
    <S.SelectForm 
      padding={padding} 
      listActive={listActive} 
      error={meta}
      fontSize={fontSize}
      disabled={disabled}
    >
      <div onClick={(e) => {}} ref={container}>
        <div className="input-container" onClick={
          !disabled 
          ? (e) => { textInput.current && textInput.current.focus(); setListActive(true) }
          : () => {}
        }>
          <i className={'icon-input-form icon zaia-' + icon} style={{ color: listActive ? theme.inputSelected : theme.inputPlaceholder }} />
          <input 
            disabled={disabled}
            ref={textInput}
            {...field} 
            value={items?.find( (item: SelectItem) => item.value === field.value)?.text ? items?.find( (item: SelectItem) => item.value === field.value)?.text : ''}
            placeholder={placeholder}
            readOnly
          />
          <i className={'icon-input-form-arrow icon icon-select'} />
        </div>
        <div className="select-list">
          {
            items?.map((item: SelectItem) => {
              return (
                <div className="select-item" key={item.value} onClick={() => handleChange(item.value)}>
                  <label>{item.text}</label>
                </div>
              )
            })
          }
          {
            itemsDisabled?.map((item: SelectItem) => {
              return (
                <div className="non-select-item" key={item.value}>
                  <label>{item.text}</label>
                </div>
              )
            })
          }
        </div>
      </div>
      {
        errorOption ?
        <div className="input-error">{
          meta.touched && meta.error ? 
          <>
            <i className="icon zaia-icono-ayuda"></i>
            {meta.error }
          </>
          : null}
        </div> : null
      }
    </S.SelectForm>
  );
}

export default SelectForm;
