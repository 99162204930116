import { Field, FieldProps, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Fragment, useEffect, useState } from "react";
import Button from "../../shared/button";
import * as S from "./ListOfPQRS.style";
import { useTranslation } from "react-i18next";
import InputForm from "../../shared/inputForm";
import { useQuery } from "@apollo/client";
import { CONSULT_ALL_PQRS, CONSULT_ALL_PQRS_FILTERED, CONSULT_ALL_PQRS_FILTERED_REQUEST_NUMBER, CONSULT_ALL_PQRS_FILTERED_STATUS, GET_DOCUMENTS_BY_PQRS } from "../../../graphql/queries/Pqrs";
import { Pqrs } from "../../../models/dbModels/Pqrs";
import * as P from "../../../utils/pipes";
import Tooltip from "../../shared/tooltip";
import SelectForm from "../../shared/selectForm";
import { REQUEST_TYPE } from "../../../utils/constants";
import Portal from "../../shared/modals/portal";
import ModalContainer from "../../shared/modals/modalContainer";
import CustomModal from "../../shared/modals/customModal";
function ListOfPQRS() {

  interface  ConsultPqrsFields {
    settledNumber?: string
    requestStatus?: string
  }

  const {t} = useTranslation("global");
  const [errors, setErrors] = useState<any>();
  const [pqrsSelected, setPqrsSelected] = useState<any>();
  const [paginatioNumber, setPaginatioNumber] = useState<number>(1);
  const [consultedDataPqrs, setConsultedDataPqrs] = useState<ConsultPqrsFields>({});

  const { data: documentList } = useQuery(
    GET_DOCUMENTS_BY_PQRS,
    {
      variables: {
        pqrsId: pqrsSelected
      }
    }
  );

  const {data: allPqrsData, loading: allPqrsLoading, error: allPqrsError}
  = useQuery (
    consultedDataPqrs.settledNumber && consultedDataPqrs.requestStatus
    ? CONSULT_ALL_PQRS_FILTERED
    : consultedDataPqrs.settledNumber
        ? CONSULT_ALL_PQRS_FILTERED_REQUEST_NUMBER
        : consultedDataPqrs.requestStatus
          ? CONSULT_ALL_PQRS_FILTERED_STATUS
          : CONSULT_ALL_PQRS,
    {
      variables: 
      consultedDataPqrs.settledNumber && consultedDataPqrs.requestStatus
      ? {
        offset: ((paginatioNumber * 10) - 10),
        settledNumber: ("%" + consultedDataPqrs.settledNumber?.toString() + "%"),
        requestStatus: consultedDataPqrs.requestStatus
      }
      : consultedDataPqrs.settledNumber
        ? {
          offset: ((paginatioNumber * 10) - 10),
          settledNumber: ("%" + consultedDataPqrs.settledNumber?.toString() + "%")
        }
        : consultedDataPqrs.requestStatus
          ? {
            offset: ((paginatioNumber * 10) - 10),
            requestStatus: consultedDataPqrs.requestStatus
          }
          : {
            offset: ((paginatioNumber * 10) - 10)
          }
    }
  );  

  useEffect(() => {
    if(allPqrsError)
      setErrors(allPqrsError);
  }, [allPqrsError])
  

  enum States {
    Recibida = "#000000",
    Solucionada = "#10ab2f",
    Rechazada = "#d52518",
    Asignada = "#000000",
    Incompleta = "#d59618"
  }  

  const handleSubmit = async (values: ConsultPqrsFields, formikHelpers: FormikHelpers<ConsultPqrsFields>) => {
    setConsultedDataPqrs(values);
  }

  const consultFormInitialValues: ConsultPqrsFields = {
    settledNumber: '',
    requestStatus: ''
  }  

  const changeOffset = (change: "next" | "back") => {
    if(change === "next") {
      if((Math.round((allPqrsData?.pmcpqrs_aggregate.aggregate.count)/10)) > paginatioNumber)
        setPaginatioNumber(paginatioNumber + 1);
    } else {
      if(paginatioNumber > 1) 
        setPaginatioNumber(paginatioNumber - 1);
    }
  }

  const getStateColor = (estado: string): string  => {
    let color: string;
    switch (estado) {
      case "Recibida": 
        color = States.Recibida
        break;
      case "Rechazada": 
        color = States.Rechazada
        break;
      case "Asignada": 
        color = States.Asignada
        break;
      case "Incompleta": 
        color = States.Incompleta
        break;
      case "Solucionada": 
        color = States.Solucionada
        break;
      default:
        color = '#18A1D5'
        break;
    }

    return color;
  }

  const cleanErrors = () => {
    setErrors(null);
  }

  const handleDownload = (url: string) => {
    window.open(url);
  }

  return (
    <S.ListOfPQRS>
      {
        (errors || allPqrsLoading) && 
        <Portal>
          <ModalContainer>
            <CustomModal
              title={allPqrsLoading ? "Cargando" : "Error"}
              description={
                allPqrsLoading 
                ? "" 
                : "No se pudieron cargar sus PQRS, intentelo más tarde"
              }
              type={allPqrsLoading ? "loading" : "failed"}
              action={cleanErrors}
            />
          </ModalContainer>
        </Portal>
      }
      <div className="list">
        <div className="list__left">
          <div className="box">
            <i className="box__icon icon icon-listado-de-pqrs"/>
            <div className="box__text">{t("listOfPqrs.pretitle")}</div>
            <div className="box__text-big">{t("listOfPqrs.title")}</div>
          </div>
        </div>
        <div className="list__right">
          <Formik
            initialValues={consultFormInitialValues}
            enableReinitialize={true}
            // validationSchema={}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <form className="pqrs-list-form" onSubmit={ props.handleSubmit }>
                <div className="inputs-container">
                  <div className="input-content">
                    <div className="input-label">
                      <span>{t("consultPqrs.settledNumber")}</span>
                      <div className="required-indicator">*</div>
                    </div>
                    <Field name="settledNumber">
                      {(props: FieldProps) => (
                        <InputForm 
                          type="text"
                          {...props}
                        />  
                      )}
                    </Field>
                  </div>
                  <div className="input-content">
                    <div className="input-label">
                      <span>{t("consultPqrs.requestStatus")}</span>
                    </div>
                    <Field name="requestStatus">
                      {(props: FieldProps) => (
                        <SelectForm
                          {...props}
                          items={
                            [ 
                              {
                                value: '',
                                text: 'Todos'
                              },
                              ...REQUEST_TYPE
                            ]
                          }
                        />   
                      )}
                    </Field>
                  </div>
                </div>
                <div className="button-container">
                  <Button 
                    type="submit"
                    text={t("consultPqrs.buttonText")}
                  />
                </div>
              </form>
            )}
          </Formik>
          <div className="pqrs-list-container">
            <div className="pqrs-pagination">
              <i className="pqrs-pagination__icon icon icon-back" onClick={() => changeOffset("back")}/>
              {paginatioNumber}/{(Math.round((allPqrsData?.pmcpqrs_aggregate.aggregate.count)/10))}
              <i className="pqrs-pagination__icon icon icon-next" onClick={() => changeOffset("next")}/>
            </div>
            <div className="pqrs-table-container">
            <table className="pqrs-table">
              <thead className="pqrs-table__head" >
                <tr>
                  <th></th>
                  <th>Nombre</th>
                  <th>Número de radicado</th>
                  <th>
                    <div className="pqrs-table__th">
                      Estado
                      <div className="tooltip-container">
                        <Tooltip
                          text={t("createPqrs.indicators.name1")}
                        />
                      </div>
                    </div>
                  </th>
                  <th>Fecha de creación </th>
                  <th>
                    <div className="pqrs-table__th">
                      Ultima actualizacion
                      <div className="tooltip-container">
                        <Tooltip
                          text={t("createPqrs.indicators.name1")}
                        />
                      </div>
                    </div>  
                  </th>
                  <th>Tipo de solicitud</th>
                  {/* <th>Acciones</th>                */}
                </tr>
              </thead>
              <tbody className="pqrs-table__body">
                {
                  allPqrsData?.pmcpqrs.map((pqrs: Pqrs, index: number) => {                                   
                    return (
                      <Fragment
                        key={pqrs.id}
                      >
                        <tr 
                          className={`pqrs-item ${pqrsSelected === pqrs.id ? 'pqrs-item--selected' : ''}`}
                          onClick={
                            () => setPqrsSelected(pqrsSelected === pqrs.id ? null : pqrs.id)
                          }
                        >
                          <td>
                            <div className="pqrs-item__numeration">
                              {
                                pqrsSelected === pqrs.id 
                                ? <i className="pqrs-item__numeration-icon icon icon-up"/> : 
                                <i className="pqrs-item__numeration-icon icon icon-down"/>
                              }
                              <div className="pqrs-item__numeration-number">
                                {(index + 1)+((paginatioNumber*10)-10)}
                              </div>
                            </div>
                          </td>
                          <td>
                            {pqrs.pmccontacto?.primerNombre} {pqrs.pmccontacto?.primerApellido} 
                          </td>
                          <td className="pqrs-item__responsive-hide">{pqrs.numeroRadicado}</td>
                          <td 
                            className="pqrs-item__state pqrs-item__responsive-hide"
                            style={{
                              "color": pqrsSelected === pqrs.id ? '' : getStateColor(pqrs.estado),
                              "backgroundColor": pqrsSelected === pqrs.id ? getStateColor(pqrs.estado) : ''
                            }}
                          >{pqrs.estado}</td>
                          <td className="pqrs-item__responsive-hide" >{P.formatNumberDate(new Date(pqrs.agregadoEn), true)}</td>
                          <td className="pqrs-item__responsive-hide" >{P.formatNumberDate(new Date(pqrs.agregadoEn), true)}</td>
                          <td className="pqrs-item__responsive-hide" >{pqrs.pmccategorium?.pmctipopeticion.nombre}</td>
                          {/* {
                            pqrsSelected === pqrs.id 
                            ? <td>
                              <i className="pqrs-item__edition-icon icon icon-editar"/>
                            </td>
                            : '' 
                          } */}
                        </tr>
                        {
                          pqrsSelected === pqrs.id 
                          ? <tr className="pqrs-item__details-tr">
                            <td colSpan={7}>
                              <div className="pqrs-item__details">
                                <div className="detail-table-item">
                                  <div className="detail-table-item__title">Radicado N°</div>
                                  <div className="detail-table-item__descript">{pqrs.numeroRadicado}</div>
                                </div>
                                <div 
                                  className="detail-table-item"
                                  style={{
                                    "color": pqrsSelected === pqrs.id ? '' : getStateColor(pqrs.estado),
                                    "backgroundColor": pqrsSelected === pqrs.id ? getStateColor(pqrs.estado) : ''
                                  }}
                                >
                                  <div className="detail-table-item__title">Estado</div>
                                  <div className="detail-table-item__descript">{pqrs.estado}</div>
                                </div>
                                <div className="detail-table-item">
                                  <div className="detail-table-item__title">Fecha de creación</div>
                                  <div className="detail-table-item__descript">{P.formatNumberDate(new Date(pqrs.agregadoEn), true)}</div>
                                </div>
                                <div className="detail-table-item">
                                  <div className="detail-table-item__title">Ultima actualizacion</div>
                                  <div className="detail-table-item__descript">{P.formatNumberDate(new Date(pqrs.agregadoEn), true)}</div>
                                </div>
                                <div className="detail-table-item">
                                  <div className="detail-table-item__title">Tipo de solicitud</div>
                                  <div className="detail-table-item__descript">{pqrs.pmccategorium?.pmctipopeticion.nombre}</div>
                                </div>
                                <div className="detail-item">
                                  {
                                    pqrs.agregadoPor
                                    ? <div className="detail-item__warning">
                                      <div className="required-indicator">*</div>
                                        Esta PQRS fue radicada por un funcionario de migración Colombia.
                                    </div>
                                    : null
                                  }
                                  <div className="detail-item__title">Nombre de quien radicó</div>
                                  <div className="detail-item__descript">
                                    {
                                      pqrs.pmccontactoBySegundocontactoid 
                                      ? `${pqrs.pmccontactoBySegundocontactoid?.primerNombre} ${pqrs.pmccontactoBySegundocontactoid?.primerApellido}`
                                      : `${pqrs.pmccontacto?.primerNombre} ${pqrs.pmccontacto?.primerApellido}`
                                    }
                                    
                                  </div>
                                  <div className="detail-item__descript detail-item__descript--no-top">
                                    {
                                      pqrs.pmccontactoBySegundocontactoid 
                                      ? pqrs.pmccontactoBySegundocontactoid?.email
                                      : pqrs.pmccontacto?.email
                                    } 
                                  </div>
                                  <div className="detail-item__title">Respuesta a su solicitud</div>
                                  {
                                    pqrs.pmchistorials?.[0]?.comentario 
                                    ? <div className="detail-item__descript">
                                      {pqrs.pmchistorials?.[0]?.comentario}
                                    </div>
                                    : <div className="detail-item__descript detail-item__descript--fade">
                                      Aun no hay respuesta a esta solicitud
                                    </div>
                                  }
                                  <div className="detail-item__title">Comentario del solicitante</div>
                                  <div className="detail-item__descript">
                                    {pqrs.comentario}
                                  </div>
                                </div>
                                <div className="detail-item">
                                  <div className="detail-item__title">
                                    <div className="detail-item__title-item-point">•</div>
                                    Categoria
                                  </div>
                                  <div className="detail-item__descript">
                                    {pqrs.pmccategorium?.categoria}
                                  </div>
                                  {
                                    pqrs.pmcsubcategorium 
                                    ? <>
                                      <div className="detail-item__title">
                                        <div className="detail-item__title-item-point">•</div>
                                        Clasificación
                                      </div>
                                      <div className="detail-item__descript">
                                        {pqrs.pmcsubcategorium?.subcategoria}
                                      </div>
                                    </>
                                    : null
                                  }
                                  {pqrs.pmccentrofacilitador || pqrs.pmctramiteyservicio
                                    ? <>
                                      <div className="detail-item__title">
                                      <div className="detail-item__title-item-point">•</div>
                                        {
                                          (pqrs.pmccentrofacilitador || pqrs.pmctramiteyservicio) && pqrs.pmcsubcategorium 
                                          ? "Subcategoria" 
                                          : "Clasificación"
                                        }
                                      </div>
                                      <div className="detail-item__descript">
                                        { pqrs.pmccentrofacilitador
                                          ? pqrs.pmccentrofacilitador?.ubicacion
                                          : pqrs.pmctramiteyservicio?.nombre}
                                      </div>
                                    </>
                                    : null 
                                  }
                                  <div className="detail-item__title">Lista de documentos cargados por el ciudadano</div>
                                  {
                                    documentList?.archivosPqrs.message.archivos.usuario.length === 0 && 
                                    <div className="detail-item__descript detail-item__descript--fade">
                                      No hay archivos cargados por el ciudadano
                                    </div>
                                  }
                                  <div className="detail-item__document-list">
                                    {
                                      documentList?.archivosPqrs.message.archivos.usuario.map((doc: any) => {
                                        return <div 
                                          className="document-item" 
                                          key={doc.nombreGoogle} 
                                          onClick={
                                            () => handleDownload(doc.link)
                                          }>
                                            {doc.nombreGoogle}
                                          </div>
                                      })
                                    }
                                  </div>
                                  <div className="detail-item__title">Lista de documentos cargados por migración</div>
                                  {
                                    documentList?.archivosPqrs.message.archivos.funncionario.length === 0 && 
                                    <div className="detail-item__descript detail-item__descript--fade">
                                      No hay archivos cargados por migración
                                    </div>
                                  }
                                  <div className="detail-item__document-list">  
                                    {
                                      documentList?.archivosPqrs.message.archivos.funncionario.map((doc: any) => {
                                        return <div 
                                          className="document-item" 
                                          key={doc.nombreGoogle} 
                                          onClick={
                                            () => handleDownload(doc.link)
                                          }>
                                            {doc.nombreGoogle}
                                          </div>
                                      })
                                    }
                                  </div>
                                </div>
                                {/* <div className="detail-item">
                                  <div className="detail-item__title">Lista de documentos cargados por migracién</div>
                                  <div className="detail-item__document-list">
                                    <div className="document-item">document 1</div>
                                    <div className="document-item">document 2</div>
                                    <div className="document-item">document 3</div>
                                  </div>
                                </div> */}
                              </div>
                            </td>
                          </tr>
                          : null
                        }
                      </ Fragment>
                    )
                  })
                }

                {/* <tr>
                  <td colSpan={6}>
                    <div className="sub-items">
                      <div className="sub-item">
                      </div>
                      <div className="sub-item">
                        <table>
                          <tr>
                            <th>Lista de documentos cargados por el ciudadano</th>
                          </tr>
                          <tr>
                            <td>Documento 1</td>
                          </tr>
                          <tr>
                            <td>Documento 2</td>
                          </tr>
                          <tr>
                            <td>Documento 3</td>
                          </tr>
                        </table>
                      </div>
                      <div className="sub-item">
                      <table>
                          <tr>
                            <th>Lista de documentos cargados por migración</th>
                          </tr>
                          <tr>
                            <td>Documento 1</td>
                          </tr>
                          <tr>
                            <td>Documento 2</td>
                          </tr>
                          <tr>
                            <td>Documento 3</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr> */}

              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    </S.ListOfPQRS>
  );
}

export default ListOfPQRS;