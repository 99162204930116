import styled from "styled-components";

export const ConsultPQRS = styled.div`
  display: flex;
  .consult {
    display: flex;
    margin: auto;
    /* flex-direction: column; */
    margin: 60px 0;
    width: 100%;
    justify-content: center;
    &__left {
      align-self: center;
      padding: 50px 30px 50px 0;
      .box {
        width: 220px;
        box-shadow: 0px 0px 9px #00000012;
        border-radius: 20px;
        background-color: ${({theme}) => theme.white};
        margin: 20px;
        padding: 40px 40px;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        &__icon {
          justify-content: center;
          font-size: 65px;
          color: ${({theme}) => theme.softBlue};
        }
        &__text {
          color: ${({theme}) => theme.blackText};
          font-size: 13px;
          margin-top: 5px;
        }
        &__text-big {
          color: ${({theme}) => theme.darkBlue};
          font-size: 30px;
          font-family: 'Poppins Bold';
          border-bottom: 2px solid #18A1D5;
          line-height: 32px;
          width: fit-content;
          display: flex;
          margin: auto;
        }
      }
    }
    &__right {
      width: 800px;
      align-self: center;
      .response-message {
        margin: 19px 0;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins Bold';
        color: ${({theme}) => theme.darkBlue};
      }
      .consult-form {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .inputs-container {
          align-self: center;
          .input-content {
            text-align: center;
            width: 240px;
            display: flex;
            flex-direction: column;
            margin: 0 10px;
            .input-label {
              font-size: 13px;
              padding-bottom: 1px;
              span {
                opacity: 0.7;
              }
            }
          }
        }
        .captcha-container {
          align-self: center;
          margin-bottom: 20px;
        }
        .button-container{
          width: 200px;
          justify-content: center;
          display: flex;
          margin: auto;
        }
      }

      .pqrs-consulted {
        &__form {
          background-color: ${({theme}) => theme.white};
          box-shadow: 0px 0px 9px #00000012;
          border-radius: 14px;
          padding: 50px 60px 20px 60px;
          margin: 0 20px;
        }
        &__title {
          display: flex;
          align-items: center;
          justify-content: center;
          &-icon {
            color: ${({theme}) => theme.green};
            font-size: 30px;
            margin-right: 10px;
          }
          &-text {
            font-size: 20px;
            font-family: 'Poppins Bold';
            color: ${({theme}) => theme.darkBlue};
          }
        }
        &__info {
          display: flex;
          justify-content: center;
          margin-top: 10px;
          align-items: center;
          .info-item {
            margin: 10px 20px;
            display: flex;
            align-items: center;
            color: ${({theme}) => theme.darkBlue};
            &__label {
              font-family: 'Poppins Bold';
              margin-right: 10px;
            }
            &__data {
              background-color: ${({theme}) => theme.greyBox};
              padding: 2px 5px;
              border-radius: 6px;
            }
          }
        }
        &__table {
          margin: 15px 0;
          display: flex;
          justify-content: center;
          table {
            font-size: 12px;
            th {
              background-color: ${({theme}) => theme.darkBlue};
              color: ${({theme}) => theme.white};
              padding: 2px 16px;
            }
            td {
              padding: 2px 16px;
              border: 2px #F2F2F2 solid;
            }
          }
        }
        &__comment {
          background: #F3F3F3;
          box-shadow: 0px 3px 6px #00000029;
          border-radius: 15px;
          min-height: 100px;
          width: auto;
          padding: 10px;
          box-sizing: border-box;
          display: flex;
          margin: auto;
        }
        &__info-laws {
          font-size: 11px;
          font-weight: 600;
          .info-laws__text {
            margin: 20px 0;
          }
        }
        &__button {
          display: flex;
          margin: 20px auto;
          border: none;
          background-color: ${({theme}) => theme.white};
          color: ${({theme}) => theme.softBlue};
          font-size: 22px;
          font-family: 'Poppins Bold';
          padding: 5px 40px;
          border-radius: 21px;
          width: fit-content;
          cursor: pointer;
        }
      }
    }
  }

  @media only screen and (max-width: 980px) {
    .consult {
      flex-direction: column;
      &__left {
        padding: 0 0 20px 0;
      }
      &__right {
        width: auto;
      }
    }

    .pqrs-consulted {
      &__info {
        flex-direction: column;
      }
      &__form {
        padding: 50px 20px 20px 20px !important;
      }
      &__title {
        text-align: center;
      }
    }
  }
`;
