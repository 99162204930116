import { Field, FieldProps, Formik, FormikHelpers } from "formik";
import Button from "../../shared/button";
import * as S from "./Login.style";
import * as Yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthChange } from "../../../redux/reducers/authSlice";
import { RootState } from "../../../redux/store";
import { ROUTES } from "../../../utils/constants";
import ReCAPTCHA from "react-google-recaptcha";
import { setToken } from "../../../services/sharedService";

// GraphQL
import { useMutation } from "@apollo/client";
import { MUTATION_LOGIN } from "../../../graphql/mutations/Auth";
import { useTranslation } from "react-i18next";
import InputForm from "../../shared/inputForm";
import { Link } from "react-router-dom";
import ModalContainer from "../../shared/modals/modalContainer";
import Portal from "../../shared/modals/portal";
import CustomModal from "../../shared/modals/customModal";


const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY
// const env = process.env.REACT_APP_ENV;

function Login() {
  const {t } = useTranslation("global");
  const [login, { loading: loginLoading, error: loginError, reset: resetLogin }] = useMutation(MUTATION_LOGIN);
  const [errorCaptcha, setErrorCaptcha] = useState<boolean>();
  const [resMessage, setResMessage] = useState<string>();
  const loginCaptchaRef = useRef<any>(null);
  const { RESET_PASSWORD, EMAIL_RECOVERY } = ROUTES;

  const loginData = useSelector((state: RootState) => state.login.loginData);
  const dispatch = useDispatch();

  useEffect(() => {}, [loginData]);

  interface  loginFields {
    email: string,
    password: string,
  }

  const loginFormInitialValues: loginFields = {
    email: '',
    password: ''
  }

  const handleSubmit = async (values: loginFields, formikHelpers: FormikHelpers<loginFields>) => {    
    // if (env !== 'test' && env !== 'development') {
      if (loginCaptchaRef.current.getValue()) {        
        setErrorCaptcha(false);
        loginFunction(values, formikHelpers);
      } else {
        setErrorCaptcha(true);
        setResMessage("Error de Captcha");
        loginCaptchaRef.current.reset();
      }
    // } else {
      // loginFunction(values, formikHelpers);
    // }
  };

  const loginFunction = async (values: loginFields, formikHelpers: FormikHelpers<loginFields>) => {
    try {
      const accessToken = await  getAccessToken(values);
      setToken(accessToken);
      dispatch(isAuthChange(true));
    } catch (err) {
      formikHelpers.resetForm();
      loginCaptchaRef.current.reset();
    }
  }

  const getAccessToken = async (values: loginFields) => {
    try {
      const { data : {
          login: { accessToken },
        },
      } 
      = await login({
        variables: {
          email: values.email.toLowerCase(),
          contrasena: values.password,
          captchaToken: loginCaptchaRef.current.getValue()
        },
      });
      dispatch(isAuthChange(true));
      return accessToken
    } catch (err: any) {
      setErrorCaptcha(false);
      setResMessage(err.message);
      loginCaptchaRef.current.reset();
      console.log("error login", err);
      return ''
    }
  }

  const cleanErrors = () => {
    resetLogin();
    setResMessage("");
    setErrorCaptcha(false);
  }

  return (
    <S.Login>
      {
        (loginLoading || loginError || errorCaptcha) && 
        <Portal>
          <ModalContainer>
            <CustomModal
              title={loginLoading ? "Cargando" : "Error"}
              description={
                loginLoading 
                ? "" 
                : resMessage
              }
              type={loginLoading ? "loading" : "failed"}
              action={cleanErrors}
            />
          </ModalContainer>
        </Portal>
      }
      <div className="login">
        <div className="login__left">
          <div className="box">
            <i className="box__icon icon icon-usuario-registrado"/>
            <div className="box__text">{t("login.pretitle")}</div>
            <div className="box__text-big">{t("login.title")}</div>
          </div>
        </div>
        <div className="login__right">
          <Formik
            initialValues={loginFormInitialValues}
            validationSchema={Yup.object({
              email: Yup.string()
                .email(`${t("validation.email")}`)
                .required(`${t("validation.required")}`),
              password: Yup.string()
                .required(`${t("validation.password")}`),
            })}
            onSubmit={handleSubmit}
          >
            {({handleSubmit, values, handleChange}) => (
                <form className="login-form" onSubmit={handleSubmit}>
                  <div className="input-content">
                    <div className="input-label">
                      <span>{t("login.email")}</span>
                    </div>
                    <Field name="email">
                      {(props: FieldProps) => (
                        <InputForm 
                          type="text"
                          {...props}
                        />  
                      )}
                    </Field>
                    <div className="link-container">
                      <Link to={EMAIL_RECOVERY.route}>{t("login.forgottenEmailLink")}</Link>
                    </div>
                  </div>
                  <div className="input-content">
                    <div className="input-label">
                      <span>{t("login.password")}</span>
                    </div>
                    <div className="double-input">
                    <Field name="password">
                      {(props: FieldProps) => (
                        <InputForm 
                          type="password"
                          {...props}
                        />  
                      )}
                    </Field>
                    </div>
                    <div className="link-container">
                      <Link to={RESET_PASSWORD.route}>{t("login.forgottenPassLink")}</Link>
                    </div>
                  </div>
                  <div className="captcha-container">
                    {
                      // env !== 'test' && env !== 'development' &&
                      <ReCAPTCHA
                        sitekey={reCaptchaKey!}
                        ref={loginCaptchaRef}
                      />
                    }
                  </div>
                  <div className="button-container">
                    <Button 
                      type={loginLoading ? "button": "submit"}
                      text={
                        !loginLoading 
                        ? t("login.buttonText")
                        : `${t("global.loading")}...` 
                      }
                      fontSize={17}
                    />
                  </div>
                </form>
            )}
          </Formik>
        </div>
      </div>
    </S.Login>
  );
}

export default Login;
