import { call, put, takeEvery } from 'redux-saga/effects';
import pqrsServices from '../../services/pqrsService';
import { createPqrsFailure, createPqrsSuccess } from '../reducers/createPqrsSlice';

function* pqrsCreation(action: any): any {
  try {
    const createPqrsResponse = yield call(() => pqrsServices.createPqrs(action.payload));    
    yield put(createPqrsSuccess(createPqrsResponse));
  } catch (error) {
    yield put(createPqrsFailure(error));
  }
}

function* pqrsCreationSaga() {
  yield takeEvery('createPqrs/createPqrs', pqrsCreation);
}

export default pqrsCreationSaga;