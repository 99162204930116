import styled from "styled-components";

export const EmailCodeVerificationForm = styled.div`
  .response-message {
    margin: 19px 0;
    text-align: center;
    font-size: 16px;
    font-family: 'Poppins Bold';
    color: ${({theme}) => theme.darkBlue};
  }
  .email-validation-title {
    font-family: 'Poppins Bold';
    color: #022D61;
    margin-bottom: 30px;
    font-size: 16px;
    margin-left: 53px;
  }
  .email-validation-token-form {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    .inputs-container {
      justify-content: flex-start;
      align-items: center;
      display: flex;
      width: 100%; 
      flex-wrap: wrap;
      max-width: 610px;
      justify-content: center;
      .input-content {
        width: 500px;
        /* min-width: 275px; */
        display: flex;
        flex-direction: column;
        /* margin: 0 15px; */
        position: relative;
        .input-label {
          display: flex;
          font-size: 13px;
          padding-bottom: 1px;
          align-items: center;
          span {
            opacity: 0.7;
          }
          .tooltip-container {
            margin-left: 10px;
          }
        }
      }
      &--password {
        margin-top: 40px;
      }
      .inputs-flex {
        display: flex;
      }
    }
    .captcha-container {
      align-self: center;
      margin-bottom: 20px;
    }
    .list-container {
      font-size: 10px;
      margin-bottom: 20px;
    }
    .button-container{
      width: 200px;
      justify-content: center;
      display: flex;
      margin: auto;
    }
    .link-container {
      font-size: 12px;
      text-align: right;
      /* margin: 0px 0px 10px; */
      transform: translateY(-15px);
    }
  }

  @media only screen and (max-width: 980px) {
    .input-content {
      width: 260px !important;
    }
  }

`;