import { useEffect } from "react";
import ReactDOM from "react-dom";
interface  Props {
  children: 
  | JSX.Element
  | JSX.Element[]
  | string
  | string[];
}

function Portal({children}: Props) {
  const portalNode = document.createElement('div');

  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])  
  
  useEffect(() => {
    document.body.appendChild(portalNode);
    return () => {
      portalNode.remove();
    }
  }, [portalNode])
  
  return ReactDOM.createPortal(children, portalNode);
}

export default Portal;