
import { gql } from "@apollo/client";

export const QUERY_GET_SUB_CLASIFICACIONES = gql`
  query QUERY_GET_SUB_CLASIFICACIONES($categoriaId: Int!) {
    pmcsubcategoria(where: {version: {_eq: "3"}, categoriaid: {_eq: $categoriaId}}, order_by: {id: asc}) {
      id
      filtroCentroFacilitador
      subcategoria
      pmcclasificacion_a_centrofacilitador {
        tipoCentroFacilitador
        filtroCentroFacilitador
        pmccentrofacilitador {
          tipoCentro
          ubicacion
          id
        }
      }
    }
  }
`
