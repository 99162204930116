import styled from "styled-components";

export const Start = styled.div`
  display: flex;
  .container {
    display: flex;
    margin: auto;
    flex-direction: column;
    margin: 60px 0;
    width: 100%;
    .description {
      width: 51%;
      align-self: center;
      text-align: center;
    }
    .boxes-container {
      margin-top: 25px;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
    .box {
      width: 200px;
      box-shadow: 0px 0px 9px #00000012;
      border-radius: 20px;
      background-color: ${({theme}) => theme.white};
      margin: 20px;
      padding: 30px 40px;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
      &__icon {
        justify-content: center;
        font-size: 40px;
        color: ${({theme}) => theme.softBlue};
      }
      &__text {
        color: ${({theme}) => theme.blackText};
        font-size: 13px;
        margin-top: 5px;
      }
      &__text-big {
        color: ${({theme}) => theme.darkBlue};
        font-size: 30px;
        font-family: 'Poppins Bold';
        border-bottom: 2px solid #18A1D5;
        line-height: 32px;
        width: fit-content;
        display: flex;
        margin: auto;
      }
    }
  }
`;
