
import { gql } from "@apollo/client";

export const QUERY_GET_PMCCOMMUNITY = gql`
  query QUERY_GET_PMCCOMMUNITY {
    pmccomunidad {
      id
      nombreComunidad
    }
  }
`