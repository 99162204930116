import { useState } from 'react';

const BASE_URL = process.env.REACT_APP_ENDPOINT_CLOUD_FUNCTIONS;

export default function useUploadImage() {
  const urlEndpoint = `${BASE_URL}/uploadPqrsFiles`;
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const getExtensionFile = (filename) => {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined;
  }

  const uploadImage = (imageData) => {
    setLoading(true);
    // 15728640
    let ext = getExtensionFile(imageData.name);
    if (
        ext 
        && 
        (
          ext[0] === 'jpg' || 
          ext[0] === 'png' || 
          ext[0] === 'jpeg' || 
          ext[0] === 'pdf' ||
          ext[0] === 'doc' ||
          ext[0] === 'docx' ||
          ext[0] === 'JPG' || 
          ext[0] === 'PNG' || 
          ext[0] === 'JPEG' || 
          ext[0] === 'PDF' ||
          ext[0] === 'DOC' ||
          ext[0] === 'DOCX'
        )
      ) {
      return new Promise(function(resolve, reject) {
        var xhr = new XMLHttpRequest();
        var fd = new FormData();
        xhr.open('POST', urlEndpoint, true);
        xhr.onreadystatechange = () => {
          if(xhr.readyState === 4) {
            if(xhr.status === 200) {
              resolve(JSON.parse(xhr.responseText))
              setData(JSON.parse(xhr.responseText));
              setError(null);
              setLoading(false);
            } else {
              reject(xhr.status + ' Error al subir el archivo')
              setError(xhr.status + ' Error al subir el archivo');
              setData(null)
              setLoading(false);
            }
          }
        };
        fd.append('file', imageData);
        if (imageData) {
          console.log(xhr);
          xhr.send(imageData ? fd : null);
        } else {
          resolve(null);
        }
      });
    } else {
      setLoading(false);
      setError('Error de formato de archivo');
    }
  }
  
  return {
    uploadImage,
    data,
    loading,
    error,
  }
}