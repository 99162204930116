export const setToken = (token: string) => {
  localStorage.setItem('accessToken', token);
}

export const getToken = () => {
  let token = localStorage.getItem('accessToken');
  if (token) return token;
  else return '';
}

export const deleteToken = () => {
  localStorage.removeItem('accessToken');
}

const sharedServices = {
  setToken, 
  getToken,
  deleteToken
}

export default sharedServices;