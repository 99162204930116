import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import * as S from "./EmailVerified.style";

// GraphQL
import { useMutation } from "@apollo/client";
import { MUTATION_VERIFY_EMAIL } from "../../../graphql/mutations/Auth"; 
import CustomModal from "../../shared/modals/customModal";

function EmailVerified() {
  const navigate = useNavigate();
  const location = useLocation();
  const [ resMessage, setResMessage ] = useState<string>('');
  const [validateEmail, { error: validateEmailError , loading: validateEmailLoading }] = useMutation(MUTATION_VERIFY_EMAIL, { errorPolicy: 'all' });

  useEffect(() => {
    const searchLocation = location.search;
    console.log(searchLocation);
    
    const params = new URLSearchParams(searchLocation);
    if (!params.get("token") || !params.get("email")) {
      navigate('/not-found');
    }

    const emailValidation = async () => {
      try {
        const data: any = await validateEmail({
          variables: {
            email: params.get("email"),
            tokenVerificacion: params.get("token")
          },
        });
        if(data.errors) {
          setResMessage(data.errors[0].message);
        } else {
          setResMessage(data.data.validarEmail.message);
        }
      } catch (err: any) {
        console.log(err);
        
      }
    }

    emailValidation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <S.EmailVerified>
      <div className="content-email-verified">

        <CustomModal
          title={
            validateEmailLoading 
            ? "Cargando" 
            : (validateEmailError) 
              ? "Error"
              : "Validación Correcta"
          }
          description={
            validateEmailLoading 
            ? "" 
            : resMessage
          }
          type={
            validateEmailLoading 
            ? "loading" 
            : (validateEmailError) 
              ? "failed"
              : "success"
            }
          actionText={"Ir al inicio"}
          action={() => navigate('/')}
        />
      </div>
    </S.EmailVerified>
  );
}

export default EmailVerified;
