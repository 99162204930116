import { gql } from "@apollo/client";

export const MUTATION_UPDATE_PROFILE = gql`
  mutation MUTATION_UPDATE_PROFILE(
    $id: Int!, 
    $dataUpdated: pmccontacto_set_input!
  ) {
    update_pmccontacto_by_pk(
      pk_columns: {
        id: $id
      }, 
      _set: $dataUpdated
    ) {
      celular
      ciudadResidenciaId
      ciudadnacimientoid
      direccion
      discapacidad
      numerodni
      sexo
      telefono
    }
  }      
`;