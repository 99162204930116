import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';
import { LoginData } from "../../models/dbModels/LoginData";

export interface LoginState {
  isLoading: Boolean,
  loginData?: LoginData,
}

const initialState: LoginState = {
  isLoading: false,
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login: (state, action: any) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action: PayloadAction<LoginData>) => {
      state.isLoading = false;
      state.loginData = action.payload;
    },
    loginFailure: (state) => {
      state.isLoading = false;
    }
  }
})

export const { login, loginSuccess, loginFailure } = loginSlice.actions;

export default loginSlice.reducer;