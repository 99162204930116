import styled, { keyframes } from "styled-components";

const opacityTransition = () => keyframes`
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const positionTransition = () => keyframes`
  0% {
    transform: translateY(55px);
  }
  50% {
    transform: translateY(55px);
  }
  100% {
    transform: translateY(0);
  }
`;

const iconRotationFailed = () => keyframes`
  0% {
    transform: rotate(45deg) translateX(57px);
  }
  50% {
    transform: rotate(45deg) translateX(57px);
  }
  62.5% {
    transform: rotate(33.75deg) translateX(22px);
  }
  68.75% {
    transform: rotate(28deg) translateX(12px);
  }
  75% {
    transform: rotate(22.5deg) translateX(5px);
  }
  100% {
    transform: rotate(0deg) translateX(0px);
  }
`;

const iconRotationLoading = () => keyframes`
  0% {
    transform: rotate(45deg) translateX(57px);
  }
  50% {
    transform: rotate(45deg) translateX(57px);
  }
  62.5% {
    transform: rotate(33.75deg) translateX(22px);
  }
  68.75% {
    transform: rotate(28deg) translateX(12px);
  }
  75% {
    transform: rotate(22.5deg) translateX(5px);
  }
  100% {
    transform: rotate(0deg) translateX(0px);
  }
`;

const iconRotationSuccess = () => keyframes`
  0% {
    transform: rotateY(180deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`;

const iconRotationAlert = () => keyframes`
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
`;

const iconRotationLoop = () => keyframes`
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

interface Props {
  modalType: "loading" | "failed" | "success" | "alert"
}

export const CustomModal = styled.div<Props>`
  .custom-modal-box {
    max-height: 80vh;
    width: 500px;
    overflow-y: auto;
    &__img-icon {      
      justify-content: center;
      font-size: 110px;
      animation: ${opacityTransition()} 1s linear, ${({modalType}) =>  modalType !== 'loading' && positionTransition()} 3s ease;
    }
    &__icon-container {
      &--success {
        color: #00ac5f;
        animation: ${iconRotationSuccess()} 3s ease;
      }
      &--failed {
        color: #ed1c24;
        animation: ${iconRotationFailed()} 3s linear;
      }
      &--loading {
        color: #0071bc;
        animation: ${iconRotationFailed()} 3s linear, ${iconRotationLoop} 2s infinite linear;
      }
      &--alert {
        color: #E2BD0D;
        animation: ${iconRotationAlert()} 3s ease-out;
      }
    }
    &__text {
      font-size: ${({modalType}) => modalType === "alert" ? "25px" : "47px"};
      font-family: "Poppins Bold";
      color: ${({theme}) => theme.darkBlue};
      margin: 10px 0;
      animation: ${opacityTransition()} 1s linear, ${({modalType}) =>  modalType !== 'loading' && positionTransition()} 3s ease;
      /* animation: ${positionTransition()} 1.5s linear; */
      line-height: 42px;
    }
    &__description {
      font-size: ${({modalType}) => modalType === "alert" ? "13px" : "23px"};
      font-family: "Poppins Bold";
      color: ${({theme}) => theme.darkBlue};
      line-height: 25px;
      margin: 10px 0;
      animation: ${opacityTransition()} 3s linear;
      white-space: pre-wrap;
      word-break: break-word;
      
      /* min-height: 20px; */
    }
    &__buttons {
      display: flex;
    }
    &__button {
      height: ${({modalType}) => modalType === "alert" ? "35px" : "50px"};
      margin: 20px auto 0;
      animation: ${opacityTransition()} 1s linear;
      width: ${({modalType}) => modalType === "alert" ? "150px" : "300px"};
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media only screen and (max-width: 550px) {
    .custom-modal-box {
      width: 280px;
      &__text {
        font-size: ${({modalType}) => modalType === "alert" ? "20px" : "40"};
      }
      &__description {
        font-size: ${({modalType}) => modalType === "alert" ? "13px" : "20px"};
      }
      &__button {
        width: 200px;
      }
      &__buttons {
        flex-direction: column;
      }
    }
  }
  
`;
