import { LoginData } from '../models/dbModels/LoginData';
import httpService from './httpService';

export async function login(data: LoginData) {
  let url = `/users/login`
  return httpService.httpPost(url, data);
}

const authServices = {
  login
}

export default authServices;
