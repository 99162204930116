import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./Start.style";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/constants";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";

function Start() {
  const navigate = useNavigate();
  const { CONSULT_PQRS, SESSION_OPTIONS, CREATE_PQRS } = ROUTES;
  const [t] = useTranslation("global");
  const { isAuth } = useSelector((state: RootState) => state.auth);

  useEffect(() => {    
  }, []);

  return (
    <S.Start>
      <div className="container">
        <div className="description">
          {t("start.description.text1")}
          <strong> {t("start.description.text2")} </strong> 
          {t("start.description.text3")}
          <strong> {t("start.description.text4")} </strong> 
          {t("start.description.text5")}
        </div>
        <div className="boxes-container">
          <div className="box" onClick={() => {window.open('https://www.migracioncolombiac3.gov.co/tengo-una-pregunta')}}>
            <i className="box__icon icon icon-lo-que-quieres-saber"/>
            <div className="box__text">{t("start.boxes.box1.title")}</div>
            <div className="box__text-big">{t("start.boxes.box1.subtitle")}</div>
          </div>
          <div className="box" onClick={() => isAuth ? navigate(CREATE_PQRS.route) : navigate(SESSION_OPTIONS.route)}>
            <i className="box__icon icon icon-radicar-pqrs"/>
            <div className="box__text">{t("start.boxes.box2.title")}</div>
            <div className="box__text-big">{t("start.boxes.box2.subtitle")}</div>
          </div>
          <div className="box" onClick={() => navigate(CONSULT_PQRS.route)}>
            <i className="box__icon icon icon-consultar-estado-pqrs"/>
            <div className="box__text">{t("start.boxes.box3.title")}</div>
            <div className="box__text-big">{t("start.boxes.box3.subtitle")}</div>
          </div>
        </div>
      </div>
    </S.Start>
  );
}

export default Start;
