/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useContext, useEffect, useState, useRef, useMemo } from "react";
import { Field, FieldProps, Formik, FormikHelpers, useFormikContext } from "formik";
import { ThemeContext } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as S from "./CreatePQRS.style";
import * as Yup from "yup";

// Types
import { ObjectShape } from "yup/lib/object";
import { Pais } from "../../../models/dbModels/Pais";
import { Ciudad } from "../../../models/dbModels/Ciudad";
import { Archivo } from "../../../models/dbModels/Archivo";
import { Categoria } from "../../../models/dbModels/Categoria";
import { Departamento } from "../../../models/dbModels/Departamento";
import { TipoPeticion } from "../../../models/dbModels/TipoPeticion";
import { Classification } from "../../../models/dbModels/Classification";

import { SelectItem } from "../../../models/logicModels/SelectItem";
import { PqrsCreation } from "../../../models/logicModels/PqrsCreation";
import { DocumentFile } from "../../../models/logicModels/DocumentFile";

// Utils
import * as P from "../../../utils/pipes";
import { CONDICION_DISCAPACIDAD, CONDITIONS, INTEREST_GROUP, ROUTES, SEX_TYPE, SUB_INTEREST_GROUP, TYPE_DOCUMENTS } from "../../../utils/constants";

// Apollo
import { useQuery } from "@apollo/client";
import { QUERY_GET_PAISES } from "../../../graphql/queries/Pais";
import { QUERY_GET_CATEGORIAS } from "../../../graphql/queries/Categoria";
import { QUERY_GET_DEPARTAMENTOS } from "../../../graphql/queries/Department";
import { QUERY_GET_TIPO_DE_PETICIONES } from "../../../graphql/queries/TipoPeticion";
import { QUERY_GET_SUB_CLASIFICACIONES } from "../../../graphql/queries/Classification";
import { QUERY_GET_CIUDADES_BY_DEPARTAMENTO, QUERY_GET_CIUDADES_BY_PAIS } from "../../../graphql/queries/Ciudad";

// Components
import Button from "../../shared/button";
import Tooltip from "../../shared/tooltip";
import InputForm from "../../shared/inputForm";
import SelectForm from "../../shared/selectForm";
import ModalContainer from "../../shared/modals/modalContainer";
import LoadingModal from "../../shared/modals/loadingModal";
import Portal from "../../shared/modals/portal";
import CustomModal from "../../shared/modals/customModal";

//Hooks
import useUserData from "../../../hooks/useUserData";
import useUploadImage from "../../../hooks/useUploadImage";

// Redux
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { createPqrs, createPqrsReset } from "../../../redux/reducers/createPqrsSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { CommunityType } from "../../../models/dbModels/CommunityType";
import { QUERY_GET_PMCCOMMUNITY } from "../../../graphql/queries/CommunityType";


const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
// const env = process.env.REACT_APP_ENV;

interface  CreatePqrsFields {
  subInterestGroup: string,
  interestGroup: string,
  name1: string,
  lastname1: string,
  name2: string,
  lastname2: string,
  typeDocument: string | number,
  document: string,
  birthdate: string,
  email: string,
  sex: string,
  localPhone: string,
  phone: string,
  address: string,
  conditionsQuestion: string,
  country: string,
  city?: number,
  department: string,
  country2: string,
  city2?: number,
  department2: string,
  requestType: string,
  category: string,
  classification: string,
  subCategory: string,
  filtroCentroFacilitador?: number,
  filtroTramiteYServicio?: number,
  comment: string,
  habeasData: boolean,
  razonSocial: string,
  emailAnonimo: string,
  condicionDiscapacidad: string,
  comunidadId: string
}

interface Props {
  anonymous?: boolean
}

function CreatePQRS({ 
  anonymous
}: Props) {  
  const {t} = useTranslation("global");
  const navigate = useNavigate();
  const { START } = ROUTES;
  const [tabSelected, setTabSelected] = useState<number>(0);
  const theme = useContext(ThemeContext);
  const [formValidations, setFormValidations] = useState<ObjectShape[]>([]);
  const [documentsUploaded, setDocumentsUploaded] = useState<DocumentFile[]>([]);
  const [initialAlertActive, setInitialAlertActive] = useState<boolean>(false)
  const [resMessage, setResMessage] = useState<string>('');
  const [errorCaptcha, setErrorCaptcha] = useState<boolean>(false);
  const createPqrsCaptchaRef = useRef<any>(null);

  const [creationSuccess, setCreationSuccess] = useState(false);

  const { userData } = useUserData();

  useEffect(() => {
    if(anonymous) {
      setInitialAlertActive(true);
      setTabSelected(2);
    } else {
      setTabSelected(1);
    }
  }, [anonymous])

  const responseInitialAlert = (res: boolean) => {
    if(res) {
      setInitialAlertActive(false);
    } else {
      navigate(START.route);
    }
  }
  
  // const [createPqrs, { loading: createPqrsLoading}] = useMutation(MUTATION_REGISTER);   

  const {createPqrsData, isLoading, createPqrsError} = useSelector((state: RootState) => state.createPqrs);
  const dispatch = useDispatch();     

  // useEffect(() => {
  //   if(isLoading) {
  //     window.scrollTo(0, 0)
  //     document.body.style.overflow = "hidden"
  //   } else {
  //     document.body.style.overflow = "auto"
  //   }
  // }, [isLoading])  

  useEffect(() => {
    if(createPqrsData) {
      setCreationSuccess(true);
      setDocumentsUploaded([]);  
    } 
  }, [createPqrsData])

  interface TabStep {
    id: number,
    tabName: string
  }  

  const tabs: TabStep[] = 
  anonymous 
  ? [
    {
      id: 2,
      tabName: t("createPqrs.requestData")
    },
    {
      id: 3,
      tabName: t("createPqrs.pqrsConfirmation")
    }
  ]
  : [
    {
      id: 1,
      tabName: t("createPqrs.personalData")
    },
    {
      id: 2,
      tabName: t("createPqrs.requestData")
    },
    {
      id: 3,
      tabName: t("createPqrs.pqrsConfirmation")
    }
  ]

  const initialFormValidations: ObjectShape[] = [
    {
      interestGroup: Yup.string()
        .required(t("validation.required")),
      name1: Yup.string()
        .min(3, t("validation.min"))
        .max(15, t("validation.max"))
        .required(t("validation.required"))
        .matches(/^[ÑáéíóúÁÉÍÓÚñA-Za-z _]*[ÑáéíóúÁÉÍÓÚñA-Za-z][ÑáéíóúÁÉÍÓÚñA-Za-z _]*$/, t("validation.onlyLetter")),
      lastname1: Yup.string()
        .min(3, t("validation.min"))
        .max(30, t("validation.max"))
        .required(t("validation.required"))
        .matches(/^[ÑáéíóúÁÉÍÓÚñA-Za-z _]*[ÑáéíóúÁÉÍÓÚñA-Za-z][ÑáéíóúÁÉÍÓÚñA-Za-z _]*$/, t("validation.onlyLetter")),
      name2: Yup.string()
        .min(3, t("validation.min"))
        .max(30, t("validation.max"))
        .matches(/^[ÑáéíóúÁÉÍÓÚñA-Za-z _]*[ÑáéíóúÁÉÍÓÚñA-Za-z][ÑáéíóúÁÉÍÓÚñA-Za-z _]*$/, t("validation.onlyLetter")),
      lastname2: Yup.string()
        .min(3, t("validation.min"))
        .max(15, t("validation.max"))
        .matches(/^[ÑáéíóúÁÉÍÓÚñA-Za-z _]*[ÑáéíóúÁÉÍÓÚñA-Za-z][ÑáéíóúÁÉÍÓÚñA-Za-z _]*$/, t("validation.onlyLetter")),
      typeDocument: Yup.string()
        .required(t("validation.required")),
      document: Yup.string()
        .required(t("validation.required")),
      birthdate: Yup.string()
        .required(t("validation.required")),
      email: Yup.string()
        .email(t("validation.email"))
        .matches(
          /^[\w-ñ.]+@([\w-ñ]+\.)+[\w-]{2,4}$/g,
          t("validation.noAccents")
        )
        .required(t("validation.required")),
      sex: Yup.string()
        .required(t("validation.required")),
      localPhone: Yup.string()
        .max(13, t("validation.13"))
        .required(t("validation.required")),
      phone: Yup.string()
        .required(t("validation.required")),
      address: Yup.string()
        .required(t("validation.required")),
      conditionsQuestion: Yup.string()
        .required(t("validation.required")),
      country: Yup.string()
        .required(t("validation.required")),
      city: Yup.string()
        .required(t("validation.required")),
      country2: Yup.string()
        .required(t("validation.required")),
      city2: Yup.string()
        .required(t("validation.required")),
      razonSocial: Yup.string()
        .min(3, t("validation.min"))
        .max(30, t("validation.max"))
        .matches(/^[ÑáéíóúÁÉÍÓÚñA-Za-z _]*[ÑáéíóúÁÉÍÓÚñA-Za-z][ÑáéíóúÁÉÍÓÚñA-Za-z _]*$/, t("validation.onlyLetter")),
    },
    {
      requestType: Yup.string()
      .required(t("validation.required")),
      category: Yup.string()
        .required(t("validation.required")),
      comment: Yup.string()
        .max(1000, t("validation.max"))
        .required(t("validation.required")),
      habeasData: Yup.string()
        .required(t("validation.required"))
        .test((value) => value === "true"),
    }
  ]  

  useEffect(() => {
    setFormValidations(initialFormValidations);
  }, [])

  const handleSubmit = (values: CreatePqrsFields, formikHelpers: FormikHelpers<CreatePqrsFields>) => {
    if(tabSelected !== 3) {
      setTabSelected(tabSelected + 1)
    } else {
      // formikHelpers.resetForm()
      if (createPqrsCaptchaRef.current.getValue()) {
        createPqrsFunction(values, formikHelpers);
      } else {
        setErrorCaptcha(true);
        setResMessage("Error de Captcha");
        createPqrsCaptchaRef.current.reset();
      }
    }
  }

  const createPqrsFunction = async (values: CreatePqrsFields, formikHelpers: FormikHelpers<CreatePqrsFields>, captchaToken?: any) => {

    let newPqrs: PqrsCreation;

    newPqrs = {
      categoriaId: Number(values.category),
      subCategoriaId: values.classification ? Number(values.classification) : null,
      peticion: values.requestType.trim(),
      comentario: values.comment.trim(),
      grupoInteres: anonymous ? "Anónimo" : values.interestGroup.trim(),
      contactoId: anonymous ? null : Number(userData?.id),
      discapacidad: values.conditionsQuestion ? values.conditionsQuestion : null,
      condicionDiscapacidad: values.condicionDiscapacidad ? values.condicionDiscapacidad : null,
      comunidadId: values.comunidadId ? values.comunidadId : null,
      captchaToken: createPqrsCaptchaRef.current.getValue(),
      archivos: documentsUploaded.map(document => {
        const file: Archivo = {
          file: document.file,
          name: document.name
        }
        return file
      })
    }   
    
    if(anonymous) {
      newPqrs.esAnonima = true;
      newPqrs.emailAnonimo = values.emailAnonimo;
    }
    
    if(values.filtroTramiteYServicio) newPqrs.tramiteYServicioId = values.filtroTramiteYServicio;
    if(values.subCategory) newPqrs.centroFacilitadorId = Number(values.subCategory);
    
    if(values.interestGroup !== "En nombre propio (Persona natural)" && !anonymous) {
      newPqrs.nuevoUsuario =  {
        primerNombre: P.onlyFirstCapital(values.name1).trim(),
        segundoNombre: P.onlyFirstCapital(values.name2).trim(),
        primerApellido: P.onlyFirstCapital(values.lastname1).trim(),
        segundoApellido: P.onlyFirstCapital(values.lastname2).trim(),
        tipoDni: values.typeDocument.toString().trim(),
        numerodni: values.document.toString().toLowerCase().trim(),
        fechaNacimiento: `${new Date(values.birthdate).getFullYear()}-${new Date(values.birthdate).getMonth()}-${new Date(values.birthdate).getDate()}`.trim(),
        email: values.email.toLowerCase().trim(),
        sexo: values.sex.trim(),
        telefono: values.localPhone.toString().trim(),
        celular: values.phone.toString().trim(),
        direccion: values.address.trim(),
        ciudadnacimientoid: Number(values.city!),
        ciudadResidenciaId: Number(values.city2!),
        discapacidad: values.conditionsQuestion ? values.conditionsQuestion : null,
        condicionDiscapacidad: values.condicionDiscapacidad ? values.condicionDiscapacidad : null,
        comunidadId: values.comunidadId ? values.comunidadId : null
      }
    }
    
    if (
      values.subInterestGroup === "Empresa privada" &&
      values.interestGroup === "En representación de (Persona Jurídica)" &&
      values.typeDocument === "NIT"
    ) newPqrs.nuevoUsuario!.razonSocial = values.razonSocial;

    dispatch(createPqrs({input: newPqrs}));
  }

  const createPqrsFormInitialValues: CreatePqrsFields = {
    subInterestGroup: '',
    interestGroup: '',
    name1: '',
    lastname1: '',
    name2: '',
    lastname2: '',
    typeDocument: '',
    document: '',
    birthdate: '',
    email: '',
    sex: '',
    localPhone: '',
    phone: '',
    address: '',
    conditionsQuestion: '',
    country: '',
    department: '',
    country2: '',
    department2: '',
    requestType: '',
    category: '',
    classification: '',
    subCategory: '',
    comment: '',
    habeasData: false,
    razonSocial: '',
    emailAnonimo: '',
    condicionDiscapacidad: '',
    comunidadId: ''
  }

  const cleanErrors = () => {
    setErrorCaptcha(false);
    setResMessage('');
    dispatch(createPqrsReset())
  }
  
  return (
    <S.CreatePQRS>
      {
        initialAlertActive && 
        <Portal>
          <ModalContainer>
            <CustomModal
              title={"Señor/a ciudadano/a anónimo/a"}
              description={`<div style="text-align: justify;">Por favor tenga en cuenta que la petición, queja, reclamo, sugerencia, denuncia o felicitación, en la cual se desconozca la información sobre el destinatario o solicitante, se fijará la respuesta en la página Web de la Entidad en el siguiente link <a href="https://www.migracioncolombia.gov.co/atencion-al-ciudadano-2/pqrs-sin-datos-de-contacto" target="_blank" >https://www.migracioncolombia.gov.co/atencion-al-ciudadano-2/pqrs-sin-datos-de-contacto</a> en virtud de lo dispuesto por el artículo 69 de la Ley 1437 de 2011.
             <br>Recuerde que, al radicar de manera anónima, usted acepta las condiciones conforme a lo establecido en el artículo 38 de la Ley 190 de 1995; artículo 69; de la Ley 734 de 2002 y artículo 81 de la Ley 962 de 2005. 
              <br>En caso que usted realice una solicitud en donde se requiera de alguna validación en las bases de datos relacionadas con trámites y servicios de nuestra entidad, deberá suministrar información completa, por lo que le sugerimos ingresar su petición, queja, reclamo, sugerencia, denuncia o felicitación seleccionando la opción Centro de Consulta Ciudadana- c3, radicar PQRS y dando clic en el botón de usuario nuevo o el botón de usuario registrado según corresponda, allí diligencie todos los campos solicitados y adjunte toda la información que considere pertinente para brindarle una respuesta satisfactoria.    </div>
¿Desea continuar?`}
              type="alert"
              action={() => responseInitialAlert(true)}
              actionText={"Sí"}
              action2={() => responseInitialAlert(false)}
              actionText2={"No"}
            /> 
          </ModalContainer>
        </Portal>
      }
      {
        (isLoading || createPqrsError || errorCaptcha) && 
        <Portal>
          <ModalContainer>
            {
              isLoading
              ? <LoadingModal
                title={"Sus datos se estan cargando"}
              />
              : <CustomModal
                title={"Error"}
                description={errorCaptcha ? resMessage : createPqrsError.message}
                type="failed"
                action={cleanErrors}
              /> 
            }
          </ModalContainer>
        </Portal>
      }
      <div className="create-pqrs">
        <div className="create-pqrs__left">
          <div className="box-title">
            <div className="box-title__text">{t("createPqrs.pretitle")}</div>
            <div className="box-title__text-big">{t("createPqrs.title")}</div>
            <div className="box-title__description">
              {t("createPqrs.description")}
              <strong className="box-title__description-color">
                {' ' + t("createPqrs.descriptionColor")}
              </strong>
            </div>
          </div>
          <div className="tab-steps">
            {
              tabs.map((tab, index) => {
                return (
                  <div key={tab.id} style={index === 2 ? {flexDirection: "row-reverse"} : {}} className={tab.id === tabSelected ? "box-tab box-tab--selected" : "box-tab"}>
                    <div className={tab.id === tabSelected ? "box-tab__text--selected" : "box-tab__text"}>
                      {index === 2 && tab.tabName}
                      <div className="box-tab__number">{index+1}</div>
                      {index === 2 ?  '' : tab.tabName}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="create-pqrs__right">
          {
            !creationSuccess
            ? <>
              <div className="title-form">
                {tabs.find(tab => tab.id === tabSelected)?.tabName.toUpperCase()}
              </div>
              <Formik
                initialValues={createPqrsFormInitialValues}
                enableReinitialize={true}
                validationSchema={Yup.object(formValidations[tabSelected - 1])}
                onSubmit={handleSubmit}
              >
                {(props) => (
                  <form className="create-pqrs-form" onSubmit={ props.handleSubmit }>
                    {
                      tabSelected === 1
                      ? <FirstViewForm
                          formValidations={formValidations}
                          setFormValidations={setFormValidations}
                          initialFormValidations={initialFormValidations}
                        />
                      : tabSelected === 2 
                        ? <SecondViewForm 
                          documentsUploaded={documentsUploaded}
                          setDocumentsUploaded={setDocumentsUploaded}
                          initialFormValidations={initialFormValidations}
                          formValidations={formValidations}
                          setFormValidations={setFormValidations}
                          anonymous={anonymous}
                        />
                        : <ThirdViewForm 
                          documentsUploaded={documentsUploaded}
                          setDocumentsUploaded={setDocumentsUploaded}
                          anonymous={anonymous}
                        />
                    }
                    {
                      tabSelected === 3 &&
                      <div className="captcha-container">
                        {
                          // env !== 'test' && env !== 'development' &&
                          <ReCAPTCHA
                            sitekey={reCaptchaKey!}
                            ref={createPqrsCaptchaRef}
                          />
                        }
                      </div>
                    }
                    <div className="buttons-container">
                      {
                        tabSelected === 2 && anonymous 
                        ? null
                        : tabSelected > 1 
                          ? <div className="button-container" onClick={() => setTabSelected(tabSelected - 1)}>
                            <Button 
                              type="button"
                              fontSize={16}
                              color={theme.white}
                              colorText={theme.softBlue}
                              text={'ATRÁS'}
                            />
                          </div>
                          : null
                      }         
                      {
                        <div className="button-container">
                          <Button 
                            type="submit"
                            fontSize={16}
                            text={
                              tabSelected !== 3 
                              ? "SIGUIENTE" 
                              : true
                                ? "CONFIRMAR"
                                : `${t("global.loading")}...`
                            }
                          />
                        </div>
                      }           
                    </div>
                  </form>
                )}
              </Formik>
            </>
            : <SuccessViewForm 
              setCreationSuccess={setCreationSuccess}
              createPqrsData={createPqrsData}
              setTabSelected={setTabSelected}
              anonymous={anonymous}
            />
          }
        </div>
      </div>
    </S.CreatePQRS>
  );
}


interface PropsFirstViewForm {
  formValidations?: ObjectShape[],
  setFormValidations?: Dispatch<SetStateAction<ObjectShape[]>>,
  initialFormValidations?: ObjectShape[],
  allDisabled?: boolean
}

function FirstViewForm({ 
  formValidations,
  setFormValidations,
  initialFormValidations,
  allDisabled
}: Readonly<PropsFirstViewForm>) {

  const {t} = useTranslation("global");

  const { values } = useFormikContext<CreatePqrsFields>();
  const { setFieldValue } = useFormikContext();

  const { userData } = useUserData();

  const isSubInterestGroup_privateCompany = useMemo(() => {
    return values.subInterestGroup === "Empresa privada" &&
    values.interestGroup === "En representación de (Persona Jurídica)" &&
    values.typeDocument === "NIT"
  }, [values.interestGroup, values.subInterestGroup, values.typeDocument]);

  const isOwnNameActive = useMemo(() => {
    return values.interestGroup === "En nombre propio (Persona natural)"
  }, [values.interestGroup]);

  const showSubInterestGroup = useMemo(() => {
    return values.interestGroup === "En representación de (Persona Jurídica)"
  }, [values.interestGroup]);

  const setSubInterestGroupValidation = () => {
    const subInterestGroupValidation: ObjectShape = {
      subInterestGroup: Yup.string()
        .required(t("validation.required"))
    }
    const firstViewValidation = initialFormValidations![0];
    const mergedValidations = {...firstViewValidation, ...subInterestGroupValidation};
    setFormValidations?.([mergedValidations, initialFormValidations![1]]);
  }

  useEffect(() => {
    if(!allDisabled) {
      setInterestGroup(true);
      if (showSubInterestGroup) setSubInterestGroupValidation();
      else setInitialValidation();
    }
  }, [values.interestGroup, showSubInterestGroup])

  const isCountryColombia = useMemo(() => {
    return values.country === "52"
  }, [values.country])

  const isCountry2Colombia = useMemo(() => {
    return values.country2 === "52"
  }, [values.country2])

  const {
    data: paisesdata,
  } = useQuery(
    QUERY_GET_PAISES,
  );

  const {
    data: departamentosdata,
  } = useQuery(
    QUERY_GET_DEPARTAMENTOS,
  );

  const {
    data: ciudadesdata,
  } = useQuery
  (
    isCountryColombia 
    ? QUERY_GET_CIUDADES_BY_DEPARTAMENTO 
    : QUERY_GET_CIUDADES_BY_PAIS,
    {
      variables: isCountryColombia 
      ? {
        departamentoId: values.department
      }
      : {
        paisId: values.country
      }
    }
  );

  const {
    data: ciudadesdata2,
  } = useQuery(
    isCountry2Colombia 
    ? QUERY_GET_CIUDADES_BY_DEPARTAMENTO 
    : QUERY_GET_CIUDADES_BY_PAIS,
    {
      variables: isCountry2Colombia 
      ? {
        departamentoId: values.department2
      }
      : {
        paisId: values.country2
      }
    }
  );

  const setInitialValidation = () => {
    setFormValidations?.(initialFormValidations!);
    if (showSubInterestGroup) setSubInterestGroupValidation();
  }

  const setDepartmentValidation = () => {
    const departmentValidation: ObjectShape = {
      department: Yup.string()
      .required(t("validation.required"))
    }
    const firstViewValidation = initialFormValidations![0];
    const mergedValidations = {...firstViewValidation, ...departmentValidation}
    setFormValidations?.([mergedValidations, initialFormValidations![1]]);
  }

  const setOwnNameValidation = () => {
    const ownNametValidation: ObjectShape = {
      interestGroup: Yup.string()
        .required(t("validation.required")),
    }     
    setFormValidations?.([ownNametValidation, initialFormValidations![1]]);
  }

  const setDepartment2Validation = () => {
    const department2Validation: ObjectShape = {
      department2: Yup.string()
      .required(t("validation.required"))
    }
    const firstViewValidation = formValidations![0];
    const mergedValidations = {...firstViewValidation, ...department2Validation}        
    setFormValidations?.([mergedValidations, formValidations![1]]);
  }

  const [interestGroupChanged, setInterestGroup] = useState(false);
  useEffect(() => {
    if(!allDisabled) {
      if (isOwnNameActive) {
        setOwnNameValidation();
        setFieldValue('name1', userData?.primerNombre)
        setFieldValue('lastname1', userData?.primerApellido)
        setFieldValue('name2', userData?.segundoNombre ? userData?.segundoNombre : "")
        setFieldValue('lastname2', userData?.segundoApellido ? userData?.segundoApellido : "")
        setFieldValue('typeDocument', userData?.tipoDni)
        setFieldValue('document', userData?.numerodni)
        setFieldValue('birthdate', userData?.fechaNacimiento ? userData?.fechaNacimiento : "")
        setFieldValue('email', userData?.email)
        setFieldValue('sex', userData?.sexo ? userData?.sexo : "")
        setFieldValue('localPhone', userData?.telefono ? userData?.telefono : "")
        setFieldValue('phone', userData?.celular ? userData?.celular : "")
        setFieldValue('address', userData?.direccion ? userData?.direccion : "")
        setFieldValue('conditionsQuestion', userData?.discapacidad ? userData?.discapacidad : "")
        setFieldValue('condicionDiscapacidad', userData?.condicionDiscapacidad ? userData?.condicionDiscapacidad : "")
        setFieldValue('comunidadId', userData?.comunidadId ? userData?.comunidadId : "")
        if(userData?.pmcciudad) {
          setFieldValue('country', userData?.pmcciudad.pmcpai ? userData?.pmcciudad.pmcpai.id.toString() : "")
          setFieldValue('department', userData?.pmcciudad.pmcdepartamento ? userData?.pmcciudad.pmcdepartamento.id.toString() : "")
          setFieldValue('city', userData?.ciudadnacimientoid ? userData?.ciudadnacimientoid.toString() : "")
        }
        if(userData?.pmcciudadByCiudadresidenciaid) {
          setFieldValue('country2', userData?.pmcciudadByCiudadresidenciaid.pmcpai ? userData?.pmcciudadByCiudadresidenciaid.pmcpai.id.toString() : "")
          setFieldValue('department2', userData?.pmcciudadByCiudadresidenciaid.pmcdepartamento ? userData?.pmcciudadByCiudadresidenciaid.pmcdepartamento.id.toString() : "")
          setFieldValue('city2', userData?.ciudadResidenciaId ? userData?.ciudadResidenciaId.toString() : "")
        }
      } else {
        setInitialValidation();
        if (interestGroupChanged) {
          setFieldValue('name1', "")
          setFieldValue('lastname1', "")
          setFieldValue('name2', "")
          setFieldValue('lastname2', "")
          setFieldValue('typeDocument', "")
          setFieldValue('document', "")
          setFieldValue('birthdate', "")
          setFieldValue('email', "")
          setFieldValue('sex', "")
          setFieldValue('localPhone', "")
          setFieldValue('phone', "")
          setFieldValue('address', "")
          setFieldValue('conditionsQuestion', "")
          setFieldValue('country', "")
          setFieldValue('city', "")
          setFieldValue('department', "")
          setFieldValue('country2', "")
          setFieldValue('city2', "")
          setFieldValue('department2', "")
          setFieldValue('razonSocial', "")
          setFieldValue('emailAnonimo', "")
          setFieldValue('condicionDiscapacidad', "")
          setFieldValue('comunidadId', "")
        }
      } 
    }
  }, [values.interestGroup])

  useEffect(() => {
    if(!allDisabled) {
      if(isOwnNameActive) {
        setOwnNameValidation();
      } else {
        if(isCountryColombia) {
          setDepartmentValidation();
        } else {
          setInitialValidation();
        }
      }
  
      if(!isCountryColombia) {
        setFieldValue('department', "")
      }
    }
  }, [values.country])

  useEffect(() => {
    if(!allDisabled) {
      if(isOwnNameActive) {
        setOwnNameValidation();
      } else {
        if(isCountry2Colombia) {
          setDepartment2Validation();
        } else {
          setInitialValidation();
        }
      }
  
      if(!isCountry2Colombia) {
        setFieldValue('department2', "")
      }    
    }
  }, [values.country2])

  useEffect(() => {
    if(isCountryColombia) {
      let isInList = departamentosdata?.pmcdepartamento?.find((departamento: Pais) => departamento.id.toString() === values.department.toString());

      if(!isInList) {
        setFieldValue('department', "")
        setFieldValue('city', "")
      }
    }
    if(isCountry2Colombia) {
      let isInList = departamentosdata?.pmcdepartamento?.find((departamento: Pais) => departamento.id.toString() === values.department2.toString());

      if(!isInList) {
        setFieldValue('department2', "")
        setFieldValue('city2', "")
      }
    }
  
  }, [departamentosdata])

  useEffect(() => {    
    let isInList =  ciudadesdata?.pmcciudad?.find((ciudad: Ciudad) => ciudad.id.toString() === values.city!.toString());
    
    if(!isInList) {
      setFieldValue('city', "")
    }
  
  }, [ciudadesdata])

  useEffect(() => {    
    let isInList =  ciudadesdata2?.pmcciudad?.find((ciudad: Ciudad) => ciudad.id.toString() === values.city2!.toString());
    
    if(!isInList) {
      setFieldValue('city2', "")
    }
  
  }, [ciudadesdata2])

  const { data: communityType } = useQuery(QUERY_GET_PMCCOMMUNITY);
  

  return (
    <div className="inputs-container inputs-container--first-step">
      <div className="inputs-flex">
        <div className="input-content">
          <div className="input-label">
            <span>
              {t("createPqrs.interestGroup")}
            </span>
            <div className="required-indicator">*</div>
          </div>
          <div className="double-input">
          <Field name="interestGroup">
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                items={INTEREST_GROUP}
                disabled={allDisabled}
              />  
            )}
          </Field>
          </div>
        </div>
        {
          isOwnNameActive &&
          <div className="input-content">
            <div className="double-input">
            </div>
          </div>
        }
        {
          showSubInterestGroup ?
          <div className="input-content">
            <div className="input-label">
              <span>
                {t("createPqrs.subInterestGroup")}
              </span>
              <div className="required-indicator">*</div>
            </div>
            <div className="double-input">
            <Field name="subInterestGroup">
              {(props: FieldProps) => (
                <SelectForm
                  {...props}
                  items={SUB_INTEREST_GROUP}
                  disabled={allDisabled}
                />  
              )}
            </Field>
            </div>
          </div>
          : <div className="input-content">
            <div className="double-input">
            </div>
          </div>
        }
        <div className="input-content">
          <div className="double-input">
          </div>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.name1")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.name1")}
            />
          </div>
        </div>
        <Field name="name1">
          {(props: FieldProps) => (
            <InputForm 
              type="text"
              {...props}
              disabled={allDisabled || isOwnNameActive} 
            />  
          )}
        </Field>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.name2")}
          </span>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.name2")}
            />
          </div>
        </div>
        <Field name="name2">
          {(props: FieldProps) => (
            <InputForm 
              type="text"
              {...props}
              disabled={allDisabled || isOwnNameActive}
            />  
          )}
        </Field>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.lastname1")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.lastname1")}
            />
          </div>
        </div>
        <Field name="lastname1">
          {(props: FieldProps) => (
            <InputForm 
              type="text"
              {...props}
              disabled={allDisabled || isOwnNameActive}
            />  
          )}
        </Field>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.lastname2")}
          </span>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.lastname2")}
            />
          </div>
        </div>
        <Field name="lastname2">
          {(props: FieldProps) => (
            <InputForm 
              type="text"
              {...props}
              disabled={allDisabled || isOwnNameActive}
            />  
          )}
        </Field>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.typeDocument")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
        <Field name="typeDocument">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              items={TYPE_DOCUMENTS}
              disabled={allDisabled || isOwnNameActive}
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.document")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.document")}
            />
          </div>
        </div>
        <div className="double-input">
        <Field name="document">
          {(props: FieldProps) => (
            <InputForm 
              type={values.typeDocument === "Pasaporte"  || values.typeDocument === "Documento extranjero"  ? "text" : "number"}
              {...props}
              disabled={allDisabled || isOwnNameActive}
            />  
          )}
        </Field>
        </div>
      </div>
      {
        isSubInterestGroup_privateCompany
        ? <div className="inputs-flex">
            <div className="input-content">
              <div className="input-label">
                <span>
                  {t("createPqrs.razonSocial")}
                </span>
                <div className="required-indicator">*</div>
                <div className="tooltip-container">
                  <Tooltip
                    text={t("createPqrs.indicators.razonSocial")}
                  />
                </div>
              </div>
              <Field name="razonSocial">
                {(props: FieldProps) => (
                  <InputForm 
                    type="text"
                    {...props}
                    disabled={allDisabled}
                  />  
                )}
              </Field>
            </div>
            <div className="input-content">
              <div className="double-input">
              </div>
            </div>
            <div className="input-content">
              <div className="double-input">
              </div>
            </div>
          </div>
        : null
      }
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.birthdate")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
        <Field name="birthdate">
          {(props: FieldProps) => (
            <InputForm 
              type="date"
              {...props}
              disabled={allDisabled || isOwnNameActive}
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.email")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.email")}
            />
          </div>
        </div>
        <div className="double-input">
        <Field name="email">
          {(props: FieldProps) => (
            <InputForm 
              type="text"
              {...props}
              disabled={allDisabled || isOwnNameActive}
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.sex")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
        <Field name="sex">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              items={SEX_TYPE}
              disabled={allDisabled || isOwnNameActive}
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.localPhone")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.localPhone")}
            />
          </div>
        </div>
        <div className="double-input">
        <Field name="localPhone">
          {(props: FieldProps) => (
            <InputForm 
              type="number"
              {...props}
              disabled={allDisabled || isOwnNameActive}
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.phone")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.phone")}
            />
          </div>
        </div>
        <div className="double-input">
        <Field name="phone">
          {(props: FieldProps) => (
            <InputForm 
              type="number"
              {...props}
              disabled={allDisabled || isOwnNameActive}
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.address")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.address")}
            />
          </div>
        </div>
        <div className="double-input">
        <Field name="address">
          {(props: FieldProps) => (
            <InputForm 
              type="text"
              {...props}
              disabled={allDisabled || isOwnNameActive}
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.conditionsQuestion")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <Field name="conditionsQuestion">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              items={CONDITIONS}
              disabled={allDisabled || isOwnNameActive}
            />  
          )}
        </Field>
      </div>
      {
        values.conditionsQuestion === 'Personas con Discapacidad' &&
        <div className="input-content">
          <div className="input-label">
            <span>
              {t("createPqrs.condicionDiscapacidad")}
            </span>
            <div className="required-indicator">*</div>
          </div>
          <Field name="condicionDiscapacidad">
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                items={CONDICION_DISCAPACIDAD}
                disabled={allDisabled || isOwnNameActive}
              />
            )}
          </Field>
        </div>
      }
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.communityType")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
          <Field name="comunidadId">
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                items={
                  communityType?.pmccomunidad.map((comunity:CommunityType ) => {
                    const data: SelectItem = {
                      value: comunity.id,
                      text: comunity.nombreComunidad
                    }
                    return (data);
                  })
                }
                disabled={allDisabled || isOwnNameActive}
              />
            )}
          </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.country")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
        <Field name="country">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              items={
                paisesdata?.pmcpais.map((pais: Pais) => {
                  const paisItem: SelectItem = {
                    value: pais.id.toString(),
                    text: pais.pais
                  }
                  return(paisItem);
                })
              }
              disabled={allDisabled || isOwnNameActive}
            />  
          )}
        </Field>
        </div>
      </div>
      {
        isCountryColombia &&
        <div className="input-content">
          <div className="input-label">
            <span>
              {t("createPqrs.department")}
            </span>
            <div className="required-indicator">*</div>
          </div>
          <div className="double-input">
          <Field name="department">
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                items={
                  departamentosdata?.pmcdepartamento.map((departamento: Departamento) => {
                    const departamentoItem: SelectItem = {
                      value: departamento.id.toString(),
                      text: departamento.departamento
                    }
                    return(departamentoItem);
                  })
                }
              disabled={allDisabled || isOwnNameActive}
            />  
            )}
          </Field>
          </div>
        </div>
      }
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.city")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
        <Field name="city">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              disabled={
                allDisabled || 
                (
                  isOwnNameActive
                  ? true 
                  : (isCountryColombia && values.department === "") || values.country === ""
                )
              }
              items={
                ciudadesdata?.pmcciudad.map((ciudad: Ciudad) => {
                  const ciudadItem: SelectItem = {
                    value: ciudad.id.toString(),
                    text: ciudad.ciudad
                  }
                  return(ciudadItem);
                })
              }
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.country2")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
        <Field name="country2">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              items={
                paisesdata?.pmcpais.map((pais: Pais) => {
                  const paisItem: SelectItem = {
                    value: pais.id.toString(),
                    text: pais.pais
                  }
                  return(paisItem);
                })
              }
              disabled={allDisabled || isOwnNameActive}
            />  
          )}
        </Field>
        </div>
      </div>
      {
        isCountry2Colombia &&
        <div className="input-content">
          <div className="input-label">
            <span>
              {t("createPqrs.department2")}
            </span>
            <div className="required-indicator">*</div>
          </div>
          <div className="double-input">
          <Field name="department2">
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                items={
                  departamentosdata?.pmcdepartamento.map((departamento: Departamento) => {
                    const departamentoItem: SelectItem = {
                      value: departamento.id.toString(),
                      text: departamento.departamento
                    }
                    return(departamentoItem);
                  })
                }
                disabled={allDisabled || isOwnNameActive}
              />  
            )}
          </Field>
          </div>
        </div>
      }
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.city2")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
        <Field name="city2">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              disabled={
                allDisabled || isOwnNameActive
                || (
                    ( 
                    isCountry2Colombia 
                    && values.department2 === ""
                    ) 
                    || values.country2 === ""
                )
              }
              items={
                ciudadesdata2?.pmcciudad.map((ciudad: Ciudad) => {
                  const ciudadItem: SelectItem = {
                    value: ciudad.id.toString(),
                    text: ciudad.ciudad
                  }
                  return(ciudadItem);
                })
              }
            />  
          )}
        </Field>
        </div>
      </div>
    </div>
  )
}

interface PropsSecondViewForm {
  documentsUploaded?: DocumentFile[],
  setDocumentsUploaded?: Dispatch<SetStateAction<DocumentFile[]>>,
  initialFormValidations?: ObjectShape[],
  formValidations?: ObjectShape[],
  setFormValidations?: Dispatch<SetStateAction<ObjectShape[]>>,
  allDisabled?: boolean,
  anonymous?: boolean
}

function SecondViewForm({
  documentsUploaded,
  setDocumentsUploaded,
  formValidations,
  initialFormValidations,
  setFormValidations,
  allDisabled,
  anonymous
}: PropsSecondViewForm) {
  const {t} = useTranslation("global");
  const initialRef: any = null;
  const hiddenFileInput = useRef(initialRef);
  const { values } = useFormikContext<CreatePqrsFields>();
  const { setFieldValue } = useFormikContext();
  const [requestTypeSelected, setRequestTypeSelected] = useState<TipoPeticion | null>(null);

  const { uploadImage, error } = useUploadImage();

  const notificationWayOptionsEnable = [
    {
      value: 1,
      text: 'Correo electrónico'
    }
  ];
  const notificationWayOptionsDisable = [
    {
      value: 2,
      text: 'Correo físico'
    }
  ];

  const {
    data: tipoPeticiondata,
  } = useQuery
  (
    QUERY_GET_TIPO_DE_PETICIONES,
  );

  const {
    data: categoriasdata,
  } = useQuery
  (
    QUERY_GET_CATEGORIAS,
    {
      variables: {
        tipoPeticionId: Number(tipoPeticiondata?.pmctipopeticion.find((tipo: any) => tipo.nombre === values.requestType)?.id)
      }
    }
  );

  const {
    data: classificationdata,
  } = useQuery
  (
    QUERY_GET_SUB_CLASIFICACIONES,
    {
      variables: {
        categoriaId: Number(values.category)
      }
    }
  );  

  const uploadImg = async (e: any) => {

    let documentNameTemp = e.target.files[0].name;

    documentNameTemp.replace(';', '');
    documentNameTemp.replace(':', '');
    documentNameTemp.replace('>', '');
    documentNameTemp.replace('<', '');
    documentNameTemp.replace('$', '');
    documentNameTemp.replace('/', '');
    documentNameTemp.replace('\\', '');
    documentNameTemp.replace('*', '');
    documentNameTemp.replace('%', '');

    let documentUploadedTemp: DocumentFile = {
      id: e.target.files[0].lastModified,
      name: documentNameTemp,
      activeLoader: true,
      documentInfo: e.target.files[0]
    }    

    if(e.target.files[0].size <= 15728640) {
      setDocumentsUploaded?.([documentUploadedTemp, ...documentsUploaded!]);    
  
      if(e.target.files[0]) {
        let fileRes: any;
        try {
          fileRes = await uploadImage(e.target.files[0]);
          
          const documentsArrayTemp: DocumentFile[] | undefined = documentsUploaded;        
  
          documentUploadedTemp.activeLoader = false;
          documentUploadedTemp.file = fileRes.file;
  
          setDocumentsUploaded?.([documentUploadedTemp, ...documentsArrayTemp!]);
        } catch (err) {
  
          console.log("error file", error);
          
          const documentsArrayTemp: DocumentFile[] | undefined = documentsUploaded;
          const indexItem = documentsArrayTemp?.findIndex((document) => document.id === e.target.files[0].lastModified);
          documentsArrayTemp?.splice(indexItem!, 1);
  
          setDocumentsUploaded?.([...documentsArrayTemp!]);
        }
      }
    }

  }

  const deleteDocument = (id?: string) => {
    if(id) {
      const documentsArrayTemp: DocumentFile[] | undefined = documentsUploaded;
      
      const indexItem = documentsArrayTemp?.findIndex((document) => document.id === id);
      documentsArrayTemp?.splice(indexItem!, 1);
  
      setDocumentsUploaded?.([...documentsArrayTemp!]);
    }
  }


  useEffect(() => {    
    let isInList = categoriasdata?.pmccategoria?.find((categoria: Categoria) => categoria.id.toString() === values.category.toString());
    
    if(!isInList) {
      setFieldValue('category', "");
      setFieldValue('classification', "");
      setFieldValue('subCategory', "");
      setFieldValue('filtroCentroFacilitador', "");
      setFieldValue('filtroTramiteYServicio', "");
    }
  
  }, [categoriasdata])

  useEffect(() => {
    let isInList = classificationdata?.pmcsubcategoria?.find((classification: Classification) => classification.id.toString() === values.classification.toString());

    if(!isInList) {
      setFieldValue('classification', "");
      setFieldValue('subCategory', "");
    }
    
  
  }, [classificationdata])

  const setInitialValidation = () => {
    setFormValidations?.(initialFormValidations!);
  }

  const setClassificationValidation = () => {
    const classificationValidation: ObjectShape = {
      classification: Yup.string()
      .required(t("validation.required")),
    }
    const secondViewValidation = initialFormValidations![1];
    const mergedValidations = {...secondViewValidation, ...classificationValidation}
    setFormValidations?.([initialFormValidations![0], mergedValidations]);
  }

  useEffect(() =>  {
    if(!allDisabled) {
      if(classificationdata?.pmcsubcategoria.length === 0) {
        setInitialValidation();
      } else {
        setClassificationValidation();
      }   
    }
  
  }, [classificationdata])

  const setEmailAnonymousValidation = () => {
    if (initialFormValidations) {
      const emailAnonymousValidation: ObjectShape = {
        emailAnonimo: Yup.string()
          .email(t("validation.email"))
          .matches(
            /^[\w-ñ.]+@([\w-ñ]+\.)+[\w-]{2,4}$/g,
            t("validation.noAccents")
          )
      }
      const secondViewValidation = initialFormValidations[1];
      const mergedValidations = {...secondViewValidation, ...emailAnonymousValidation}        
      setFormValidations?.([initialFormValidations[0], mergedValidations]);
    }
  }

  useEffect(() =>  {
    if (anonymous) setEmailAnonymousValidation();
  }, [anonymous])

  const subCategoryItems = useMemo(() => {
    const allRelations: any = [];

    const classificationSelected =
    classificationdata?.pmcsubcategoria.find(
      (classification: any) => 
        classification.id === values.classification
    )    
    
    classificationSelected?.pmcclasificacion_a_centrofacilitador
    .forEach((relationItem: any) => {      
      allRelations.push(relationItem);
    })

    let allCentrosFacilitadores: any = []

    if(allRelations) {      
      allRelations.forEach(
        (centroFacilitador: any) => {
          allCentrosFacilitadores = allCentrosFacilitadores.concat(centroFacilitador.pmccentrofacilitador);
        }
      )

    } else {
      return []
    }

    if(allCentrosFacilitadores) {      
      return allCentrosFacilitadores.map(
        (centroFacilitador: any) => {
          return {
            value: centroFacilitador.id,
            text: centroFacilitador.ubicacion
          }
        }
      )
    } else {
      return []
    }
  }, [classificationdata, values.classification])

  const categoriaCentroFacilitadorItems = useMemo(() => {
    
    const categorySelected =
    categoriasdata?.pmccategoria.find(
      (categoria: any) => 
        categoria.id === values.category
    )    
    
    if(categorySelected?.filtroCentroFacilitador) {
      const allRelations: any = [];
      
      categorySelected?.pmcclasificacion_a_centrofacilitador
      .forEach((relationItem: any) => {        
        allRelations.push(relationItem);
      })
  
      let allCentrosFacilitadores: any = []
  
      if(allRelations) {        
        allRelations.forEach(
          (centroFacilitador: any) => {
            allCentrosFacilitadores = allCentrosFacilitadores.concat(centroFacilitador.pmccentrofacilitador);
          }
        )
  
      } else {
        return []
      }
  
      if(allCentrosFacilitadores) {        
        return allCentrosFacilitadores.map(
          (centroFacilitador: any) => {
            return {
              value: centroFacilitador.id,
              text: centroFacilitador.ubicacion
            }
          }
        )
      } else {
        return []
      }
    } else if(categorySelected?.filtroTramiteYServicio) {
      const allRelations: any = [];
      
      categorySelected?.pmccategoria_a_tramiteyservicio
      .forEach((relationItem: any) => {        
        allRelations.push(relationItem);
      })      

      let allTramitesYServicios: any = []
  
      if(allRelations) {        
        allRelations.forEach(
          (centroFacilitador: any) => {
            allTramitesYServicios = allTramitesYServicios.concat(centroFacilitador.pmctramiteyservicio);
          }
        )
  
      } else {
        return []
      }
  
      if(allTramitesYServicios) {        
        return allTramitesYServicios.map(
          (tramiteYServicio: any) => {
            return {
              value: tramiteYServicio.id,
              text: tramiteYServicio.nombre
            }
          }
        )
      } else {
        return []
      }
    }
  }, [categoriasdata, values.category])

  useEffect(() => {  
    if(values.requestType) {
      const tipoTemp = tipoPeticiondata?.pmctipopeticion.find((tipo: TipoPeticion) => tipo.nombre === values.requestType)
      setRequestTypeSelected(tipoTemp);
    }
  }, [values.requestType])

  useEffect(() => {    
    if(formValidations) {      
      if(subCategoryItems.length > 0) {
        const subCategoryValidation: ObjectShape = {
          subCategory: Yup.string()
          .required(t("validation.required"))
        }
        const secondViewValidation = formValidations[1];
        const mergedValidations = {...secondViewValidation, ...subCategoryValidation}        
        setFormValidations?.([formValidations[0], mergedValidations]);
      } else {
        const secondViewValidation = formValidations[1];
        if(secondViewValidation.hasOwnProperty("subCategory")) {
          delete secondViewValidation.subCategory;          
          setFormValidations?.([formValidations[0], secondViewValidation]);
        }
      }
    }

    let isInList = subCategoryItems?.find((item: any) => item.value.toString() === values.subCategory.toString());

    if(!isInList) {
      setFieldValue('subCategory', "");
    }
  }, [subCategoryItems])

  return (
    <div className="inputs-container inputs-container--second-step">
      <div className="input-content">
        <div className="input-label">
          <span>{t("createPqrs.requestType")}</span>
          <div className="required-indicator">*</div>
        </div>
        {
          (requestTypeSelected && !allDisabled) &&
          <div className="description-request">
            <div className="description-request__top">
              <div className="description-request__title">{requestTypeSelected.nombre}</div>
              <i className="icon icon-cerrar description-request__icon" onClick={() => setRequestTypeSelected(null)}></i>
            </div>
            <div className="description-request__description-text">
              {requestTypeSelected.descripcion}
            </div>
          </div>
        }
        <Field name="requestType">
          {(props: FieldProps) => (
            <SelectForm
            disabled={allDisabled}
              {...props}
              items={
                tipoPeticiondata?.pmctipopeticion.map((tipopeticion: TipoPeticion) => {
                  const tipopeticionItem: SelectItem = {
                    value: tipopeticion.nombre,
                    text: tipopeticion.nombre
                  }
                  return(tipopeticionItem);
                })
              }
            />  
          )}
        </Field>                          
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>{t("createPqrs.category")}</span>
          <div className="required-indicator">*</div>
        </div>
        <Field name="category">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              disabled={values.requestType === '' || allDisabled}
              items={
                categoriasdata?.pmccategoria.map((categoria: Categoria) => {
                  const paisItem: SelectItem = {
                    value: categoria.id,
                    text: categoria.categoria
                  }
                  return(paisItem);
                })
              }
            />  
          )}
        </Field>
      </div>
      {
        values.category &&
        (
          categoriasdata?.pmccategoria.find(
            (categoria: any) => 
              categoria.id === values.category
          )?.filtroCentroFacilitador
          || categoriasdata?.pmccategoria.find(
            (categoria: any) => 
              categoria.id === values.category
          )?.filtroTramiteYServicio
        )
        ? <div className="input-content">
          <div className="input-label">
            <span>{t("createPqrs.classification")}</span>
            <div className="required-indicator">*</div>
          </div>
          <Field name={
            categoriasdata?.pmccategoria.find(
              (categoria: any) => 
                categoria.id === values.category
            )?.filtroCentroFacilitador
            ? "filtroCentroFacilitador"
            : "filtroTramiteYServicio"
          }>
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                disabled={values.category === '' || allDisabled}
                items={
                  categoriaCentroFacilitadorItems
                }
              />  
            )}
          </Field>
        </div>
        : classificationdata?.pmcsubcategoria.length === 0 
          ? null 
          : <>
            <div className="input-content">
              <div className="input-label">
                <span>{t("createPqrs.classification")}</span>
                <div className="required-indicator">*</div>
              </div>
              <Field name="classification">
                {(props: FieldProps) => (
                  <SelectForm
                    {...props}
                    disabled={values.category === '' || allDisabled}
                    items={
                      classificationdata?.pmcsubcategoria.map((classification: Classification) => {
                        const classificationItem: SelectItem = {
                          value: classification.id,
                          text: classification.subcategoria
                        }
                        return(classificationItem);
                      })
                    }
                  />  
                )}
              </Field>
            </div>
          {
            values.classification &&
            classificationdata?.pmcsubcategoria.find(
              (classification: any) => 
                classification.id === values.classification
            )?.filtroCentroFacilitador
            ? <div className="input-content">
              <div className="input-label">
                <span>{t("createPqrs.subCategory")}</span>
                <div className="required-indicator">*</div>
              </div>
              <div className="double-input">
              <Field name="subCategory">
                {(props: FieldProps) => (
                  <SelectForm
                    disabled={allDisabled}
                    {...props}
                    items={
                      subCategoryItems
                    }
                  />  
                )}
              </Field>
              </div>
            </div>
            : null
          }
        </>
      }
      {
        anonymous
        ? <div className="input-content">
          <div className="input-label">
            <span>{t("createPqrs.email")}</span>
          </div>
          <Field name="emailAnonimo">
            {(props: FieldProps) => (
              <InputForm 
                type="text"
                {...props}
                disabled={allDisabled}
              />  
            )}
          </Field>
        </div>
        : <div className="input-content">
          <div className="input-label">
            <span>{t("createPqrs.notificationWay")}</span>
            <div className="required-indicator">*</div>
          </div>
          <Field name="notificationWaySelected">
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                disabled={values.requestType === '' || allDisabled}
                items={ notificationWayOptionsEnable }
                itemsDisabled={ notificationWayOptionsDisable }
              />  
            )}
          </Field>
        </div>
      }
      <div className="inputs-flex inputs-flex--documents">
        <div className="document-content">
          <div className="input-document">
            <span>Adjuntar documentos</span>
            {
              allDisabled
              ? null
              : <div className={
                `box-input-container ` 
                + (
                  documentsUploaded?.length === 3 
                  ? 'box-input-container--disabled' 
                  : ''
                  )
                }>
                <div 
                  className="box-input"
                  onClick={() => 
                    documentsUploaded?.length === 3 
                    ? null 
                    : hiddenFileInput.current?.click()}>
                  <i className="box-input__icon icon icon-adjuntar-documentos"></i>
                  <div className="box-input__title">Seleccione los archivos que desea adjuntar</div>
                </div>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={uploadImg}
                  style={{display: 'none'}}
                  // accept="image/*"  
                />
              </div>
            }
          </div>
          <div className="documents-uploaded">
            <div className={`documents-uploaded__list` + (allDisabled ? ' documents-uploaded__list--disabled' : '') }>
              {
                documentsUploaded?.map((document) => {
                  return (
                    <div className="document-uploaded" key={document.id}>
                      <i className="document-uploaded__icon icon icon-docuemnto-pdf"></i>
                      <div className="document-uploaded__info">
                        <div className="document-uploaded__title">{document.name}</div>
                        {
                          allDisabled
                          ? null
                          : <div className="document-uploaded__bar">
                            <S.Loader activedBar={document.activeLoader}>
                              <div className="loader">
                                <div className="loaderBar"></div>
                              </div>
                            </S.Loader>
                          </div>
                        }
                      </div>
                      {
                        allDisabled
                        ? null
                        : <i className="document-uploaded__icon-delete icon icon-caneca" onClick={() => deleteDocument(document.id)}></i>
                      }
                    </div>
                  )
                })
              }
            </div>
            <div className="documents-uploaded__document-type">Unicos formatos permitidos: pdf, jpg y png (Solo puede cargar 3 archivos en total)</div>
          </div>
        </div>
        <div className="comment-content">
          <span>Comentarios/Observaciones</span>
          <Field name="comment">
            {(props: FieldProps) => (
              <InputForm
                disabled={allDisabled}
                type="text"
                textarea={true}
                height={200}
                {...props}
              /> 
            )}
          </Field>
        </div>
      </div>
      <div className={
        `box-accept ` 
        + (
          allDisabled 
          ? 'box-accept--disabled' 
          : ''
          )
      }
      >
        <div className="box-accept__title">Nota aclaratoria Habeas Data</div>
        <div className="box-accept__description">
 Al hacer clic el botón confirmar, usted acepta la remisión de la PQRS a Migración Colombia. Sus
datos serán recolectados y tratados conforme con la Política de Tratamiento de Datos. En la
opción consulta de PQRS podrá verificar el estado de la respuesta.
<p> 
En caso que la solicitud de información sea de naturaleza de identidad reservada, deberá
efectuar el respectivo trámite ante la Procuraduría General de la Nación, haciendo clic en el
siguiente link:
<a href="https://www.procuraduria.gov.co/PQRSDF/Pages/default.aspx" target="_blank">https://www.procuraduria.gov.co/PQRSDF/Pages/default.aspx</a>
</p>
<p>
Recuerde que, al radicar de manera anónima, usted acepta las condiciones conforme a lo
establecido en el artículo 38 de la Ley 190 de 1995; artículo 69; de la Ley 734 de 2002 y artículo
81 de la Ley 962 de 2005.
</p>
        </div>
        <div className={
          `box-accept__input-container ` 
          + (
            !allDisabled 
            ? 'box-accept__input-container--disabled' 
            : ''
            )
        }>
          <span className="box-accept__input-label">Acepto</span>
          <div className="required-indicator">*</div>
          <div className="box-accept__input-radius">
            <input type="radio" 
              id="si" 
              name="habeasData" 
              checked={values.habeasData === true} 
              value="true"
              onChange={() => allDisabled ? null : setFieldValue("habeasData", true)}
            />
            <label htmlFor="si">Sí</label>
            <input type="radio" 
              id="no" 
              name="habeasData" 
              checked={values.habeasData === false} 
              value="false"
              onChange={() => allDisabled ? null : setFieldValue("habeasData", false)}
            />
            <label htmlFor="no">No</label>
          </div>
        </div>
      </div>
    </div>
  )
} 


interface PropsThirdViewForm {
  documentsUploaded?: DocumentFile[],
  setDocumentsUploaded?: Dispatch<SetStateAction<DocumentFile[]>>
  initialFormValidations?: ObjectShape[],
  anonymous?: boolean
}

function ThirdViewForm({
  documentsUploaded,
  setDocumentsUploaded,
  anonymous
}: PropsThirdViewForm) {
  
  return (
    <div className="inputs-container inputs-container--third-step">
      {
        anonymous
        ? null
        : <FirstViewForm allDisabled/>
      }
      <SecondViewForm 
        documentsUploaded={documentsUploaded}
        setDocumentsUploaded={setDocumentsUploaded}
        anonymous={anonymous}
        allDisabled
      />
    </div>
  )
}

interface PropsSuccessViewForm {
  setCreationSuccess: Dispatch<SetStateAction<boolean>>
  setTabSelected: Dispatch<SetStateAction<number>>
  createPqrsData: any
  anonymous?: boolean
}

function SuccessViewForm({ 
  setCreationSuccess,
  setTabSelected,
  createPqrsData,
  anonymous
}: PropsSuccessViewForm) {
  const {t} = useTranslation("global");
  // const { resetForm } = useFormikContext();

  const dispatch = useDispatch();   

  return(
    <div className="pqrs-consulted">
      <div className="pqrs-consulted__form">
        <div className="pqrs-consulted__title">
          <i className="pqrs-consulted__title-icon icon icon-solicitud-radicada"></i>
          <div className="pqrs-consulted__title-text">Su solicitud ha sido registrada de forma exitosa en nuestro Centro de Consulta Ciudadana - C3</div>
        </div>
        <div className="pqrs-consulted__info">
          <div className="info-item">
            <div className="info-item__label">{t("consultPqrs.pqrsBoxInfo.settled")} N°</div>
            <div className="info-item__data">{createPqrsData?.data.pqrs.pmcpqrs[0].numeroRadicado!}</div>
          </div>
        </div>
        <div className="pqrs-consulted__encuesta">
          Lo invitamos a realizar la encuesta de satisfacción en el siguiente link: 
          {
            createPqrsData?.data.encuesta &&
            <div className="pqrs-consulted__encuesta-link" onClick={() => {
                  window.open(createPqrsData?.data.encuesta)
                }}> 
              {createPqrsData?.data.encuesta}
            </div>
          }
        </div>
        <div className="pqrs-consulted__info-laws">
          {
            anonymous
            ? <div className="info-laws__text">
              Puedes usar este codigo para consultar el estado 
              de tu PQRS en esta misma pagina.
            </div> 
            : <div className="info-laws__text">
              En la bandeja de entrada de su correo electronico 
              recibirá notificaciones de la
              PQRS. En caso de no encontrarlo revise su correo no
              deseado (Spam).
            </div>
          }
        </div>
      </div>
    <div className="pqrs-consulted__button-container" onClick={
      () => {
        setCreationSuccess(false);
        dispatch(createPqrsReset());        
        if(anonymous) {
          setTabSelected(2);
        } else {
          setTabSelected(1);
        }
      }
    }>
      <Button 
        text="FINALIZAR"
        fontSize={20}
      />
    </div>
  </div>
  )
}


export default CreatePQRS;
