import { Outlet } from "react-router-dom";
import Footer from "./shared/footer";
import Header from "./shared/header";
import * as S from "./Pages.style";

interface  Props {
  authed: boolean;
}

function Pages({authed}: Props) {

  return (
    <S.Pages>
      <Header />
        <Outlet/>
      <Footer />
    </S.Pages>
  );
}

export default Pages;