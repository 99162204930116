import * as S from "./ModalContainer.style";

interface  Props {
  children: 
  | JSX.Element
  | JSX.Element[]
  | string
  | string[];
}


function ModalContainer({children}: Props) {

  return(
    <S.ModalContainer>
      <div className="modal-background"></div>
      <div className="modal-background-dark"></div>
      <div className="modal-content">
        {children}
      </div>
    </S.ModalContainer>
  )
}

export default ModalContainer;