import * as S from "./Button.style"

interface  Props {
  text: String;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  fontSize?: number;
  colorText?: string;
  color?: string;
  borderRadius?: number;
  borderColor?: string;
}

function Button({text, type, fontSize, colorText, color, borderRadius, borderColor}: Props) {

  return (
    <S.Button
      type={type}
      fontSize={fontSize}
      colorText={colorText}
      color={color}
      borderColor={borderColor}
      borderRadius={borderRadius}
    >
      {text}
    </S.Button>
  );
}

export default Button;