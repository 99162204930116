import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ThemeProvider } from "styled-components";
import { useSelector, useDispatch } from 'react-redux';
import { darkTheme, lightTheme, GlobalStyles } from "./themes"
import RoutesIndex from './routes/RoutesIndex';
import SEO from './utils/SEO';
import useInfoToken from './hooks/useInfoToken';
import { RootState } from "./redux/store";
import { isAuthChange } from "./redux/reducers/authSlice";
import useUserData from "./hooks/useUserData";
import { useLazyQuery, useQuery } from '@apollo/client';
import { QUERY_GET_USER } from './graphql/queries/User';
import { TokenInfo } from './models/logicModels/TokenInfo';
import Chatbot from './pages/shared/chatbot';
interface Props {
  setAccessToken?: (accessToken: string | null) => void;
}

function App({ setAccessToken }: Props) {
  const dispatch = useDispatch();  

  const tokenVerified = localStorage.getItem("accessToken");
  const [ existToken, setExistToken ] = useState(tokenVerified ? true : false);
  const { getTokenInfo } = useInfoToken();
  const { saveUserData } = useUserData();
  const [tokenInfo,  setTokenInfo] = useState<TokenInfo | null>();

  const { isAuth } = useSelector((state: RootState) => state.auth);

  const 
  [
    getUser,
    {
      loading: userLoading,
      data: userdata,
      error: userError
    }
  ] 
  = 
  useLazyQuery
  // useQuery
  (
    QUERY_GET_USER,
    {
      onCompleted: (data) => {       
        saveUserData(data?.pmccontacto[0]);
      },
      onError: error => {
        console.error(error);
        localStorage.removeItem("accessToken");
        setExistToken(false);
        setAccessToken?.(null);
        dispatch(isAuthChange(false));
      },
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
  const tokenInfoTemp = getTokenInfo();
  
    if (!tokenInfoTemp) {
      dispatch(isAuthChange(false));
      localStorage.removeItem("accessToken");
      setAccessToken?.(null);
      setExistToken(false);
    } else {
      setTokenInfo(tokenInfoTemp);  
      dispatch(isAuthChange(true));
      localStorage.setItem("accessToken", tokenInfoTemp.token);
      setAccessToken?.(tokenInfoTemp.token);
      // setAccessToken?.(tokenVerified);
      setExistToken(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, dispatch, setAccessToken]);

  useEffect(() => {    
    if(isAuth && tokenInfo) {
      getUser({variables: {userId: tokenInfo?.userId}})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, tokenInfo]);
  return (
    <ThemeProvider theme={
      // colorTheme === 'light' 
      true ? lightTheme : darkTheme} >
      <GlobalStyles />
      <SEO/>
      {/* <Chatbot/> */}
      <RoutesIndex existToken={existToken}/>
    </ThemeProvider>
  );
}

export default App;
