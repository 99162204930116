import { createGlobalStyle } from "styled-components"

export const lightTheme = {
  white: '#FFFFFF',
  darkBlue: '#022D61',
  softBlue: '#18A1D5',
  backgroundGrey: '#E9E9E9',
  orange: '#FBB03B',
  green: '#ACDA31',
  greenBar: '#68c274',
  blackText: '#333333',
  black: '#000000',
  greyTable: '#E8E5E5',
  greyBox: '#F3F3F3',
  greyText: '#B3B3B3',
  red: '#ff0000',
}

export const darkTheme = {
  
}

export const  GlobalStyles = createGlobalStyle`
  
`