export function getHexOpacity(opacity: number): string {
  return Math.floor(opacity * 255).toString(16).padStart(2, '').toUpperCase();
}

export function getMicLanguage(currentLanguage: string) {
  const language = currentLanguage.split('-')[0];
  if (language === 'es') {
    return 'es-ES'
  } else {
    return 'en-US'
  }
}

// date in '01/03/2021' 
export function formatNumberDate ( data: Date | string , yearActive: boolean ): string {
  const day = new Date(data).getDate();
  const month = new Date(data).getMonth() + 1;
  const year = new Date(data).getFullYear();
  return ((day < 10 ? '0' + day : day) + '/' + (month < 10 ? '0' + month : month) + (yearActive ? '/' + year : '' ));
}

export function onlyFirstCapital (text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}