import styled from "styled-components";

export const Register = styled.div`
  display: flex;
  .register {
    display: flex;
    margin: auto;
    /* flex-direction: column; */
    margin: 60px 0;
    /* margin: 50px 100px 50px 0; */
    width: 100%;
    justify-content: center;
    &__left {
      align-self: center;
      padding: 50px 90px 50px 0;
      .box {
        width: 220px;
        box-shadow: 0px 0px 9px #00000012;
        border-radius: 20px;
        background-color: ${({theme}) => theme.white};
        margin: 20px;
        padding: 60px 40px;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        &__icon {
          justify-content: center;
          font-size: 65px;
          color: ${({theme}) => theme.softBlue};
        }
        &__text {
          color: ${({theme}) => theme.blackText};
          font-size: 13px;
          margin-top: 5px;
        }
        &__text-big {
          color: ${({theme}) => theme.darkBlue};
          font-size: 30px;
          font-family: 'Poppins Bold';
          border-bottom: 2px solid #18A1D5;
          line-height: 32px;
          width: fit-content;
          display: flex;
          margin: auto;
        }
      }
    }
    &__right {
      align-self: center;
      .response-message {
        margin: 19px 0;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins Bold';
        color: ${({theme}) => theme.darkBlue};
      }
      .register-form {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .inputs-container {
          justify-content: flex-start;
          align-items: center;
          display: flex;
          width: 100%; 
          flex-wrap: wrap;
          max-width: 610px;
          .input-content {
            min-width: 275px;
            display: flex;
            flex-direction: column;
            margin: 0 15px;
            position: relative;
            .input-label {
              display: flex;
              font-size: 13px;
              padding-bottom: 1px;
              align-items: center;
              span {
                opacity: 0.7;
              }
              .tooltip-container {
                margin-left: 10px;
              }
            }
          }
          &--password {
            margin-top: 40px;
          }
          .inputs-flex {
            display: flex;
          }
        }
        .captcha-container {
          align-self: center;
          margin-bottom: 20px;
        }
        .list-container {
          font-size: 10px;
          margin-bottom: 20px;
        }
        .button-container{
          width: 200px;
          justify-content: center;
          display: flex;
          margin: auto;
        }
      }
    }
  }

  @media only screen and (max-width: 980px) {
    .register {
      flex-direction: column;
      &__left {
        padding: 0 0 20px 0;
      }
    }
    .inputs-flex {
      flex-direction: column;
    }
    .inputs-container {
      width: min-content !important;
    }
    .register-form {
      align-items: center !important;
    }
  }
`;
