
import { gql } from "@apollo/client";

export const QUERY_GET_DEPARTAMENTOS = gql`
  query QUERY_GET_DEPARTAMENTOS {
    pmcdepartamento {
      departamento
      id
      iso
    }
  }
`
  