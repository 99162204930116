
import { gql } from "@apollo/client";

export const QUERY_GET_PAISES = gql`
  query QUERY_GET_PAISES {
    pmcpais {
      id
      iso
      pais
    }
  }
`
  