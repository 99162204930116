import React from 'react';
import { Helmet } from "react-helmet";

function SEO() {
  return (
    <Helmet>
      <title>{'Migración | Centro de Consulta Ciudadana'}</title>
      {/* <meta name="description" content={'descripppppptttttt'} /> */}
    </Helmet>
  );
}

export default SEO;