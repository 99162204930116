import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./Register.style";

// Components
import RegisterForm from "./components/RegisterForm";
import EmailValidation from "./components/emailValidationForm";
import EmailCodeVerificationForm from "./components/emailCodeVerificationForm";

function Register() {

  const {t} = useTranslation("global");

  const [emailVerified, setEmailVerified] = useState<boolean>(false); 

  const [email, setEmail] = useState<string>("");
  const [activeCode, setCodeActive] = useState<boolean>(false);

  return (
    <S.Register>
      <div className="register">
        <div className="register__left">
          <div className="box">
            <i className="box__icon icon icon-usuario-nuevo"/>
            <div className="box__text">{t("register.pretitle")}</div>
            <div className="box__text-big">{t("register.title")}</div>
          </div>
        </div>
        <div className="register__right">
          {
            !emailVerified && !activeCode &&
            <EmailValidation
              setEmail={setEmail} 
              setCodeActive={setCodeActive}
            />
          } 
          {
            !emailVerified && activeCode &&
            <EmailCodeVerificationForm
              email={email}
              setEmail={setEmail}
              setCodeActive={setCodeActive}
              setEmailVerified={setEmailVerified}
            />
          }
          {
            emailVerified && 
            <RegisterForm
              email={email}
              setEmail={setEmail}
              setCodeActive={setCodeActive}
              setEmailVerified={setEmailVerified}
            /> 
          }
        </div>
      </div>
    </S.Register>
  );
}

export default Register;
