import { useEffect } from "react";
import Button from "../../button";
import * as S from "./CustomModal.style";

interface  Props {
  title: string, 
  type: "success" | "failed" | "loading" | "alert",
  description?: string,
  action: () => void,
  actionText?: string,
  action2?: () => void,
  actionText2?: string
}


function CustomModal({title, description, type, action, actionText, action2, actionText2}: Props) {
  
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  //   document.body.style.overflow = "hidden"
  //   return () => {
  //     document.body.style.overflow = "auto"
  //   }
  // }, [])  

  return(
    <S.CustomModal
      modalType={type}
    >
      <div className="custom-modal-box">
        {
          type === "success" 
          ? <div className="custom-modal-box__icon-container custom-modal-box__icon-container--success">
            <i className="custom-modal-box__img-icon icon icon-estado-aprobado" /> 
          </div> 
          : type === "failed"
            ? <div className="custom-modal-box__icon-container custom-modal-box__icon-container--failed">
              <i className="custom-modal-box__img-icon icon icon-estado-rechazado" /> 
            </div> 
            : type === "loading" 
              ? <div className="custom-modal-box__icon-container custom-modal-box__icon-container--loading">
                <i className="custom-modal-box__img-icon icon icon-estado-cargando" /> 
              </div> 
              : <div className="custom-modal-box__icon-container custom-modal-box__icon-container--alert">
                <i className="custom-modal-box__img-icon icon icon-estado-advertencia" /> 
              </div> 
        }
        <div className="custom-modal-box__text">
          {title}
        </div>
        <div className="custom-modal-box__description"  dangerouslySetInnerHTML={{__html: `${description}`}} >
      
        </div>
        <div className="custom-modal-box__buttons">
          {
            type !== "loading" 
            ? <div className="custom-modal-box__button" onClick={() => action()}>
              <Button
                text={actionText ? actionText : "CERRAR"} 
                borderRadius={10}
                fontSize={20}
              />
            </div>
            : null
          }
          {
            action2 && actionText2
            ? <div className="custom-modal-box__button" onClick={() => action2()}>
              <Button
                text={actionText2} 
                fontSize={20}
                colorText="#18A1D5"
                color="white"
                borderColor="#18A1D5"
                borderRadius={10}
              />
            </div>
            : null
          }
        </div>
      </div>
    </S.CustomModal>
  )
}

export default CustomModal;
