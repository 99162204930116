import styled from "styled-components";

export const SessionOptions = styled.div`
  display: flex;
  .container {
    display: flex;
    margin: auto;
    flex-direction: column;
    margin: 60px 0;
    width: 100%;
    .boxes-container {
      margin-top: 25px;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
    .box {
      width: 250px;
      box-shadow: 0px 0px 9px #00000012;
      border-radius: 20px;
      background-color: ${({theme}) => theme.white};
      margin: 20px;
      padding: 30px 20px;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
      &__icon {
        justify-content: center;
        font-size: 60px;
        color: ${({theme}) => theme.softBlue};
      }
      &__text {
        color: ${({theme}) => theme.blackText};
        font-size: 13px;
        margin-top: 5px;
      }
      &__text-big {
        color: ${({theme}) => theme.darkBlue};
        font-size: 30px;
        font-family: 'Poppins Bold';
        border-bottom: 2px solid #18A1D5;
        line-height: 32px;
        width: fit-content;
        display: flex;
        margin: auto;
      }
      &__text-medium {
        color: ${({theme}) => theme.darkBlue};
        font-size: 25px;
        font-family: 'Poppins Bold';
        line-height: 32px;
        margin-top: 5px;
      }
      &__text-long {
        color: ${({theme}) => theme.blackText};
        font-size: 10px;
        margin-top: 5px;
        font-family: 'Poppins';
        padding: 0 10%;
        font-weight: 600;
      }
      &__button {
        width: 70%;
        display: flex;
        margin: 7px auto;
      }
    }
    .bottom-info {
      width: 51%;
      align-self: center;
      margin-top: 30px;
      font-size: 12px;
      .message {
        display: flex;
        justify-content: space-between;
        &__button {
          width: 400px;
          height: fit-content;
          display: flex;
        }
        &__text {
          font-weight: 600;
          margin-right: 10px;
        }
      }
      .link {
        margin-top: 10px;
        font-weight: 600;
        width: auto;
        font-style: italic;
        a {
          color: ${({theme}) => theme.blackText};
          line-break: anywhere;
        }
      }
    }
  }

  @media only screen and (max-width: 980px) {
    .bottom-info {
      .message {
        flex-direction: column;
        text-align: center;
        &__text {
          margin-right: 0;
        }
        &__button {
          width: 150px !important;
          margin-top: 20px;
          align-self: center;
        }
      }
    }
  }
`;
