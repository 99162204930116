import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../shared/button";
import * as S from "./SessionOptions.style";
import { ROUTES } from "../../../utils/constants";

function SessionOptions() {
  const navigate = useNavigate();
  const { LOGIN, REGISTER, CREATE_PQRS_ANONYMOUS } = ROUTES;
  const [t] = useTranslation  ("global");


  useEffect(() => {    
  }, []);

  return (
    <S.SessionOptions>
      <div className="container">
        <div className="boxes-container">
          <div className="box" onClick={() => navigate(REGISTER.route)}>
            <i className="box__icon icon icon-usuario-nuevo"/>
            <div className="box__text">{t("sessionOptions.boxes.box1.preTitle")}</div>
            <div className="box__text-big">{t("sessionOptions.boxes.box1.title")}</div>
            <div className="box__text-long">{t("sessionOptions.boxes.box1.descript")}</div>
            <div className="box__button">
              <Button
                text={t("sessionOptions.boxes.box1.buttonText")}
              />
            </div>
          </div>
          <div className="box" onClick={() => navigate(LOGIN.route)}>
            <i className="box__icon icon icon-usuario-registrado"/>
            <div className="box__text">{t("sessionOptions.boxes.box2.preTitle")}</div>
            <div className="box__text-big">{t("sessionOptions.boxes.box2.title")}</div>
            <div className="box__text-long">{t("sessionOptions.boxes.box2.descript")}</div>
          </div>
          <div className="box" onClick={() => navigate(CREATE_PQRS_ANONYMOUS.route)}>
            <i className="box__icon icon icon-pqrs-annimas"/>
            <div className="box__text-medium">{t("sessionOptions.boxes.box3.preTitle")}</div>
            <div className="box__text-big">{t("sessionOptions.boxes.box3.title")}</div>
            <div className="box__text-long">{t("sessionOptions.boxes.box3.descript")}</div>
          </div>
        </div>
        <div className="bottom-info">
          <div className="message">
            <div className="message__text">
              {t("sessionOptions.description")}
            </div>
            <div className="message__button"
              onClick={() => {window.open('https://www.procuraduria.gov.co/PQRSDF/Pages/default.aspx')}}
            >
              <Button
                text={t("sessionOptions.descriptionButton")}
              />
            </div>
          </div>
          {/* <div className="link">
            <a href="https://www.procuraduria.gov.co/portal/solicitud_informacion_identificacion_reservada.page">
              https://www.procuraduria.gov.co/portal/solicitud_informacion_identificacion_reservada.page
            </a>
          </div> */}
        </div>
      </div>
    </S.SessionOptions>
  );
}

export default SessionOptions;
