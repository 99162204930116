import { call, put, takeEvery } from 'redux-saga/effects';
import authServices from '../../services/authService';
import { loginFailure, loginSuccess } from '../reducers/loginSlice';

function* fetchLogin(action: any): any {
  try {
    const loginResponse = yield call(() => authServices.login(action.payload));
    yield put(loginSuccess(loginResponse.data));
  } catch (error) {
    yield put(loginFailure());
  }
}

function* loginSaga() {
  yield takeEvery('login/login', fetchLogin);
}

export default loginSaga;