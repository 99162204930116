import styled from "styled-components";

interface Props {
}

export const Tooltip = styled.div<Props>`
  .tooltip-icon {
    color: ${({theme}) => theme.softBlue}
  }
  .tooltip-box {
    color: ${({theme}) => theme.white};
    background-color: ${({theme}) => theme.softBlue};
    
  }
`;

export const TooltipBox = styled.div<Props>`
  color: ${({theme}) => theme.white};
  background-color: ${({theme}) => theme.softBlue};
  font-size: 11px;
  padding: 2px 5px;
  max-width: 250px;
  .popup-arrow {
    color: green;
  }
`;