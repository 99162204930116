import styled from "styled-components";

export const ResetPass = styled.div`
  display: flex;
  .reset-pass {
    display: flex;
    margin: auto;
    /* flex-direction: column; */
    margin: 60px 0;
    width: 100%;
    justify-content: center;
    &__left {
      align-self: center;
      padding: 50px 100px 50px 0;
      .box {
        width: 245px;
        box-shadow: 0px 0px 9px #00000012;
        border-radius: 20px;
        background-color: ${({theme}) => theme.white};
        margin: 20px;
        padding: 60px 17px;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        &__icon {
          justify-content: center;
          font-size: 65px;
          color: ${({theme}) => theme.softBlue};
        }
        &__text {
          color: ${({theme}) => theme.blackText};
          font-size: 13px;
          margin-top: 5px;
        }
        &__text-big {
          color: ${({theme}) => theme.darkBlue};
          font-size: 30px;
          font-family: 'Poppins Bold';
          border-bottom: 2px solid #18A1D5;
          line-height: 32px;
          width: fit-content;
          display: flex;
          margin: auto;
        }
      }
    }
    &__right {
      align-self: center;
      .response-message {
        margin: 19px 0;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins Bold';
        color: ${({theme}) => theme.darkBlue};
      }
      .reset-pass-form {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        align-items: center;
        .inputs-container {
          .input-content {
            width: 500px;
            display: flex;
            flex-direction: column;
            margin: 0 10px;
            .input-label {
              font-size: 13px;
              padding-bottom: 1px;
              span {
                opacity: 0.7;
              }
            }
          }
        }
        .list-container {
          font-size: 10px;
          margin-bottom: 20px;
        }
        .button-container{
          width: 200px;
          justify-content: center;
          display: flex;
          margin: auto;
          font-size: 18px;
          margin-top: 20px;
        }
        .forgotten-pass {
          font-size: 12px;
          text-align: center;
          margin-top: 5px;
        }
      }
      .message-box {
        background-color: ${({theme}) => theme.white};
        margin: 0 60px;
        box-sizing: border-box;
        border-radius: 10px; 
        padding: 40px 50px; 
        &__title {
          font-family: 'Poppins Bold';
          text-align: center;
          font-size: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            color: ${({theme}) => theme.softBlue};
            margin-right: 10px;
          }
        }
        &__message {
          margin-top: 20px;
          font-size: 15px;
          text-align: center;
        }
      }
      .button-container {
        width: 200px;
        display: flex;
        margin: 34px auto 0 auto;
      }
    }
  }

  @media only screen and (max-width: 980px) {
    .reset-pass {
      flex-direction: column;
      &__left {
        padding: 0 0 20px 0;
      }
    }
    .input-content {
      width: 240px !important;
    }
  }
`;