import { useRef, useState, Dispatch, SetStateAction, useMemo } from "react";
import * as S from "./EmailValidation.style";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { Field, FieldProps, Formik, FormikHelpers } from "formik";

// Apollo
import { useMutation } from "@apollo/client";
import { MUTATION_EMAIL_VALIDATION } from "../../../../../graphql/mutations/Auth";

// Components 
import InputForm from "../../../../shared/inputForm";
import Button from "../../../../shared/button";
import Portal from "../../../../shared/modals/portal";
import ModalContainer from "../../../../shared/modals/modalContainer";
import CustomModal from "../../../../shared/modals/customModal";

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

interface Props {
  setEmail: Dispatch<SetStateAction<string>>;
  setCodeActive: Dispatch<SetStateAction<boolean>>;
}


function EmailValidation({
  setEmail,
  setCodeActive
}: Readonly<Props>) {

  const {t} = useTranslation("global");
  const [emailValidation, { loading: emailValidationLoading, error: emailValidationError, reset: emailValidationReset}] = useMutation(MUTATION_EMAIL_VALIDATION);
  const [resMessage, setResMessage] = useState<string>('');
  const [errorCaptcha, setErrorCaptcha] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const emailRecoveryCaptchaRef = useRef<any>(null);

  interface  emailValidationFields {
    email: string,
  }
  
  const emailRecoveryFormInitialValues: emailValidationFields = {
    email: ''
  }

  const handleSubmit = (values: emailValidationFields, formikHelpers: FormikHelpers<emailValidationFields>) => {
    if (emailRecoveryCaptchaRef.current.getValue()) {
      setErrorCaptcha(false);
      emailValidationFunction(values, formikHelpers);
    } else {
      setErrorCaptcha(true);
      setResMessage("Error de Captcha");
      emailRecoveryCaptchaRef.current.reset();
    }
  }

  const emailValidationFunction = async (values: emailValidationFields, formikHelpers: FormikHelpers<emailValidationFields>) => {
    try {
      const { data : {
        verificarEmailRegistro: { message },
        }
      } 
      = await emailValidation({
        variables: {
          email: values.email.toString(),
          captchaToken: emailRecoveryCaptchaRef.current.getValue()
        },
      });
      setEmail(values.email.toString());
      setResMessage(message);
      formikHelpers.resetForm();
      emailRecoveryCaptchaRef.current.reset();
    } catch (err: any) {
      setError(true);
      setResMessage(err.message);
      setErrorCaptcha(false);
      formikHelpers.resetForm();
      emailRecoveryCaptchaRef.current.reset();
    }
  }
  
  const cleanErrors = () => {
    registerRedirection();
    setError(false);
    emailValidationReset();
    setResMessage("");
    setErrorCaptcha(false);
  }

  const modalTitle = useMemo(() => {
    if (emailValidationLoading) {
      return "Cargando";
    } else if (emailValidationError || errorCaptcha || error) {
      return "Error";
    } else {
      return "Email enviado";
    }
  }, [emailValidationLoading, emailValidationError, errorCaptcha, error]);

  const modalDescription = useMemo(() => {
    if (emailValidationLoading) {
      return "";
    } else {
      return resMessage;
    }
  }, [emailValidationLoading, resMessage]);

  const modalType = useMemo(() => {
    if (emailValidationLoading) {
      return "loading";
    } else if (emailValidationError || errorCaptcha || error) {
      return "failed";
    } else {
      return "success";
    }
  }, [emailValidationLoading, emailValidationError, errorCaptcha, error]);

  const registerRedirection = () => {
    if (emailValidationError || errorCaptcha || error) {
      setCodeActive(false);
    } else {
      setCodeActive(true);
    }
  }

  return (
    <S.EmailValidation>
      {
        (emailValidationLoading || emailValidationError || errorCaptcha || resMessage || error) && 
        <Portal>
          <ModalContainer>
            <CustomModal
              title={modalTitle}
              description={modalDescription}
              type={modalType}
              action={cleanErrors}
            />
          </ModalContainer>
        </Portal>
      }
      <div className="email-validation-title">
        Validar email para registro
      </div>
      <Formik
        initialValues={emailRecoveryFormInitialValues}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("validation.email"))
            .required(t("validation.required")),
        })}
        onSubmit={handleSubmit}
      >
        {({handleSubmit, values, handleChange}) => (
          <form className="email-validation-form" onSubmit={handleSubmit}>
            <div className="inputs-container">
              <div className="input-content">
                <div className="input-label">
                  <span>{t("resetPass.email")}</span>
                </div>
                {/* <div className="double-input"> */}
                <Field name="email">
                  {(props: FieldProps) => (
                    <InputForm 
                      type="email"
                      {...props}
                    />  
                  )}
                </Field>
                <div className="link-container">
                  <a className="ink-container__text" href="#" onClick={() => setCodeActive(true)}>{"Tengo código de verificación"}</a>
                </div>
                {/* </div> */}
              </div>

              </div>
            {/* </div> */}
            <div className="captcha-container">
              {
                <ReCAPTCHA
                  sitekey={reCaptchaKey!}
                  ref={emailRecoveryCaptchaRef}
                />
              }
            </div>
            <div className="button-container">
              <Button 
              type={emailValidationLoading ? "button" : "submit"}
                text={
                  !emailValidationLoading
                  ? t("resetPass.buttonText")
                  : `${t("global.loading")}...`
                }
                fontSize={17}
              />
            </div>
          </form>
        )}
      </Formik>
    </S.EmailValidation>
  );
}

export default EmailValidation;
