import styled, { keyframes } from "styled-components";

const dotPulseBefore = () => keyframes`
  0% {
    box-shadow: 9980px 0 0 -8px #000;
  }
  30% {
    box-shadow: 9980px 0 0 .3px #000;
  }
  60%,
  100% {
    box-shadow: 9980px 0 0 -8px #000;
  }
`;

const dotPulseAfter = () => keyframes`
  0% {
    box-shadow: 10010px 0 0 -8px #000;
  }
  30% {
    box-shadow: 10010px 0 0 .3px #000;
  }
  60%,
  100% {
    box-shadow: 10010px 0 0 -8px #000;
  }
`;

const dotPulse = () => keyframes`
  0% {
    box-shadow: 9999px 0 0 -8px #000;
  }
  30% {
    box-shadow: 9999px 0 0 .3px #000;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -8px #000;
  }
`;

const opacityTransition = () => keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

interface Props {
}

export const LoadingModal = styled.div<Props>`
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  .loading-modal-box {
    backdrop-filter: blur(0px);
    margin: auto;
    background-color: ${({theme}) => theme.white};
    border-radius: 10px;
    text-align: center;
    &__img-icon {
      width: 110px;
    }
    &__img-title {
      width: 500px;
    }
    &__text {
      font-family: 'Poppins Bold';
      animation: ${opacityTransition()} 4s infinite linear;
    }
    .points-container {
      position: relative;
      /* padding: 10px; */
      display: inline-flex;
      margin-left: 13px;
      opacity: 0.8;
      transform: scale(0.6);
    }

    .dot-pulse {
      position: relative;
      left: -9999px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: black;
      color: black;
      box-shadow: 9999px 0 0 -5px black;
      animation: ${dotPulse()} 1.5s infinite linear;
      animation-delay: .25s;
    }

    .dot-pulse::before, .dot-pulse::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #000;
      color: #000;
    }

    .dot-pulse::before {
      box-shadow: 9984px 0 0 -5px #000;
      animation: ${dotPulseBefore()} 1.5s infinite linear;
      animation-delay: 0s;
    }

    .dot-pulse::after {
      box-shadow: 10014px 0 0 -5px #000;
      animation: ${dotPulseAfter()} 1.5s infinite linear;
      animation-delay: .5s;
    }
  }

  @media only screen and (max-width: 630px) {
    .loading-modal-box {
      width: 280px;
      &__img-icon {
        width: 90px;
      }
      &__img-title {
        width: 250px;
      }
    }
  }
`;
