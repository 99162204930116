import Popup from "reactjs-popup";
import * as S from "./Tooltip.style"

interface  Props {
  text: String;
}

function Tooltip({text}: Props) {

  return (
    <S.Tooltip>
      <Popup
        trigger={<i className="tooltip-icon icon icon-menu-informacin"></i>}
        keepTooltipInside
        position="right center"
        on="hover"
        arrowStyle={{"color": "transparent"}}
      >
        <S.TooltipBox>
          {text}
        </S.TooltipBox>
      </Popup>
    </S.Tooltip>
  );
}

export default Tooltip;