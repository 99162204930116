import styled, { keyframes } from "styled-components";

const opacityTransition = () => keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const positionTransition = () => keyframes`
  0% {
    transform: translateY(55px);
  }
  50% {
    transform: translateY(55px);
  }
  100% {
    transform: translateY(0);
  }
`;

const iconRotationFailed = () => keyframes`
  0% {
    transform: rotate(45deg) translateX(57px);
  }
  50% {
    transform: rotate(45deg) translateX(57px);
  }
  62.5% {
    transform: rotate(33.75deg) translateX(22px);
  }
  68.75% {
    transform: rotate(28deg) translateX(12px);
  }
  75% {
    transform: rotate(22.5deg) translateX(5px);
  }
  100% {
    transform: rotate(0deg) translateX(0px);
  }
`;

const iconRotationLoading = () => keyframes`
  0% {
    transform: rotate(45deg) translateX(57px);
  }
  50% {
    transform: rotate(45deg) translateX(57px);
  }
  62.5% {
    transform: rotate(33.75deg) translateX(22px);
  }
  68.75% {
    transform: rotate(28deg) translateX(12px);
  }
  75% {
    transform: rotate(22.5deg) translateX(5px);
  }
  100% {
    transform: rotate(0deg) translateX(0px);
  }
`;

const iconRotationSuccess = () => keyframes`
  0% {
    transform: rotateY(180deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`;

const iconRotationLoop = () => keyframes`
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

interface Props {}

export const ModalContainer = styled.div<Props>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  .modal-background {
    backdrop-filter: blur(4px);
    width: 100%;
    height: 100%;
    position: absolute;   
  }
  .modal-background-dark {
    width: 100%;
    height: 100%;
    position: absolute;    
    background-color: black;
    opacity: .4;
  }
  .modal-content {
    /* width: 100%;
    height: 100%; */
    opacity: 1;
    transition: opacity 2s linear;
    backdrop-filter: blur(0px);
    margin: auto;
    background-color: ${({theme}) => theme.white};
    border-radius: 10px;
    padding: 20px 60px;
    text-align: center;
    box-shadow: 13px 13px 32px -17px rgba(0,0,0,0.75);
    /* width: 550px; */
  }

  @media only screen and (max-width: 980px) {
    .modal-content {
      padding: 20px 10px !important;
    }
  }
`;
