import styled from "styled-components";

export const Footer = styled.div`
  margin-top: auto;
  background-color: ${({theme}) => theme.darkBlue};
  font-size: 13px;
  color: ${({theme}) => theme.white};
  .footer-panels {
    /* justify-content: center; */
    padding: 0px 7% 0 3%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .footer-panel {
      margin: 30px 10px;
      width: 350px;
      &__title {
        font-size: 17px;
        color: ${({theme}) => theme.white};
      }
      &__title-line {
        border-bottom: 2px solid ${({theme}) => theme.orange};
        width: 100%;
        margin: 10px 0;
      }
      &__subtitle {
        font-weight: bold;
        font-size: 12px;
        padding: 3px 0;
        color: ${({theme}) => theme.orange};
      }
      &__subtitle-divided {
        display: flex;
        justify-content: space-between;
      }
      &__text {
        font-size: 12px;
        margin: 3px 0;
        color: ${({theme}) => theme.white};
        line-break: anywhere;
      }
      &__img {
        height: 50px;
        padding-left: 35px;
        margin: auto;
        display: flex;
      }
      &__social-networks {
        display: flex;
        align-items: center;
        justify-content: center;
        &-icons {
          display: flex;
          border-bottom: 1px solid ${({theme}) => theme.orange};
          padding: 5px 0;
          i {
            margin-right: 3px;
            cursor: pointer;
          }
          margin-right: 4px;
        }
        &-at {
          font-family: 'Poppins Light';
          font-size: 13px;
        }
      }
      .politics {
        margin-top: 40px;
      }
    }
  }
  .footer-bottom {
    background-color: ${({theme}) => theme.darkBlue};
    padding: 10px;
    text-align: center;
  }
`;
