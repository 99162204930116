import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./Profile.style";

// Form
import * as Yup from "yup";
import { Field, FieldProps, Formik, FormikHelpers, useFormikContext } from "formik";

// Components
import Button from "../../shared/button";
import Tooltip from "../../shared/tooltip";
import InputForm from "../../shared/inputForm";
import Portal from "../../shared/modals/portal";
import SelectForm from "../../shared/selectForm";
import CustomModal from "../../shared/modals/customModal";
import ModalContainer from "../../shared/modals/modalContainer";

// Apollo
import { useMutation, useQuery } from "@apollo/client";
import { QUERY_GET_PAISES } from "../../../graphql/queries/Pais";
import { QUERY_GET_DEPARTAMENTOS } from "../../../graphql/queries/Department";
import { QUERY_GET_CIUDADES_BY_DEPARTAMENTO, QUERY_GET_CIUDADES_BY_PAIS } from "../../../graphql/queries/Ciudad";
import { MUTATION_UPDATE_PROFILE } from "../../../graphql/mutations/User";

// Utils
import { CONDICION_DISCAPACIDAD, CONDITIONS, SEX_TYPE, TYPE_DOCUMENTS } from "../../../utils/constants";

// Models
import { ObjectShape } from "yup/lib/object";
import { Pais } from "../../../models/dbModels/Pais";
import { Ciudad } from "../../../models/dbModels/Ciudad";
import { SelectItem } from "../../../models/logicModels/SelectItem";
import { Departamento } from "../../../models/dbModels/Departamento";

// Hooks
import useUserData from "../../../hooks/useUserData";
import { MUTATION_CHANGE_PASSWORD } from "../../../graphql/mutations/Auth";
import { CommunityType } from "../../../models/dbModels/CommunityType";
import { QUERY_GET_PMCCOMMUNITY } from "../../../graphql/queries/CommunityType";

interface  ProfileFields {
  name1: string,
  lastname1: string | null,
  name2: string,
  lastname2: string | null,
  typeDocument: string,
  razonSocial: string | null,
  document: string,
  birthdate: string | null,
  sex: string,
  localPhone: string,
  phone: string,
  address: string,
  country: string,
  department: string,
  city?: number,
  country2: string,
  city2?: number,
  comunidadId: number | null,
  department2: string,
  conditionsQuestion: string,
  condicionDiscapacidad: string | null,
  email: string,
}

interface  passChangeFields {
  oldPassword: string,
  newPassword: string,
  newPasswordConfirmation: string
}

function Profile() {

  const [resMessage, setResMessage] = useState<string>('');
  const {t} = useTranslation("global");
  const [formValidations, setFormValidations] = useState<ObjectShape>();
  const [ profileEditable, setProfileEditable ] = useState<boolean>(true);
  const { userData } = useUserData();    

  const [updateProfile, { loading: updateProfileLoading, error: updateProfileError, reset: updateProfileReset}] = useMutation(MUTATION_UPDATE_PROFILE);
  const [changePass, { loading: changePassLoading, error: changePassError, reset: changePassReset}] = useMutation(MUTATION_CHANGE_PASSWORD);

  const initialFormValidations: ObjectShape = {
    name1: Yup.string()
      .min(3, t("validation.min"))
      .max(15, t("validation.max"))
      .required(t("validation.required"))
      .matches(/^[ÑáéíóúÁÉÍÓÚñA-Za-z _]*[ÑáéíóúÁÉÍÓÚñA-Za-z][ÑáéíóúÁÉÍÓÚñA-Za-z _]*$/, t("validation.onlyLetter")),
    lastname1: Yup.string()
      .min(3, t("validation.min"))
      .max(15, t("validation.max"))
      .required(t("validation.required"))
      .matches(/^[ÑáéíóúÁÉÍÓÚñA-Za-z _]*[ÑáéíóúÁÉÍÓÚñA-Za-z][ÑáéíóúÁÉÍÓÚñA-Za-z _]*$/, t("validation.onlyLetter")),
    name2: Yup.string()
      .min(3, t("validation.min"))
      .max(15, t("validation.max"))
      .matches(/^[ÑáéíóúÁÉÍÓÚñA-Za-z _]*[ÑáéíóúÁÉÍÓÚñA-Za-z][ÑáéíóúÁÉÍÓÚñA-Za-z _]*$/, t("validation.onlyLetter")),
    lastname2: Yup.string()
      .min(3, t("validation.min"))
      .max(15, t("validation.max"))
      .matches(/^[ÑáéíóúÁÉÍÓÚñA-Za-z _]*[ÑáéíóúÁÉÍÓÚñA-Za-z][ÑáéíóúÁÉÍÓÚñA-Za-z _]*$/, t("validation.onlyLetter")),
    typeDocument: Yup.string()
      .required(t("validation.required")),
    document: Yup.string()
      .required(t("validation.required")),
    razonSocial: Yup.string(),
    birthdate: Yup.string()
      .required(t("validation.required")),
    sex: Yup.string()
      .required(t("validation.required")),
    localPhone: Yup.string()
      .max(13, t("validation.13"))
      .required(t("validation.required")),
    phone: Yup.string()
      .required(t("validation.required")),
    address: Yup.string()
      .required(t("validation.required")),
    conditionsQuestion: Yup.string()
      .required(t("validation.required")),
    country: Yup.string()
      .required(t("validation.required")),
    city: Yup.string()
      .required(t("validation.required")),
    country2: Yup.string()
      .required(t("validation.required")),
    city2: Yup.string()
      .required(t("validation.required")),
    comunidadId: Yup.string()
      .required(t("validation.required")),
    email: Yup.string()
      .email(t("validation.email"))
      .required(t("validation.required")),
    
    condicionDiscapacidad: Yup.string().when('conditionsQuestion', {
      is: 'Personas con Discapacidad',
      then: Yup.string().required('Este campo es obligatorio.'),
      otherwise: Yup.string()
    }),
  }

  const formPassChangeValidations: ObjectShape = {
    oldPassword: Yup.string()
      .required(`${t("validation.password")}`),
    newPassword: Yup.string()
      .required(`${t("validation.password")}`)
        .matches(
          /^(?=.*?[A-ZÁ-Ú])(?=.*?[a-zá-ú])(?=.*?\d).{6,}$/,
          `${t("validation.matches")}`
        ),
    newPasswordConfirmation: Yup.string()
      .min(3, `${t("validation.min")}`)
      .required(`${t("validation.confirmPass")}`)
      .when("newPassword", {
        is: (newPassword: string) => (newPassword && (newPassword.length > 0)),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          `${t("validation.match")}`
        ),
      }),
    }

    useEffect(() => {
      setFormValidations(initialFormValidations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      setFormValidations(initialFormValidations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileEditable])

    const handlePassChangeSubmit = async (values: passChangeFields, formikHelpers: FormikHelpers<passChangeFields>) => {
    
    try {
      const {
        data: {
          cambiarContrasena: { message },
        },
      } = await changePass({
        variables: {
          contrasena: values.oldPassword,
          nuevaContrasena: values.newPassword
        },
      });
      setResMessage(message);
      formikHelpers.resetForm();
    } catch (err: any) {
      formikHelpers.resetForm();
      setResMessage(err.message);
    }
  }

  const handleSubmit = async (values: ProfileFields, formikHelpers: FormikHelpers<ProfileFields>) => {
    if(
      values.localPhone !== userData?.telefono ||
      values.phone !== userData?.celular ||
      values.sex !== userData?.sexo ||
      values.conditionsQuestion !== userData?.discapacidad ||
      values.address !== userData?.direccion ||
      values.city2 !== userData?.ciudadResidenciaId?.toString() ||
      values.city !== userData?.ciudadnacimientoid?.toString() ||
      !userData?.fechaNacimiento ||
      values.comunidadId !== userData.comunidadId ||
      values.condicionDiscapacidad !== userData.condicionDiscapacidad ||
      values.razonSocial !== userData.razonSocial ||
      values.document !== userData.numerodni
    ) {
      let tempDataUpdated: any = {};
      if(values.localPhone !== userData?.telefono)
        tempDataUpdated.telefono = values.localPhone.toString();
      if(values.phone !== userData?.celular)
        tempDataUpdated.celular = values.phone.toString();
      if(values.sex !== userData?.sexo)
        tempDataUpdated.sexo = values.sex;
      if(values.conditionsQuestion !== userData?.discapacidad)
        tempDataUpdated.discapacidad = values.conditionsQuestion;
      if(values.address !== userData?.direccion)
        tempDataUpdated.direccion = values.address;
      if(values.city2 !== userData?.ciudadResidenciaId?.toString())
        tempDataUpdated.ciudadResidenciaId = values.city2;
      if(!userData?.ciudadnacimientoid)
        tempDataUpdated.ciudadnacimientoid = values.city;
      if(!userData?.fechaNacimiento)
        tempDataUpdated.fechaNacimiento = values.birthdate ? `${new Date(values.birthdate).getFullYear()}-${new Date(values.birthdate).getMonth()}-${new Date(values.birthdate).getDate()}`.trim() : null;
      if(values.comunidadId !== userData?.comunidadId)
        tempDataUpdated.comunidadId = values.comunidadId;
      if(values.condicionDiscapacidad !== userData?.condicionDiscapacidad)
        tempDataUpdated.condicionDiscapacidad = values.condicionDiscapacidad
      if(values.razonSocial !== userData?.razonSocial)
        tempDataUpdated.razonSocial = values.razonSocial
      if(values.conditionsQuestion !== "Personas con Discapacidad" && userData?.condicionDiscapacidad) {
        tempDataUpdated.condicionDiscapacidad = null
      }
      if(values.document !== userData?.numerodni)
        tempDataUpdated.numerodni = values.document.toString();
      try {
        await updateProfile({
          variables: {
            id: userData?.id,
            dataUpdated: tempDataUpdated
          },
        });
        setResMessage("Sus datos fueron actualizados");
        setProfileEditable(!profileEditable);
      } catch (err: any) {
        setResMessage("Error actualizando sus datos");
      }
    } else {
      setProfileEditable(!profileEditable);
      setResMessage("Sus datos fueron actualizados");
    }
  }

  const registerFormInitialValues: ProfileFields = {
    name1: '',
    lastname1: '',
    name2: '',
    lastname2: '',
    typeDocument: '',
    document: '',
    razonSocial: '',
    birthdate: '',
    sex: '',
    localPhone: '',
    phone: '',
    address: '',
    country: '',
    department: '',
    country2: '',
    department2: '',
    comunidadId: 1,
    conditionsQuestion: '',
    condicionDiscapacidad: '',
    email: '',
  }
  
  const passChangeFormInitialValues: passChangeFields = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
  }

  const cleanErrors = () => {
    updateProfileReset();
    changePassReset();
    setResMessage("");
  }


  return (
    <S.Profile>
      {
        (
          updateProfileLoading || 
          updateProfileError || 
          changePassLoading ||
          changePassError ||
          resMessage
        ) &&
        <Portal>
          <ModalContainer>
            <CustomModal
              title={
                (updateProfileLoading || changePassLoading)
                ? "Cargando"
                : (updateProfileError || changePassError)
                  ? "Error"
                  : "información validada"
              }
              description={
                (updateProfileLoading || changePassLoading)
                ? ""
                : resMessage
              }
              type={
                (updateProfileLoading || changePassLoading)
                ? "loading"
                : (updateProfileError || changePassError)
                  ? "failed"
                  : "success"
                }
              action={cleanErrors}
            />
          </ModalContainer>
        </Portal>
      }
      <div className="profile">
        <div className="profile__form-container">
          {/* {
            registerLoading
            ? ''
            : <div className="response-message" style={registerError || !validCaptcha ? {"color": "red"} : {}}>
              {errorCaptcha === true ? "Error de Captcha" : resMessage}
            </div>
          } */}
          <Formik
            initialValues={registerFormInitialValues}
            enableReinitialize={true}
            validationSchema={Yup.object(formValidations)}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <form className="profile-form" onSubmit={ props.handleSubmit }>
                <div className="profile__title">Bienvenido {userData?.primerNombre + " " + userData?.primerApellido}</div>
                <div className="profile__button-update-profile" onClick={() => profileEditable && setProfileEditable?.(!profileEditable) } >
                  <Button
                    type={"button"}
                    text={"Editar perfil"}
                    color={profileEditable ? '' : "#afafaf"}
                    fontSize={12}
                  />
                </div>
                <RegisterForm
                  formValidations={formValidations}
                  setFormValidations={setFormValidations}
                  initialFormValidations={initialFormValidations}
                  profileEditable={profileEditable}
                  setProfileEditable={setProfileEditable}
                />
                <div className="button-container">
                  <Button
                    type={updateProfileLoading || profileEditable ? "button" : "submit"}
                    color={!profileEditable ? '' : "#afafaf"}
                    fontSize={15}
                    text={
                      !updateProfileLoading
                      ? t("profile.button")
                      : `${t("global.loading")}...`
                    }
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="profile__form-container">
          <Formik
            initialValues={passChangeFormInitialValues}
            enableReinitialize={true}
            validationSchema={Yup.object(formPassChangeValidations)}
            onSubmit={handlePassChangeSubmit}
          >
            {(props) => (
              <form className="profile-form" onSubmit={ props.handleSubmit }>
                <ChangePassForm/>
                <div className="button-container">
                  <Button
                    type={updateProfileLoading ? "button" : "submit"}
                    fontSize={15}
                    text={
                      !updateProfileLoading
                      ? t("profile.buttonUpdatePass")
                      : `${t("global.loading")}...`
                    }
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </S.Profile>
  );
}

interface PropsProfileForm {
  formValidations?: ObjectShape,
  setFormValidations?: Dispatch<SetStateAction<ObjectShape | undefined>>,
  initialFormValidations?: ObjectShape,
  profileEditable?: boolean,
  setProfileEditable?: Dispatch<SetStateAction<boolean>>
}

function RegisterForm({
  formValidations,
  setFormValidations,
  initialFormValidations,
  profileEditable,
  setProfileEditable
}: Readonly<PropsProfileForm>) {

  const {t} = useTranslation("global");

  const { values } = useFormikContext<ProfileFields>();
  const { setFieldValue } = useFormikContext();
  const { userData } = useUserData();  

  const isCountryColombia = useMemo(() => {
    return values.country === "52"
  }, [values.country])

  const isCountryColombia2 = useMemo(() => {
    return values.country2 === "52"
  }, [values.country2])

  const { data: communityType } = useQuery(QUERY_GET_PMCCOMMUNITY);

  const {
    data: paisesdata,
  } = useQuery (
    QUERY_GET_PAISES,
  );

  const {
    data: departamentosdata,
  } = useQuery (
    QUERY_GET_DEPARTAMENTOS,
  );

  const {
    data: ciudadesdata,
  } = useQuery (
    isCountryColombia
    ? QUERY_GET_CIUDADES_BY_DEPARTAMENTO
    : QUERY_GET_CIUDADES_BY_PAIS,
    {
      variables: isCountryColombia
      ? {
        departamentoId: values.department
      }
      : {
        paisId: values.country
      }
    }
  );

  const {
    data: ciudadesdata2,
  } = useQuery (
    isCountryColombia2
    ? QUERY_GET_CIUDADES_BY_DEPARTAMENTO
    : QUERY_GET_CIUDADES_BY_PAIS,
    {
      variables: isCountryColombia2
      ? {
        departamentoId: values.department2
      }
      : {
        paisId: values.country2
      }
    }
  );

  useEffect(() => {    
    setFieldValue('name1', userData?.primerNombre ?? "")
    setFieldValue('lastname1', userData?.primerApellido ?? "")
    setFieldValue('name2', userData?.segundoNombre ?? "")
    setFieldValue('lastname2', userData?.segundoApellido ?? "")
    setFieldValue('typeDocument', userData?.tipoDni ?? "")
    setFieldValue('document', userData?.numerodni ?? "")
    setFieldValue('birthdate', userData?.fechaNacimiento ?? "")
    setFieldValue('email', userData?.email ?? "")    
    setFieldValue('sex', userData?.sexo ?? "")
    setFieldValue('localPhone', userData?.telefono ?? "")
    setFieldValue('phone', userData?.celular ?? "")
    setFieldValue('address', userData?.direccion ?? "")
    setFieldValue('conditionsQuestion', userData?.discapacidad ?? "")
    setFieldValue('razonSocial', userData?.razonSocial ?? "")
    setFieldValue('condicionDiscapacidad', userData?.condicionDiscapacidad ?? "")
    setFieldValue('comunidadId', userData?.comunidadId ?? null)
    if(userData?.pmcciudad) {
      setFieldValue('country', userData?.pmcciudad.pmcpai ? userData?.pmcciudad.pmcpai.id.toString() : "")
      setFieldValue('department', userData?.pmcciudad.pmcdepartamento ? userData?.pmcciudad.pmcdepartamento.id.toString() : "")
      setFieldValue('city', userData?.ciudadnacimientoid ? userData?.ciudadnacimientoid.toString() : "")

    }
    if(userData?.pmcciudadByCiudadresidenciaid) {
      setFieldValue('country2', userData?.pmcciudadByCiudadresidenciaid.pmcpai ? userData?.pmcciudadByCiudadresidenciaid.pmcpai.id.toString() : "")
      setFieldValue('department2', userData?.pmcciudadByCiudadresidenciaid.pmcdepartamento ? userData?.pmcciudadByCiudadresidenciaid.pmcdepartamento.id.toString() : "")
      setFieldValue('city2', userData?.ciudadResidenciaId ? userData?.ciudadResidenciaId.toString() : "")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  const setInitialValidation = () => {
    setFormValidations?.(initialFormValidations!);
  }

  const setDepartmentValidation = () => {
    const departmentValidation: ObjectShape = {
      department: Yup.string()
      .required(t("validation.required"))
    }
    const validations = initialFormValidations;
    const mergedValidations = {...validations, ...departmentValidation}
    setFormValidations?.(mergedValidations);
  }

  const setDepartment2Validation = () => {
    const department2Validation: ObjectShape = {
      department2: Yup.string()
      .required(t("validation.required"))
    }
    const validations = formValidations;
    const mergedValidations = {...validations, ...department2Validation}
    setFormValidations?.(mergedValidations);
  }


  useEffect(() => {
    if(isCountryColombia) {
      setDepartmentValidation();
    } else {
      setInitialValidation();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.country])

  useEffect(() => {
    if(isCountryColombia2) {
      setDepartment2Validation();
    } else {
      setInitialValidation();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.country2])

  useEffect(() => {
    if(isCountryColombia) {
      let isInList = departamentosdata?.pmcdepartamento?.find((departamento: Pais) => departamento.id.toString() === values.department.toString());

      if(!isInList) {
        setFieldValue('department', "")
        setFieldValue('city', "")
      }
    }
    if(isCountryColombia2) {
      let isInList = departamentosdata?.pmcdepartamento?.find((departamento: Pais) => departamento.id.toString() === values.department2.toString());

      if(!isInList) {
        setFieldValue('department2', "")
        setFieldValue('city2', "")
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departamentosdata])

  useEffect(() => {
    if(values.city) {
      let isInList =  ciudadesdata?.pmcciudad?.find((ciudad: Ciudad) => ciudad.id.toString() === values.city!.toString());
  
      if(!isInList ) {      
        setFieldValue('city', "")
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ciudadesdata])

  useEffect(() => {
    if(values.city2) {
        let isInList =  ciudadesdata2?.pmcciudad?.find((ciudad: Ciudad) => ciudad.id.toString() === values.city2!.toString());

      if(!isInList) {
        setFieldValue('city2', "")
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ciudadesdata2])

  return (
    <div className="inputs-container">
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.name1")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.name1")}
            />
          </div>
        </div>
        <Field name="name1">
          {(props: FieldProps) => (
            <InputForm 
              type="text"
              {...props}
              disabled
            />  
          )}
        </Field>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.name2")}
          </span>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.name2")}
            />
          </div>
        </div>
        <Field name="name2">
          {(props: FieldProps) => (
            <InputForm 
              type="text"
              {...props}
              disabled
            />  
          )}
        </Field>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.lastname1")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.lastname1")}
            />
          </div>
        </div>
        <Field name="lastname1">
          {(props: FieldProps) => (
            <InputForm 
              type="text"
              {...props}
              disabled
            />  
          )}
        </Field>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.lastname2")}
          </span>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.lastname2")}
            />
          </div>
        </div>
        <Field name="lastname2">
          {(props: FieldProps) => (
            <InputForm 
              type="text"
              {...props}
              disabled
            />  
          )}
        </Field>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.typeDocument")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
        <Field name="typeDocument">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              items={TYPE_DOCUMENTS}
              disabled
            />  
          )}
        </Field>
        </div>
      </div>
      {
        values.typeDocument === "NIT" &&
        <div className="input-content">
          <div className="input-label">
            <span>{t("register.razonSocial")}</span>
          </div>
          <div className="double-input">
            <Field name="razonSocial">
              {(propsInput: FieldProps) => (
                <InputForm
                  type="text"
                  {...propsInput}
                  disabled={profileEditable}
                />
              )}
            </Field>
          </div>
        </div>
      }
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.document")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.document")}
            />
          </div>
        </div>
        <div className="double-input">
          <Field name="document">
            {(props: FieldProps) => (
              <InputForm 
              type={values.typeDocument === "Pasaporte"  || values.typeDocument === "Documento extranjero"  ? "text" : "number"}
                {...props}
                disabled={( !profileEditable )? values.typeDocument !== "NIT" : true }
              />  
            )}
          </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.birthdate")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
        <Field name="birthdate">
          {(props: FieldProps) => (
            <InputForm 
              type="date"
              {...props}
              disabled={profileEditable || Boolean(userData?.fechaNacimiento)}
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.email")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.email")}
            />
          </div>
        </div>
        <div className="double-input">
        <Field name="email">
          {(props: FieldProps) => (
            <InputForm 
              type="text"
              {...props}
              disabled
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.sex")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
        <Field name="sex">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              items={SEX_TYPE}
              disabled={profileEditable}
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.localPhone")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.localPhone")}
            />
          </div>
        </div>
        <div className="double-input">
        <Field name="localPhone">
          {(props: FieldProps) => (
            <InputForm 
              type="number"
              {...props}
              disabled={profileEditable}
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.phone")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.phone")}
            />
          </div>
        </div>
        <div className="double-input">
        <Field name="phone">
          {(props: FieldProps) => (
            <InputForm 
              type="number"
              {...props}
              disabled={profileEditable}
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.address")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.address")}
            />
          </div>
        </div>
        <div className="double-input">
        <Field name="address">
          {(props: FieldProps) => (
            <InputForm 
              type="text"
              {...props}
              disabled={profileEditable}
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.conditionsQuestion")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <Field name="conditionsQuestion">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              items={CONDITIONS}
              disabled={profileEditable}
            />  
          )}
        </Field>
      </div>

      {values.conditionsQuestion === 'Personas con Discapacidad' &&
        <div className="input-content">
          <div className="input-label">
            <span>
              {t("createPqrs.condicionDiscapacidad")}
            </span>
            <div className="required-indicator">*</div>
          </div>
          <Field name="condicionDiscapacidad">
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                items={CONDICION_DISCAPACIDAD}
                disabled={profileEditable}
              />
            )}
          </Field>
        </div>
      }

      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.country")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
        <Field name="country">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              disabled={profileEditable || Boolean(userData?.ciudadnacimientoid)}
              items={
                paisesdata?.pmcpais.map((pais: Pais) => {
                  const paisItem: SelectItem = {
                    value: pais.id.toString(),
                    text: pais.pais
                  }
                  return(paisItem);
                })
              }
            />  
          )}
        </Field>
        </div>
      </div>
      {
        isCountryColombia &&
        <div className="input-content">
          <div className="input-label">
            <span>
              {t("createPqrs.department")}
            </span>
            <div className="required-indicator">*</div>
          </div>
          <div className="double-input">
          <Field name="department">
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                items={
                  departamentosdata?.pmcdepartamento.map((departamento: Departamento) => {
                    const departamentoItem: SelectItem = {
                      value: departamento.id.toString(),
                      text: departamento.departamento
                    }
                    return(departamentoItem);
                  })
                }
                disabled={profileEditable || Boolean(userData?.ciudadnacimientoid)}
              />  
            )}
          </Field>
          </div>
        </div>
      }
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.city")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
        <Field name="city">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              disabled={profileEditable || Boolean(userData?.ciudadnacimientoid)}
              items={
                ciudadesdata?.pmcciudad.map((ciudad: Ciudad) => {
                  const ciudadItem: SelectItem = {
                    value: ciudad.id.toString(),
                    text: ciudad.ciudad
                  }
                  return(ciudadItem);
                })
              }
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.country2")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
        <Field name="country2">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              items={
                paisesdata?.pmcpais.map((pais: Pais) => {
                  const paisItem: SelectItem = {
                    value: pais.id.toString(),
                    text: pais.pais
                  }
                  return(paisItem);
                })
              }
              disabled={profileEditable}
            />  
          )}
        </Field>
        </div>
      </div>
      {
        isCountryColombia2 &&
        <div className="input-content">
          <div className="input-label">
            <span>
              {t("createPqrs.department2")}
            </span>
            <div className="required-indicator">*</div>
          </div>
          <div className="double-input">
          <Field name="department2">
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                items={
                  departamentosdata?.pmcdepartamento.map((departamento: Departamento) => {
                    const departamentoItem: SelectItem = {
                      value: departamento.id.toString(),
                      text: departamento.departamento
                    }
                    return(departamentoItem);
                  })
                }
                disabled={profileEditable}
              />  
            )}
          </Field>
          </div>
        </div>
      }
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.city2")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
        <Field name="city2">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              disabled={
                profileEditable ||
                (( 
                  isCountryColombia2
                  && values.department2 === ""
                ) 
                || values.country2 === "")
              }
              items={
                ciudadesdata2?.pmcciudad.map((ciudad: Ciudad) => {
                  const ciudadItem: SelectItem = {
                    value: ciudad.id.toString(),
                    text: ciudad.ciudad
                  }
                  return(ciudadItem);
                })
              }
            />  
          )}
        </Field>
        </div>
      </div>
      <div className="input-content">
          <div className="input-label">
            <span>
              {t("createPqrs.communityType")}
            </span>
            <div className="required-indicator">*</div>
          </div>
          <div className="double-input">
            <Field name="comunidadId">
              {(props: FieldProps) => (
                <SelectForm
                  {...props}
                  items={
                    communityType?.pmccomunidad.map((comunity: CommunityType) => {
                      const data: SelectItem = {
                        value: comunity.id,
                        text: comunity.nombreComunidad
                      }
                      return (data);
                    })
                  }
                  disabled={profileEditable}
                />
              )}
            </Field>
          </div>
        </div>
    </div>
  )
}

function ChangePassForm() {

  const {t} = useTranslation("global");

  const { values } = useFormikContext<ProfileFields>();

  return (
    <div className="inputs-container">
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("profile.oldPass")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.name1")}
            />
          </div>
        </div>
        <Field name="oldPassword">
          {(props: FieldProps) => (
            <InputForm 
              type="password"
              {...props}
            />  
          )}
        </Field>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("profile.newPass")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.name2")}
            />
          </div>
        </div>
        <Field name="newPassword">
          {(props: FieldProps) => (
            <InputForm 
              type="password"
              {...props}
            />  
          )}
        </Field>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("profile.newPassConfirmation")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.lastname1")}
            />
          </div>
        </div>
        <Field name="newPasswordConfirmation">
          {(props: FieldProps) => (
            <InputForm 
              type="password"
              {...props}
            />  
          )}
        </Field>
      </div>
    </div>
  )
}
export default Profile;
