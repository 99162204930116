export const ROUTES = {
  START: {
    route: "/",
  },
  LOGIN: {
    route: "/login",
  },
  REGISTER: {
    route: "/register",
  },
  RESET_PASSWORD: {
    route: "/reset-pass",
  },
  CONSULT_PQRS: {
    route: "/consult-pqrs", 
  },
  CREATE_PQRS: {
    route: "/create-pqrs", 
  },
  SESSION_OPTIONS: {
    route: "/session-options", 
  }, 
  CREATE_PQRS_ANONYMOUS: {
    route: "/create-pqrs-anonymous"
  },
  PQRS_LIST: {
    route: "/pqrs-list"
  },
  PROFILE: {
    route: "/profile"
  },
  EMAIL_RECOVERY: {
    route: "/email-recovery"
  }
};

export const REQUEST_TYPE = [
  {
    value: 'Recibida',
    text: 'Recibida'
  },
  {
    value: 'Solucionada',
    text: 'Solucionada'
  },
  {
    value: 'Rechazada',
    text: 'Rechazada'
  },
  {
    value: 'Asignada',
    text: 'Asignada'
  },
  {
    value: 'Incompleta',
    text: 'Incompleta'
  }
]

export const TYPE_DOCUMENTS = [
  {
    value: 'Cédula de Ciudadanía',
    text: 'Cédula de Ciudadanía'
  },
  {
    value: 'Cédula de Extranjería',
    text: 'Cédula de Extranjería'
  },
  {
    value: 'NIT',
    text: 'NIT'
  },
  {
    value: 'Pasaporte',
    text: 'Pasaporte'
  },
  {
    value: 'Permiso Especial de Permanencia',
    text: 'Permiso Especial de Permanencia'
  },
  {
    value: 'Registro civil de nacimiento',
    text: 'Registro civil de nacimiento'
  },
  {
    value: 'Tarjeta de Identidad',
    text: 'Tarjeta de Identidad'
  },
  {
    value: 'Permiso por Protección Temporal (PPT)',
    text: 'Permiso por Protección Temporal (PPT)'
  },   
  {
    value: 'Documento extranjero',
    text: 'Documento extranjero'
  },   
];

export const INTEREST_GROUP = [
  {
    value: 'En nombre propio (Persona natural)',
    text: 'En nombre propio (Persona natural)'
  },
  {
    value: 'En representación de (Persona Jurídica)',
    text: 'En representación de (Persona Jurídica)'
  },
  {
    value: 'En representación de un tercero (Persona natural)',
    text: 'En representación de un tercero (Persona natural)'
  },
]

export const SUB_INTEREST_GROUP = [
  {
    value: 'Servidor Público',
    text: 'Servidor Público'
  },
  {
    value: 'Entidades del Estado',
    text: 'Entidades del Estado'
  },
  {
    value: 'Medios de Comunicación',
    text: 'Medios de Comunicación'
  },
  {
    value: 'Comunidad Internacional',
    text: 'Comunidad Internacional'
  },
  {
    value: 'Instituciones educativas',
    text: 'Instituciones educativas'
  },  
  {
    value: 'Empresa privada',
    text: 'Empresa privada'
  },  
]

export const CONDITIONS = [
  {
    value: 'Adulto Mayor',
    text: 'Adulto Mayor'
  },
  {
    value: 'Personas con Discapacidad',
    text: 'Personas con Discapacidad'
  },
  {
    value: 'Niños, niñas y adolescentes',
    text: 'Niños, niñas y adolescentes'
  },
  {
    value: 'Embarazada',
    text: 'Embarazada'
  },
  {
    value: 'Ninguna',
    text: 'Ninguna'
  },
]

export const CONDICION_DISCAPACIDAD = [
  {
    value: 'Física',
    text: 'Física'
  },
  {
    value: 'Visual',
    text: 'Visual'
  },
  {
    value: 'Intelectual',
    text: 'Intelectual'
  },
  {
    value: 'Auditiva',
    text: 'Auditiva'
  },
  {
    value: 'Sordoceguera',
    text: 'Sordoceguera'
  },
  {
    value: 'Multiple',
    text: 'Multiple'
  },
  {
    value: 'Psicosocial',
    text: 'Psicosocial'
  },
]

export const SEX_TYPE = [
  {
    value: 'Masculino',
    text: 'Masculino'
  },
  {
    value: 'Femenino',
    text: 'Femenino'
  },
  {
    value: 'Otro',
    text: 'Otro'
  }
]