import { gql } from "@apollo/client";

export const QUERY_GET_USER = gql`
  query QUERY_GET_USER (
    $userId: Int!
  ) {
    pmccontacto(
      where: { id: { _eq: $userId } }
    ) {
      id
      primerApellido
      primerNombre
      segundoNombre
      segundoApellido
      barrio
      celular
      ciudadResidenciaId
      ciudadnacimientoid
      direccion
      discapacidad
      email
      esVeeduria
      fechaNacimiento
      fechaTokenGenerado
      interes
      nacionalidad
      numerodni
      preguntaSecreta1
      respuestaSecreta1
      sexo
      telefono
      tipoDni
      condicionDiscapacidad
      razonSocial
      comunidadId
      pmcciudadByCiudadresidenciaid {
        pmcdepartamento {
          id
          departamento
        }
        pmcpai {
          id
          pais
        }
      }
      pmcciudad {
        pmcdepartamento {
          departamento
          id
        }
        pmcpai {
          id
          pais
        }
      }
    }
  }
`