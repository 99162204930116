import { Field, FieldProps, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useRef, useState } from "react";
import Button from "../../shared/button";
import * as S from "./ConsultPQRS.style";
import { useTranslation } from "react-i18next";
import InputForm from "../../shared/inputForm";
import { useLazyQuery } from "@apollo/client";
import { CONSULT_PQRS } from "../../../graphql/queries/Pqrs";
import { Pqrs } from "../../../models/dbModels/Pqrs";
import * as P from "../../../utils/pipes";
import ReCAPTCHA from "react-google-recaptcha";
import Portal from "../../shared/modals/portal";
import ModalContainer from "../../shared/modals/modalContainer";
import CustomModal from "../../shared/modals/customModal";

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

function ConsultPQRS() {

  const pqrsInfoDefault: Pqrs = {
    id: null,
    actualizadoEn: '',
    agregadoEn: '',
    comentario: '',
    estado: '',
    peticion: '',
    sla: null,
    numeroRadicado: '',
  }

  const {t} = useTranslation("global");
  const [resMessage, setResMessage] = useState<string>('');
  const [errorCaptcha, setErrorCaptcha] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>()
  const [pqrsInfo, setPqrsInfo] = useState<Pqrs>(pqrsInfoDefault);
  const [pqrsId, setPqrsId] = useState<string | null>();
  const consultPqrsCaptchaRef = useRef<any>(null);
  


  const [consultPqrs, { loading: consultPqrsLoading }] 
  = useLazyQuery (
    CONSULT_PQRS,
    {
      onCompleted: (data) => {   
        setPqrsInfo(data.pqrsPorNumeroRadicado);
        setPqrsId(data.pqrsPorNumeroRadicado.numeroRadicado);
        setResMessage('');
      },
      onError: error => {
        console.log(error);
        
        setErrors(error);
        setResMessage('Ocurrio un error con la consulta, intentelo de nuevo');
        consultPqrsCaptchaRef.current.reset();
      },
      fetchPolicy: "network-only"
    }
  );

  interface  ConsultPqrsFields {
    settledNumber?: string
  }

  const handleSubmit = async (values: ConsultPqrsFields, formikHelpers: FormikHelpers<ConsultPqrsFields>) => {
    if (consultPqrsCaptchaRef.current.getValue()) {
      setErrorCaptcha(false);
      try {
        consultPqrs({
          variables: {
            numeroRadicado: values.settledNumber,
            captchaToken: consultPqrsCaptchaRef.current.getValue()
          }
        })
        formikHelpers.resetForm();
      } catch (err) {
        console.log(err);
        
        formikHelpers.resetForm();
        consultPqrsCaptchaRef.current.reset();
      }
    } else {
      setErrorCaptcha(true);  
      setResMessage("Error de Captcha");
      consultPqrsCaptchaRef.current.reset();
    }
  }

  const cleanErrors = () => {
    setErrors(null);
    setResMessage("");
    setErrorCaptcha(false);
  }

  const consultFormInitialValues: ConsultPqrsFields = {
    settledNumber: ''
  }

  return (
    <S.ConsultPQRS>
      {
        (consultPqrsLoading || errors || errorCaptcha) && 
        <Portal>
          <ModalContainer>
            <CustomModal
              title={consultPqrsLoading ? "Cargando" : "Error"}
              description={
                consultPqrsLoading 
                ? "" 
                : resMessage
              }
              type={consultPqrsLoading ? "loading" : "failed"}
              action={cleanErrors}
            />
          </ModalContainer>
        </Portal>
      }
      <div className="consult">
        <div className="consult__left">
          <div className="box">
            <i className="box__icon icon icon-consultar-estado-pqrs"/>
            <div className="box__text">{t("consultPqrs.pretitle")}</div>
            <div className="box__text-big">{t("consultPqrs.title")}</div>
          </div>
        </div>
        <div className="consult__right">
          {
            pqrsId
            ? 
              <div className="pqrs-consulted">
                <div className="pqrs-consulted__form">
                  <div className="pqrs-consulted__title">
                    <i className="pqrs-consulted__title-icon icon icon-solicitud-radicada"></i>
                    <div className="pqrs-consulted__title-text">{t("consultPqrs.pqrsBoxInfo.title")} {pqrsInfo.estado}</div>
                  </div>
                  <div className="pqrs-consulted__info">
                    <div className="info-item">
                      <div className="info-item__label">{t("consultPqrs.pqrsBoxInfo.date")}</div>
                      <div className="info-item__data">{P.formatNumberDate(pqrsInfo.agregadoEn, true)}</div>
                    </div>
                    <div className="info-item">
                      <div className="info-item__label">{t("consultPqrs.pqrsBoxInfo.settled")}</div>
                      <div className="info-item__data">{pqrsInfo.numeroRadicado}</div>
                    </div>
                  </div>
                  <div className="pqrs-consulted__table">
                    <table>
                      <tr>
                        <th>{t("consultPqrs.pqrsBoxInfo.type")}</th>
                        <th>{t("consultPqrs.pqrsBoxInfo.terms")}</th>
                      </tr>
                      <tr>
                        <td>{pqrsInfo.peticion}</td>
                        <td>{pqrsInfo.sla} días hábiles contados a partir del día siguiente hábil a la recepción</td>
                      </tr>
                    </table>
                  </div>
                  <div className="pqrs-consulted__comment">
                    <div className="comment-box">
                      {pqrsInfo.comentario}
                    </div>
                  </div>
                  <div className="pqrs-consulted__info-laws">
                    <div className="info-laws__text">Según lo establecido por la Ley 2207 del 17 de mayo de 2022, se deroga el artículo 5 del Decreto Legislativo 491 de 2020, respecto a la ampliación de terminos para poder atender peticiones. A partir de la fecha se establecen los términos para atender las peticiones (PQRS) conforme a la Ley 1755 de 2015:</div>
                    <div className="info-laws__text">Adicionalmente, las consultas elevadas a la entidad en relación con las materias a su cargo se responderán dentro de los 30 días hábiles.</div>
                  </div>
                </div>
              <button className="pqrs-consulted__button" onClick={
                () => {
                  setPqrsId(null); 
                  setPqrsInfo(pqrsInfoDefault);
                  setErrorCaptcha(false);
                }
              }>
                Atrás
              </button>
            </div>
            : <> 
            <Formik
              initialValues={consultFormInitialValues}
              enableReinitialize={true}
              validationSchema={Yup.object({
                settledNumber: Yup.string()
                  .min(14, t("validation.min15"))
                  .max(21, t("validation.max20"))
                  .required(t("validation.required")),
              })}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <form className="consult-form" onSubmit={ props.handleSubmit }>
                  <div className="inputs-container">
                    <div className="input-content">
                      <div className="input-label">
                        <span>{t("consultPqrs.settledNumber")}</span>
                      </div>
                      <Field name="settledNumber">
                        {(props: FieldProps) => (
                          <InputForm 
                            type="text"
                            {...props}
                          />  
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="captcha-container">
                    {
                      // env !== 'test' && env !== 'development' &&
                      <ReCAPTCHA
                        sitekey={reCaptchaKey!}
                        ref={consultPqrsCaptchaRef}
                      />
                    }
                  </div>
                  <div className="button-container">
                    <Button 
                      type="submit"
                      text={t("consultPqrs.buttonText")}
                    />
                  </div>
                </form>
              )}
            </Formik>
            </>
          }
        </div>
      </div>
    </S.ConsultPQRS>
  );
}

export default ConsultPQRS;