import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';

export interface createPqrsState {
  isLoading: Boolean,
  createPqrsData?: any,
  createPqrsError?: any
}

const initialState: createPqrsState = {
  isLoading: false,
}

export const createPqrsSlice = createSlice({
  name: 'createPqrs',
  initialState,
  reducers: {
    createPqrs: (state, action: any) => {
      state.isLoading = true;
    },
    createPqrsSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.createPqrsData = action.payload;
      state.createPqrsError = '';
    },
    createPqrsFailure: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.createPqrsData = ''
      state.createPqrsError = action.payload.response.data;
    },
    createPqrsReset: (state) => {
      state.isLoading = false;
      state.createPqrsData = ''
      state.createPqrsError = '';
    }
  }
})

export const { createPqrs, createPqrsSuccess, createPqrsFailure, createPqrsReset } = createPqrsSlice.actions;

export default createPqrsSlice.reducer;