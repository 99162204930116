import { PqrsCreation } from '../models/logicModels/PqrsCreation';
import httpService from './httpService';

export async function createPqrs(data: PqrsCreation) {
  let url = `/managePqrs/newPqrs`
  return httpService.httpPost(url, data);
}

const pqrsServices = {
  createPqrs
}

export default pqrsServices;
