
import { gql } from "@apollo/client";

export const QUERY_GET_TIPO_DE_PETICIONES = gql`
query QUERY_GET_TIPO_DE_PETICIONES {
  pmctipopeticion(where: {disponible: {_eq: true}}) {
    descripcion
    id
    nombre
  }
}
`
