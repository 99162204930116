import * as S from "./EmailCodeVerificationForm.style";
import { useRef, useState, Dispatch, SetStateAction, useMemo } from "react";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { Field, FieldProps, Formik, FormikHelpers } from "formik";

// Components 
import InputForm from "../../../../shared/inputForm";
import Button from "../../../../shared/button";
import Portal from "../../../../shared/modals/portal";
import ModalContainer from "../../../../shared/modals/modalContainer";
import CustomModal from "../../../../shared/modals/customModal";

// Apollo
import { MUTATION_EMAIL_VALIDATION_TOKEN } from "../../../../../graphql/mutations/Auth";
import { useMutation } from "@apollo/client";

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

interface Props {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  setCodeActive: Dispatch<SetStateAction<boolean>>;
  setEmailVerified: Dispatch<SetStateAction<boolean>>;
}

function EmailCodeVerificationForm({
  email,
  setEmail,
  setCodeActive,
  setEmailVerified
}: Readonly<Props>) {

  const {t} = useTranslation("global");
  const [emailValidationToken, { loading: emailValidationTokenLoading, error: emailValidationTokenError, reset: emailValidationTokenReset}] = useMutation(MUTATION_EMAIL_VALIDATION_TOKEN);
  const [resMessage, setResMessage] = useState<string>('');
  const [errorCaptcha, setErrorCaptcha] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const emailRecoveryCaptchaRef = useRef<any>(null);

  interface  emailValidationTokenFields {
    email: string,
    token: string
  }
  
  const emailRecoveryFormInitialValues: emailValidationTokenFields = {
    email: email,
    token: ''
  }

  const handleSubmit = (values: emailValidationTokenFields, formikHelpers: FormikHelpers<emailValidationTokenFields>) => {
    if (emailRecoveryCaptchaRef.current.getValue()) {
      setErrorCaptcha(false);
      emailValidationTokenFunction(values, formikHelpers);
    } else {
      setErrorCaptcha(true);
      setResMessage("Error de Captcha");
      emailRecoveryCaptchaRef.current.reset();
    }
  }

  const emailValidationTokenFunction = async (values: emailValidationTokenFields, formikHelpers: FormikHelpers<emailValidationTokenFields>) => {
    try {
      const { data : {
        verificarEmailTokenRegistro: { message },
        }
      } 
      = await emailValidationToken({
        variables: {
          email: values.email.toString(),
          token: values.token.toString(),
          captchaToken: emailRecoveryCaptchaRef.current.getValue()
        },
      });

      setResMessage(message);
      formikHelpers.resetForm();
      setEmail(values.email);
      emailRecoveryCaptchaRef.current.reset();
    } catch (err: any) {
      setError(true);
      setResMessage(err.message);
      setErrorCaptcha(false);
      formikHelpers.resetForm();
      emailRecoveryCaptchaRef.current.reset();
    }
  }

  const cleanErrors = () => {
    registerRedirection();
    setError(false);
    emailValidationTokenReset();
    setResMessage("");
    setErrorCaptcha(false);
  }

  const registerRedirection = () => {
    if (emailValidationTokenError || errorCaptcha || error) {
      setEmailVerified(false);
    } else {
      setEmailVerified(true);
    }
  }

  const modalTitle = useMemo(() => {
    if (emailValidationTokenLoading) {
      return "Cargando";
    } else if (emailValidationTokenError || errorCaptcha || error) {
      return "Error";
    } else {
      return "Verificación correcta";
    }
  }, [emailValidationTokenLoading, emailValidationTokenError, errorCaptcha, error]);

  const modalDescription = useMemo(() => {
    if (emailValidationTokenLoading) {
      return "";
    } else {
      return resMessage;
    }
  }, [emailValidationTokenLoading, resMessage]);

  const modalType = useMemo(() => {
    if (emailValidationTokenLoading) {
      return "loading";
    } else if (emailValidationTokenError || errorCaptcha || error) {
      return "failed";
    } else {
      return "success";
    }
  }, [emailValidationTokenLoading, emailValidationTokenError, errorCaptcha, error]);

  return (
    <S.EmailCodeVerificationForm>
      {
        (emailValidationTokenLoading || emailValidationTokenError || errorCaptcha || resMessage || error) && 
        <Portal>
          <ModalContainer>
            <CustomModal
              title={modalTitle}
              description={modalDescription}
              type={modalType}
              action={cleanErrors}
            />
          </ModalContainer>
        </Portal>
      }
      <div className="email-validation-title">
        Validar email para registro
      </div>
      <Formik
        initialValues={emailRecoveryFormInitialValues}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("validation.email"))
            .required(t("validation.required")),
        })}
        onSubmit={handleSubmit}
      >
        {({handleSubmit, values, handleChange}) => (
          <form className="email-validation-token-form" onSubmit={handleSubmit}>
            <div className="inputs-container">
              <div className="input-content">
                <div className="input-label">
                  <span>{t("resetPass.email")}</span>
                </div>
                {/* <div className="double-input"> */}
                <Field name="email">
                  {(props: FieldProps) => (
                    <InputForm 
                      type="email"
                      {...props}
                    />  
                  )}
                </Field>
                {/* </div> */}
              </div>

              <div className="input-content">
                <div className="input-label">
                  <span>Código de verificación</span>
                </div>
                {/* <div className="double-input"> */}
                <Field name="token">
                  {(props: FieldProps) => (
                    <InputForm 
                      type="text"
                      {...props}
                    />  
                  )}
                </Field>
                <div className="link-container">
                  <a className="ink-container__text" href="#" onClick={() => setCodeActive(false)}>{"Aún no cuento con código de verificación"}</a>
                </div>
                {/* </div> */}
              </div>

              </div>
            {/* </div> */}
            <div className="captcha-container">
              {
                <ReCAPTCHA
                  sitekey={reCaptchaKey!}
                  ref={emailRecoveryCaptchaRef}
                />
              }
            </div>
            <div className="button-container">
              <Button 
              type={emailValidationTokenLoading ? "button" : "submit"}
                text={
                  !emailValidationTokenLoading
                  ? t("resetPass.buttonText")
                  : `${t("global.loading")}...`
                }
                fontSize={17}
              />
            </div>
          </form>
        )}
      </Formik>
    </S.EmailCodeVerificationForm>
  );
}

export default EmailCodeVerificationForm;
