import styled, {keyframes} from "styled-components";

interface Props {
  padding?: string;
  disabled?: boolean;
  active?: any;
  error?: any;
  fontSize?: string;
  activedMic?: boolean;
  height?: number;
}

const expansion = () => keyframes`
 0% {
  box-shadow: 0 0 0 0px rgba(255,0,0,.5);
 }
 100% {
  box-shadow: 0 0 0 5px rgba(255,0,0,.5);
 }
`

export const InputForm = styled.div<Props>`
  width: -webkit-fill-available;
  .input-container {
    background-color: ${({theme}) => theme.white};
    border: none;
    box-sizing: border-box;
    padding: ${({padding}) => padding ? padding :'5px 5px'};
    border-radius: 6px;
    display: flex;
    align-items: center;
    min-width: 100px;
    cursor: ${({disabled}) => disabled ? '' :'text'};
    opacity: ${({disabled}) => disabled ? '.4' :'1'};
    position: relative;
    .icon-input-form {
      margin-bottom: -5px;
      font-size: 14px;
    }
    .icon-input-pass {
      font-size: 12px;
      cursor: pointer;
      background-color: ${({theme}) => theme.softBlue};
      position: absolute;
      right: 0;
      height: 100%;
      width: 28px;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      color: ${({theme}) => theme.white};
    }
    input {
      border: none;
      background-color: transparent;
      width: 100%;
      font-family: "Open Sans";
      font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
      margin-left: 7px;
      color: ${({theme}) => theme.inputText};
      &:focus {
        outline: none;
      }
      &::placeholder { 
        color: ${({ theme }) => theme.inputPlaceholder};
        font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
        margin-left: 30px;
        font-family: "Open sans";
      }
    }
    textarea {
      border: none;
      background-color: transparent;
      width: 100%;
      font-family: "Open Sans";
      font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
      margin-left: 7px;
      height: ${({height}) => height ? height : '80'}px;
      resize: none;
      color: ${({theme}) => theme.inputText};
      &:focus {
        outline: none;
      }
      &::placeholder { 
        color: ${({ theme }) => theme.inputPlaceholder};
        font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
        margin-left: 30px;
        font-family: "Open sans";
      }
      &::-webkit-scrollbar {
          height: 4px;
          width: 20px;
          margin: 0;
          opacity: .9;
          padding-bottom: 10px;
          cursor: pointer;
        }
        ::-webkit-scrollbar-track {
          min-height: 5px !important;
        }
        ::-webkit-scrollbar-thumb {
          min-height: 30px !important;
        }
    }
    .mic-container {
      color: transparent;
      position: absolute;
      right: 7px;
      bottom: 7px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      animation: ${({activedMic}) => activedMic ? expansion() : null} 2s ease infinite;
      cursor: pointer;
      display: flex;
      .mic-icon {
        color: ${({activedMic, theme}) => !activedMic ? theme.primaryColor : theme.warning};
        margin: 3px auto;
      }
    }
    .play-record-button {
    }
  }
  .input-counter {
    text-align: end;
    font-size: 13px;
    opacity: 0.7;
  }
  .input-error {
    height: 15px;
    padding: 3px 0;
    font-size: 9px;
    display: flex;
    color: ${({theme}) => theme.red};
    align-items: center;
    i {
      margin-right: 2px;
    }
  }
`