import styled from "styled-components";

interface Props {
  fontSize?: number;
  color?: string;
  colorText?: string;
  height?: number;
  borderColor?: string;
  borderRadius?: number;
}

export const Button = styled.button<Props>`
  height: 100%;
  width: 100%;
  background-color: ${({theme, color}) => color ? color : theme.softBlue};
  border-radius: ${({borderRadius}) => borderRadius ? borderRadius + "px" : '17px'};
  color: ${({theme, colorText}) => colorText ? colorText : theme.white };;
  border: ${({borderColor}) => borderColor ? `1px ${borderColor} solid` : "none"};
  font-weight: 600; 
  padding: 7px 7px; 
  font-size: ${({fontSize}) => fontSize ? fontSize + 'px' : '12px'};
  font-family: 'Poppins';
  line-height: 16px;
  cursor: pointer;
`;