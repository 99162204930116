import * as S from "./Footer.style";

const migracionLogoBlanco = require('../../../assets/migracion-logo-blanco.png');

function Footer() {

  return (
    <S.Footer>
      <div className="footer-panels">
        <div className="footer-panel">
          <img 
            className="footer-panel__img" 
            alt="Migración" 
            src={migracionLogoBlanco}
          />
          <div className="footer-panel__social-networks">
            <div className="footer-panel__social-networks-icons">
              <i className="icon icon-facebook social-networks__icon"/>
              <i className="icon icon-instagram social-networks__icon"/>
              <i className="icon icon-twitter social-networks__icon"/>
              <i className="icon icon-youtube social-networks__icon"/>
            </div>
            <div className="footer-panel__social-networks-at">
              @MigracionCol
            </div>
          </div>
        </div>
        <div className="footer-panel">
          <div className="footer-panel__title"> Centro de contacto ciudadano:</div>
          <div className="footer-panel__title-line"></div>
          <div className="footer-panel__subtitle">Línea Conmutador:</div>
          <div className="footer-panel__text">+57 (601) 611 61 70</div>
          <div className="footer-panel__subtitle">Linea Anticorrupción:</div>
          <div className="footer-panel__text">+57 (601) 611 61 77</div>
          <div className="footer-panel__subtitle">Línea Nacional Gratuita:</div>
          <div className="footer-panel__text">01 8000 12 86 62</div>
          <div className="footer-panel__subtitle">Canal de Contacto Para PQRS de la Ciudadanía</div>
          <div className="footer-panel__text">Centro de Consulta Ciudadana - C3</div>
          <div className="footer-panel__subtitle">Correo electrónico únicamente para notificaciones de juzgados y avisos judiciales:</div>
          <div className="footer-panel__text">noti.judiciales@migracioncolombia.gov.co</div>
          <div className="footer-panel__subtitle">Correo electrónico únicamente para autoridades administrativas:</div>
          <div className="footer-panel__text">autoridades.administrativas@migracioncolombia.gov.co</div>
          <div className="footer-panel__subtitle">Denuncias presunto actos de corrupción: </div>
          <div className="footer-panel__text">soytransparente@migracioncolombia.gov.co</div>       
          {/* <div className="politics">
            <div className="footer-panel__text">Política de calidad y términos de uso</div>       
            <div className="footer-panel__separator footer-panel__separator--dashed"></div>
            <div className="footer-panel__text">Politica editorial</div>       
            <div className="footer-panel__separator footer-panel__separator--dashed"></div>
            <div className="footer-panel__text">Carta de trato digno a la ciudadanía</div>       
            <div className="footer-panel__separator footer-panel__separator--dashed"></div>
          </div>  */}
        </div>
        <div className="footer-panel">
          <div className="footer-panel__title">Horario de atención CFSM:</div>
          <div className="footer-panel__title-line"></div>  
          <div className="footer-panel__subtitle">Bogotá, D.C.</div>
          <div className="footer-panel__subtitle-divided">
            <div className="footer-panel__text">Lunes a viernes:</div>
            <div className="footer-panel__text">7:30 am a 12:00 m.1:00 p.m. a 4:30 p.m.</div>
          </div>
          <div className="footer-panel__separator"></div>
          <div className="footer-panel__subtitle">Cali, Ibagué, Medellín, Neiva y Tunja</div>
          <div className="footer-panel__subtitle-divided">
            <div className="footer-panel__text">Lunes a viernes:</div>
            <div className="footer-panel__text">8:00 a.m. a 12:00 m.1:00 p.m. a 5:00 p.m.</div>
          </div>
          <div className="footer-panel__separator"></div>
          <div className="footer-panel__subtitle">Resto del país:</div>
          <div className="footer-panel__subtitle-divided">
            <div className="footer-panel__text">Lunes a viernes:</div>
            <div className="footer-panel__text">8:00 am - 12:00 m.1:00 p.m - 5:00 p.m.</div>
          </div>
          <div className="footer-panel__separator"></div>
        </div>
      </div>
      <div className="footer-bottom">
        Copyright ©2021 Migración Colombia. Todos los derechos reservados
      </div>
    </S.Footer>
  );
}

export default Footer;