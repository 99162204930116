import styled from "styled-components";

interface Props {
  padding?: string;
  listActive?: boolean;
  error?: any;
  fontSize?: string;
  disabled?: boolean;
}

export const SelectForm = styled.div<Props>`
  height: 100%;
  width: 100%;
  position: relative;
  .input-container {
    cursor: ${({disabled}) => disabled ? 'default' :'pointer'};
    border: none;
    padding: ${({padding}) => padding ? padding :'5px 5px'};
    border-radius: 6px;
    display: flex;
    align-items: center;
    min-width: 100px;
    background-color: ${({theme}) => theme.white};
    opacity: ${({disabled}) => disabled ? '.4' :'1'};
    .icon-input-form {
      margin-bottom: -5px;
      font-size: 14px;
    }
    input {
      color: ${({theme}) => theme.inputText};
      border: none;
      background-color: transparent;
      width: 100%;
      font-family: "Open Sans";
      margin-left: 7px;
      cursor: ${({disabled}) => disabled ? 'default' :'pointer'};
      font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
      &:focus {
        outline: none;
      }
      &::placeholder { 
        color: ${({theme}) => theme.inputPlaceholder};
        font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
        margin-left: 30px;
        font-family: "Open sans";
      }
    }
    .icon-input-form-arrow {
      margin-bottom: ${({listActive}) => listActive ? '2px' : '-5px'};;
      color: ${({theme}) => theme.softBlue};
      font-size: 12px;
      transition: .3s ease;
      transform: ${({listActive}) => listActive ? 'rotate(180deg)' : 'none'};
      margin-left: auto;
    }
  }
  .select-list {
    position: absolute;
    background-color: ${({theme}) => theme.white};
    z-index: 1;
    display: ${({listActive}) => listActive ? 'block' :'none' };
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
    font-family: "Open Sans";
    font-size: ${({fontSize}) => fontSize ? fontSize :'12px'};
    .select-item {
      cursor: pointer;
      padding: 3px 7px;
      label {
        cursor: pointer;
      }
    }
    .non-select-item {
      cursor: pointer;
      padding: 3px 7px;
      background-color: #d1cfcf;
      label {
        cursor: pointer;
      }
    }
    ::-webkit-scrollbar{
      display: none;
    }
  }
  .input-error {
    height: 15px;
    padding: 3px 0;
    font-size: 9px;
    display: flex;
    color: ${({theme}) => theme.red};
    align-items: center;
    i {
      margin-right: 2px;
    }
  }
`