import { useEffect, useState } from "react";
import App from "../App";
import {
  ApolloClient,
  HttpLink,
  ApolloProvider,
  InMemoryCache,
  split
} from "@apollo/client";
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
// import { useTranslation } from "react-i18next";

function Apollo() {
  const [accessToken, setAccessToken] = useState<string | null>();
  const [client, setClient] = useState<any>();
  // const [t] = useTranslation("global");

  const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;

  interface Headers {
    Authorization?: string;
  }

  useEffect(() => {
    const getHeaders = () => {
      const headers: Headers = { };
      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }
      return headers;
    };
  
    const httpLink = new HttpLink({
      uri: `https://${GRAPHQL_ENDPOINT}`,
      headers: getHeaders(),
    });
  
    const wsLink = new GraphQLWsLink(
      createClient({
        url: `wss://${GRAPHQL_ENDPOINT}`,
        connectionParams: () => {
          return { headers: getHeaders() };
        },
        // shouldRetry: true
      }),
    );
  
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    );
  
  
    const client = new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache()
    });

    setClient(client);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  if (!client) {
    return <div>
      {`
        LOADING...
      `}
    </div>
  }

  return (
    <ApolloProvider client={client}>
      <App setAccessToken={setAccessToken} />
    </ApolloProvider>
  );
}

export default Apollo;
