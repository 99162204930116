import * as S from "./RegisterForm.style";
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

// Form
import * as Yup from "yup";
import { Field, FieldProps, Formik, FormikHelpers, useFormikContext } from "formik";

// Components
import Button from "../../../../shared/button";
import Portal from "../../../../shared/modals/portal";
import Tooltip from "../../../../shared/tooltip";
import InputForm from "../../../../shared/inputForm";
import SelectForm from "../../../../shared/selectForm";
import CustomModal from "../../../../shared/modals/customModal";
import ModalContainer from "../../../../shared/modals/modalContainer";

// Apollo
import { useMutation, useQuery } from "@apollo/client";
import { QUERY_GET_PAISES } from "../../../../../graphql/queries/Pais";
import { MUTATION_EMAIL_EXISTANCE, MUTATION_ID_EXISTANCE } from "../../../../../graphql/mutations/Auth";
import { QUERY_GET_DEPARTAMENTOS } from "../../../../../graphql/queries/Department";
import { QUERY_GET_CIUDADES_BY_DEPARTAMENTO, QUERY_GET_CIUDADES_BY_PAIS } from "../../../../../graphql/queries/Ciudad";
import { MUTATION_REGISTER } from "../../../../../graphql/mutations/Auth";

// Utils
import * as P from "../../../../../utils/pipes";
import { CONDICION_DISCAPACIDAD, CONDITIONS, SEX_TYPE, TYPE_DOCUMENTS } from "../../../../../utils/constants";

// Models
import { ObjectShape } from "yup/lib/object";
import { Pais } from "../../../../../models/dbModels/Pais";
import { Ciudad } from "../../../../../models/dbModels/Ciudad";
import { SelectItem } from "../../../../../models/logicModels/SelectItem";
import { Departamento } from "../../../../../models/dbModels/Departamento";
import { CommunityType } from "../../../../../models/dbModels/CommunityType";
import { QUERY_GET_PMCCOMMUNITY } from "../../../../../graphql/queries/CommunityType";

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

interface RegisterFields {
  name1: string,
  lastname1: string,
  name2: string,
  lastname2: string,
  typeDocument: string,
  razonSocial: string,
  document: string,
  sex: string,
  localPhone: string,
  phone: string,
  address: string,
  country: string,
  department: string,
  city?: number,
  country2: string,
  city2?: number,
  comunidadId: number,
  department2: string,
  conditionsQuestion: string,
  condicionDiscapacidad: string,
  email: string,
  emailConfirmation: string,
  password: string,
  passwordConfirmation: string,
  habeasData: boolean
}

interface Props {
  setEmail: Dispatch<SetStateAction<string>>,
  setCodeActive: Dispatch<SetStateAction<boolean>>,
  setEmailVerified: Dispatch<SetStateAction<boolean>>,
  email: string,
}

function RegisterForm({
  email,
  setEmail,
  setCodeActive,
  setEmailVerified
}: Readonly<Props>) {
  const registerCaptchaRef = useRef<any>(null);
  const { t } = useTranslation("global");

  const [formValidations, setFormValidations] = useState<ObjectShape>();

  const [register, { loading: registerLoading, error: registerError, reset: resetRegister }] = useMutation(MUTATION_REGISTER);
  const [emailExistance, { loading: emailExistanceLoading, error: emailExistanceError, reset: resetEmailExistance }] = useMutation(MUTATION_EMAIL_EXISTANCE);
  const [idExistance, { loading: idExistanceLoading, error: idExistanceError, reset: resetIdExistance }] = useMutation(MUTATION_ID_EXISTANCE);
  const [resMessage, setResMessage] = useState<string>('');
  const [idEmailError, setIdEmailError] = useState<boolean>(false);
  const [errorCaptcha, setErrorCaptcha] = useState<boolean>(false);

  const handleSubmit = (values: RegisterFields, formikHelpers: FormikHelpers<RegisterFields>) => {
    if (registerCaptchaRef.current.getValue()) {
      setErrorCaptcha(false);
      handleRegister(values, formikHelpers);
    } else {
      setErrorCaptcha(true);
      setResMessage("Error de Captcha");
      registerCaptchaRef.current.reset();
    }
  }

  const registerFormInitialValues: RegisterFields = {
    name1: '',
    lastname1: '',
    name2: '',
    lastname2: '',
    typeDocument: '',
    document: '',
    sex: '',
    localPhone: '',
    phone: '',
    address: '',
    country: '',
    department: '',
    country2: '',
    comunidadId: 1,
    department2: '',
    conditionsQuestion: '',
    condicionDiscapacidad: '',
    razonSocial: '',
    email: email,
    emailConfirmation: '',
    password: '',
    passwordConfirmation: '',
    habeasData: false
  }

  const initialFormValidations: ObjectShape = {
    name1: Yup.string()
      .min(3, t("validation.min"))
      .max(15, t("validation.max"))
      .required(t("validation.required"))
      .matches(/^[ÑáéíóúÁÉÍÓÚñA-Za-z _]*[ÑáéíóúÁÉÍÓÚñA-Za-z][ÑáéíóúÁÉÍÓÚñA-Za-z _]*$/, t("validation.onlyLetter")),
    lastname1: Yup.string()
      .min(3, t("validation.min"))
      .max(15, t("validation.max"))
      .required(t("validation.required"))
      .matches(/^[ÑáéíóúÁÉÍÓÚñA-Za-z _]*[ÑáéíóúÁÉÍÓÚñA-Za-z][ÑáéíóúÁÉÍÓÚñA-Za-z _]*$/, t("validation.onlyLetter")),
    name2: Yup.string()
      .min(3, t("validation.min"))
      .max(15, t("validation.max"))
      .matches(/^[ÑáéíóúÁÉÍÓÚñA-Za-z _]*[ÑáéíóúÁÉÍÓÚñA-Za-z][ÑáéíóúÁÉÍÓÚñA-Za-z _]*$/, t("validation.onlyLetter")),
    lastname2: Yup.string()
      .min(3, t("validation.min"))
      .max(15, t("validation.max"))
      .matches(/^[ÑáéíóúÁÉÍÓÚñA-Za-z _]*[ÑáéíóúÁÉÍÓÚñA-Za-z][ÑáéíóúÁÉÍÓÚñA-Za-z _]*$/, t("validation.onlyLetter")),
    typeDocument: Yup.string()
      .required(t("validation.required")),

    razonSocial: Yup.string().when('typeDocument', {
      is: 'NIT',
      then: Yup.string().required('Este campo es obligatorio.'),
      otherwise: Yup.string()
    }),

    document: Yup.string()
      .required(t("validation.required")),
    sex: Yup.string()
      .required(t("validation.required")),
    localPhone: Yup.string()
      .max(13, t("validation.13"))
      .required(t("validation.required")),
    phone: Yup.string()
      .required(t("validation.required")),
    address: Yup.string()
      .required(t("validation.required")),
    conditionsQuestion: Yup.string()
      .required(t("validation.required")),

    condicionDiscapacidad: Yup.string().when('conditionsQuestion', {
      is: 'Personas con Discapacidad',
      then: Yup.string().required('Este campo es obligatorio.'),
      otherwise: Yup.string()
    }),

    country: Yup.string()
      .required(t("validation.required")),
    city: Yup.string()
      .required(t("validation.required")),
    country2: Yup.string()
      .required(t("validation.required")),
    city2: Yup.string()
      .required(t("validation.required")),
    comunidadId: Yup.string()
      .required(t("validation.required")),
    email: Yup.string()
      .email(t("validation.email"))
      .required(t("validation.required")),
    emailConfirmation: Yup.string()
      .email(t("validation.email"))
      .required(t("validation.required"))
      .when("email", {
        is: (email: string) => (email && email.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref("email")],
          `${t("validation.matchEmail")}`
        ),
      }),
    password: Yup.string()
      .min(3, `${t("validation.min")}`)
      .required(`${t("validation.password")}`)
      .matches(
        // /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/i,
        /^(?=.*?[A-ZÁ-Ú])(?=.*?[a-zá-ú])(?=.*?\d).{6,}$/,
        `${t("validation.matches")}`
      ),
    passwordConfirmation: Yup.string()
      .min(3, `${t("validation.min")}`)
      .required(`${t("validation.confirmPass")}`)
      .when("password", {
        is: (password: string) => (password && password.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          `${t("validation.match")}`
        ),
      }),
    habeasData: Yup.bool().required('Este campo es obligatorio.').oneOf([true], 'Debes seleccionar Si.'),
  }

  const registerFunction = async (values: RegisterFields, formikHelpers: FormikHelpers<RegisterFields>) => {
    try {
      const {
        data: {
          registro: { message },
        },
      } = await register({
        variables: {
          primerNombre: P.onlyFirstCapital(values.name1).trim(),
          primerApellido: P.onlyFirstCapital(values.lastname1).trim(),
          segundoNombre: P.onlyFirstCapital(values.name2).trim(),
          segundoApellido: P.onlyFirstCapital(values.lastname2).trim(),
          tipoDni: values.typeDocument.trim(),
          razonSocial: values.razonSocial.trim(),
          numerodni: values.document.toString().toLowerCase().trim(),
          sexo: values.sex.trim(),
          telefono: values.localPhone.toString().trim(),
          celular: values.phone.toString().trim(),
          direccion: values.address.trim(),
          discapacidad: values.conditionsQuestion,
          condicionDiscapacidad: values.condicionDiscapacidad,
          ciudadnacimientoid: Number(values.city!),
          ciudadResidenciaId: Number(values.city2!),
          comunidadId: Number(values.comunidadId),
          email: values.email.toLowerCase().trim(),
          contrasena: values.password,
          captchaToken: registerCaptchaRef.current.getValue()
        },
      });
      setResMessage(message);
      formikHelpers.resetForm();
      registerCaptchaRef.current.reset();
    } catch (err: any) {
      setErrorCaptcha(false);
      setResMessage(err.message);
      registerCaptchaRef.current.reset();
    }
  }

  useEffect(() => {
    setFormValidations(initialFormValidations);
  }, [])

  const handleRegister = async (values: RegisterFields, formikHelpers: FormikHelpers<RegisterFields>) => {
    let quantityError = 0;
    try {
      await idExistance({
        variables: {
          numerodni: values.document.toString().toLowerCase().trim(),
        },
      });
    } catch (error: any) {
      setErrorCaptcha(false);
      setResMessage(error.message);
      registerCaptchaRef.current.reset();
      quantityError++;
      setIdEmailError(true);
      formikHelpers.setFieldValue("document", "");
    }

    try {
      await emailExistance({
        variables: {
          email: values.email.toLowerCase().trim(),
        },
      });
    } catch (error: any) {
      setErrorCaptcha(false);
      setResMessage(error.message);
      registerCaptchaRef.current.reset();
      quantityError++;
      setIdEmailError(true);
      formikHelpers.setFieldValue("email", "");
      formikHelpers.setFieldValue("emailConfirmation", "");
    }

    if (quantityError === 0) {
      await registerFunction(values, formikHelpers);
    }

    if (quantityError === 2) {
      setErrorCaptcha(false);
      setResMessage("El numero de identificación y el correo no están disponibles para registrar");
      formikHelpers.setFieldValue("document", "");
      formikHelpers.setFieldValue("email", "");
      formikHelpers.setFieldValue("emailConfirmation", "");
      registerCaptchaRef.current.reset();
    }
  }

  const cleanErrors = () => {
    resetRegister();
    setResMessage("");
    setErrorCaptcha(false);
    setIdEmailError(false);
    if (!registerLoading && !emailExistanceLoading && !idExistanceLoading && !registerError && !emailExistanceError && !idExistanceError) {
      setEmail("");
      setCodeActive(false);
      setEmailVerified(false);
    }
  }

  const modalTitle = useMemo(() => {
    if (registerLoading) {
      return "Cargando";
    } else if (registerError || emailExistanceError || idExistanceError) {
      return "Error";
    } else {
      return "Registrado";
    }
  }, [registerLoading, registerError, emailExistanceError, idExistanceError]);

  const modalDescription = useMemo(() => {
    if (registerLoading || emailExistanceLoading || idExistanceLoading) {
      return "";
    } else {
      return resMessage;
    }
  }, [registerLoading, resMessage, emailExistanceLoading, idExistanceLoading]);

  const modalType = useMemo(() => {
    if (registerLoading || emailExistanceLoading || idExistanceLoading) {
      return "loading";
    } else if (registerError || emailExistanceError || idExistanceError) {
      return "failed";
    } else {
      return "success";
    }
  }, [registerLoading, registerError, emailExistanceLoading, idExistanceLoading, emailExistanceError, idExistanceError]);

  return (
    <S.RegisterForm>
      {
        (
          registerLoading ||
          registerError ||
          errorCaptcha ||
          resMessage ||
          idEmailError ||
          emailExistanceLoading ||
          idExistanceLoading
        ) &&
        <Portal>
          <ModalContainer>
            <CustomModal
              title={modalTitle}
              description={modalDescription}
              type={modalType}
              action={cleanErrors}
            />
          </ModalContainer>
        </Portal>
      }
      <Formik
        initialValues={registerFormInitialValues}
        enableReinitialize={true}
        validationSchema={Yup.object(formValidations)}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <form className="register-form" onSubmit={props.handleSubmit}>
            <RegisterInputs
              formValidations={formValidations}
              setFormValidations={setFormValidations}
              initialFormValidations={initialFormValidations}
            />
            <div className="list-container">
              <ul>
                <li>
                  {t("register.items.item1.text1")}
                  <strong> {t("register.items.item1.text2")} </strong>
                  {t("register.items.item1.text3")}
                </li>
                <li>
                  {t("register.items.item2.text1")}
                  <strong> {t("register.items.item2.text2")}</strong>
                </li>
                <li>{t("register.items.item3.text1")}</li>
              </ul>
            </div>

            <div className={`box-accept`}>
              <div className="box-accept__title">Nota aclaratoria Habeas Data</div>
              <div className="box-accept__description">
              Al hacer clic el botón confirmar, usted acepta la remisión de la PQRS a Migración Colombia. Sus
datos serán recolectados y tratados conforme con la Política de Tratamiento de Datos. En la
opción consulta de PQRS podrá verificar el estado de la respuesta.
<p> 
En caso que la solicitud de información sea de naturaleza de identidad reservada, deberá
efectuar el respectivo trámite ante la Procuraduría General de la Nación, haciendo clic en el
siguiente link:
<a href="https://www.procuraduria.gov.co/PQRSDF/Pages/default.aspx" target="_blank">https://www.procuraduria.gov.co/PQRSDF/Pages/default.aspx</a>
</p>
<p>
Recuerde que, al radicar de manera anónima, usted acepta las condiciones conforme a lo
establecido en el artículo 38 de la Ley 190 de 1995; artículo 69; de la Ley 734 de 2002 y artículo
81 de la Ley 962 de 2005.
</p>
              </div>
              <div className={`box-accept__input-container `}>
                <span className="box-accept__input-label">Acepto</span>
                <div className="required-indicator">*</div>
                <div className="box-accept__input-radius">
                  <Field name="habeasData">
                    {(props: FieldProps) => (
                      <input type="radio"
                        id="si"
                        name={props.field.name}
                        checked={props.field.value}
                        value="true"
                        onChange={() => props.form.setFieldValue("habeasData", true)}
                      />
                    )}
                  </Field>
                  <label htmlFor="si">Sí</label>

                  <Field name="habeasData">
                    {(props: FieldProps) => (
                      <input type="radio"
                        id="no"
                        name={props.field.name}
                        checked={!props.field.value}
                        value="false"
                        onChange={() => props.form.setFieldValue("habeasData", false)}
                      />
                    )}
                  </Field>
                  <label htmlFor="no">No</label>
                </div>
              </div>
              {props.errors.habeasData && (
                <div className="habeasError">{props.errors.habeasData}</div>)
              }
            </div>

            <div className="captcha-container">
              {
                // env !== 'test' && env !== 'development' &&
                <ReCAPTCHA
                  sitekey={reCaptchaKey!}
                  ref={registerCaptchaRef}
                />
              }
            </div>
            <div className="button-container">
              <Button
                type={registerLoading || emailExistanceLoading || idExistanceLoading ? "button" : "submit"}
                text={
                  registerLoading || emailExistanceLoading || idExistanceLoading
                    ? `${t("global.loading")}...`
                    : t("register.buttonText")
                }
              />
            </div>
          </form>
        )}
      </Formik>
    </S.RegisterForm>
  );
}

interface PropsRegisterForm {
  formValidations?: ObjectShape,
  setFormValidations?: Dispatch<SetStateAction<ObjectShape | undefined>>,
  initialFormValidations?: ObjectShape,
  allDisabled?: boolean
}

function RegisterInputs({
  formValidations,
  setFormValidations,
  initialFormValidations
}: Readonly<PropsRegisterForm>) {

  const { t } = useTranslation("global");

  const { values } = useFormikContext<RegisterFields>();
  const { setFieldValue } = useFormikContext();

  const isCountryColombia = useMemo(() => {
    return values.country === "52"
      ? true
      : false
  }, [values.country])

  const isCountryColombia2 = useMemo(() => {
    return values.country2 === "52"
      ? true
      : false
  }, [values.country2])

  const {
    data: paisesdata,
  } = useQuery
      (
        QUERY_GET_PAISES,
      );

  const {
    data: departamentosdata,
  } = useQuery
      (
        QUERY_GET_DEPARTAMENTOS,
      );

  const {
    data: ciudadesdata,
  } = useQuery
      (
        isCountryColombia
          ? QUERY_GET_CIUDADES_BY_DEPARTAMENTO
          : QUERY_GET_CIUDADES_BY_PAIS,
        {
          variables: isCountryColombia
            ? {
              departamentoId: values.department
            }
            : {
              paisId: values.country
            }
        }
      );

  const {
    data: ciudadesdata2,
  } = useQuery
      (
        isCountryColombia2
          ? QUERY_GET_CIUDADES_BY_DEPARTAMENTO
          : QUERY_GET_CIUDADES_BY_PAIS,
        {
          variables: isCountryColombia2
            ? {
              departamentoId: values.department2
            }
            : {
              paisId: values.country2
            }
        }
      );

  const { data: communityType } = useQuery(QUERY_GET_PMCCOMMUNITY);

  const setInitialValidation = () => {
    setFormValidations?.(initialFormValidations!);
  }

  const setDepartmentValidation = () => {
    const departmentValidation: ObjectShape = {
      department: Yup.string()
        .required(t("validation.required"))
    }
    const validations = initialFormValidations;
    const mergedValidations = { ...validations, ...departmentValidation }
    setFormValidations?.(mergedValidations);
  }

  const setDepartment2Validation = () => {
    const department2Validation: ObjectShape = {
      department2: Yup.string()
        .required(t("validation.required"))
    }
    const validations = formValidations;
    const mergedValidations = { ...validations, ...department2Validation }
    setFormValidations?.(mergedValidations);
  }


  useEffect(() => {
    if (isCountryColombia) {
      setDepartmentValidation();
    } else {
      setInitialValidation();
    }
  }, [values.country])

  useEffect(() => {
    if (isCountryColombia2) {
      setDepartment2Validation();
    } else {
      setInitialValidation();
    }
  }, [values.country2])

  useEffect(() => {
    if (isCountryColombia) {
      let isInList = departamentosdata?.pmcdepartamento?.find((departamento: Pais) => departamento.id.toString() === values.department.toString());

      if (!isInList) {
        setFieldValue('department', "")
        setFieldValue('city', "")
      }
    }
    if (isCountryColombia2) {
      let isInList = departamentosdata?.pmcdepartamento?.find((departamento: Pais) => departamento.id.toString() === values.department2.toString());

      if (!isInList) {
        setFieldValue('department2', "")
        setFieldValue('city2', "")
      }
    }

  }, [departamentosdata])

  useEffect(() => {
    let isInList = ciudadesdata?.pmcciudad?.find((ciudad: Ciudad) => ciudad.id.toString() === values.city!.toString());

    if (!isInList) {
      setFieldValue('city', "")
    }

  }, [ciudadesdata])

  useEffect(() => {
    let isInList = ciudadesdata2?.pmcciudad?.find((ciudad: Ciudad) => ciudad.id.toString() === values.city2!.toString());

    if (!isInList) {
      setFieldValue('city2', "")
    }

  }, [ciudadesdata2])
  
/*   const formikContext  = useFormikContext();
  const selectedCondition = values.conditionsQuestion; */

  return (
    <div className="inputs-container">
      <div className="input-content">
        <div className="input-label">
          <span>{t("register.name1")}</span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.name1")}
            />
          </div>
        </div>
        <Field name="name1">
          {(props: FieldProps) => (
            <InputForm
              type="text"
              {...props}
            />
          )}
        </Field>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>{t("register.name2")}</span>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.name2")}
            />
          </div>
        </div>
        <Field name="name2">
          {(props: FieldProps) => (
            <InputForm
              type="text"
              {...props}
            />
          )}
        </Field>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>{t("register.lastname1")}</span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.lastname1")}
            />
          </div>
        </div>
        <Field name="lastname1">
          {(props: FieldProps) => (
            <InputForm
              type="text"
              {...props}
            />
          )}
        </Field>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>{t("register.lastname2")}</span>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.lastname2")}
            />
          </div>
        </div>
        <Field name="lastname2">
          {(props: FieldProps) => (
            <InputForm
              type="text"
              {...props}
            />
          )}
        </Field>
      </div>

      <div className="input-content">
        <div className="input-label">
          <span>{t("register.typeDocument")}</span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
          <Field name="typeDocument">
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                items={TYPE_DOCUMENTS}
              />
            )}
          </Field>
        </div>
      </div>

      { values.typeDocument === 'NIT' &&
        <div className="input-content">
          <div className="input-label">
            <span>{t("register.razonSocial")}</span>
            <div className="required-indicator">*</div>
          </div>
          <div className="double-input">
            <Field name="razonSocial">
              {(propsInput: FieldProps) => (
                <InputForm
                  type="text"
                  {...propsInput}
                />
              )}
            </Field>
          </div>
        </div>
      }

      <div className="input-content">
        <div className="input-label">
          <span>{t("register.document")}</span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.document")}
            />
          </div>
        </div>
        <div className="double-input">
          <Field name="document">
            {(propsInput: FieldProps) => (
              <InputForm
              type={values.typeDocument === "Pasaporte"  || values.typeDocument === "Documento extranjero"  ? "text" : "number"}
                {...propsInput}
              />
            )}
          </Field>
        </div>
      </div>

      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.sex")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
          <Field name="sex">
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                items={SEX_TYPE}
              />
            )}
          </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.localPhone")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.localPhone")}
            />
          </div>
        </div>
        <div className="double-input">
          <Field name="localPhone">
            {(props: FieldProps) => (
              <InputForm
                type="number"
                {...props}
              />
            )}
          </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.phone")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.phone")}
            />
          </div>
        </div>
        <div className="double-input">
          <Field name="phone">
            {(props: FieldProps) => (
              <InputForm
                type="number"
                {...props}
              />
            )}
          </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.address")}
          </span>
          <div className="required-indicator">*</div>
          <div className="tooltip-container">
            <Tooltip
              text={t("createPqrs.indicators.address")}
            />
          </div>
        </div>
        <div className="double-input">
          <Field name="address">
            {(props: FieldProps) => (
              <InputForm
                type="text"
                {...props}
              />
            )}
          </Field>
        </div>
      </div>

      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.conditionsQuestion")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <Field name="conditionsQuestion">
          {(props: FieldProps) => (
            <SelectForm
              {...props}
              items={CONDITIONS}
            />
          )}
        </Field>
      </div>

      {values.conditionsQuestion === 'Personas con Discapacidad' &&
        <div className="input-content">
          <div className="input-label">
            <span>
              {t("createPqrs.condicionDiscapacidad")}
            </span>
            <div className="required-indicator">*</div>
          </div>
          <Field name="condicionDiscapacidad">
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                items={CONDICION_DISCAPACIDAD}
              />
            )}
          </Field>
        </div>
      }

      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.country")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
          <Field name="country">
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                items={
                  paisesdata?.pmcpais.map((pais: Pais) => {
                    const paisItem: SelectItem = {
                      value: pais.id.toString(),
                      text: pais.pais
                    }
                    return (paisItem);
                  })
                }
              />
            )}
          </Field>
        </div>
      </div>
      {
        isCountryColombia &&
        <div className="input-content">
          <div className="input-label">
            <span>
              {t("createPqrs.department")}
            </span>
            <div className="required-indicator">*</div>
          </div>
          <div className="double-input">
            <Field name="department">
              {(props: FieldProps) => (
                <SelectForm
                  {...props}
                  items={
                    departamentosdata?.pmcdepartamento.map((departamento: Departamento) => {
                      const departamentoItem: SelectItem = {
                        value: departamento.id.toString(),
                        text: departamento.departamento
                      }
                      return (departamentoItem);
                    })
                  }
                />
              )}
            </Field>
          </div>
        </div>
      }
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.city")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
          <Field name="city">
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                disabled={
                  (isCountryColombia && values.department === "") || values.country === ""
                }
                items={
                  ciudadesdata?.pmcciudad.map((ciudad: Ciudad) => {
                    const ciudadItem: SelectItem = {
                      value: ciudad.id.toString(),
                      text: ciudad.ciudad
                    }
                    return (ciudadItem);
                  })
                }
              />
            )}
          </Field>
        </div>
      </div>
      <div className="input-content">
        <div className="input-label">
          <span>
            {t("createPqrs.country2")}
          </span>
          <div className="required-indicator">*</div>
        </div>
        <div className="double-input">
          <Field name="country2">
            {(props: FieldProps) => (
              <SelectForm
                {...props}
                items={
                  paisesdata?.pmcpais.map((pais: Pais) => {
                    const paisItem: SelectItem = {
                      value: pais.id.toString(),
                      text: pais.pais
                    }
                    return (paisItem);
                  })
                }
              />
            )}
          </Field>
        </div>
      </div>
      {
        isCountryColombia2 &&
        <div className="input-content">
          <div className="input-label">
            <span>
              {t("createPqrs.department2")}
            </span>
            <div className="required-indicator">*</div>
          </div>
          <div className="double-input">
            <Field name="department2">
              {(props: FieldProps) => (
                <SelectForm
                  {...props}
                  items={
                    departamentosdata?.pmcdepartamento.map((departamento: Departamento) => {
                      const departamentoItem: SelectItem = {
                        value: departamento.id.toString(),
                        text: departamento.departamento
                      }
                      return (departamentoItem);
                    })
                  }
                />
              )}
            </Field>
          </div>
        </div>
      }
      <div className="inputs-flex">
        <div className="input-content">
          <div className="input-label">
            <span>
              {t("createPqrs.city2")}
            </span>
            <div className="required-indicator">*</div>
          </div>
          <div className="double-input">
            <Field name="city2">
              {(props: FieldProps) => (
                <SelectForm
                  {...props}
                  disabled={
                    (isCountryColombia2 && values.department2 === "") || values.country2 === ""
                  }
                  items={
                    ciudadesdata2?.pmcciudad.map((ciudad: Ciudad) => {
                      const ciudadItem: SelectItem = {
                        value: ciudad.id.toString(),
                        text: ciudad.ciudad
                      }
                      return (ciudadItem);
                    })
                  }
                />
              )}
            </Field>
          </div>
        </div>

        <div className="input-content">
          <div className="input-label">
            <span>
              {t("createPqrs.communityType")}
            </span>
            <div className="required-indicator">*</div>
          </div>
          <div className="double-input">
            <Field name="comunidadId">
              {(props: FieldProps) => (
                <SelectForm
                  {...props}
                  items={
                    communityType?.pmccomunidad.map((comunity:CommunityType ) => {
                      const data: SelectItem = {
                        value: comunity.id,
                        text: comunity.nombreComunidad
                      }
                      return (data);
                    })
                  }
                />
              )}
            </Field>
          </div>
        </div>
      </div>

      <div className="inputs-flex">
        <div className="input-content">
          <div className="input-label">
            <span>{t("register.email")}</span>
            <div className="required-indicator">*</div>
            <div className="tooltip-container">
              <Tooltip
                text={t("createPqrs.indicators.email")}
              />
            </div>
          </div>
          <Field name="email">
            {(props: FieldProps) => (
              <InputForm
                type="text"
                disabled={true}
                copyPasteBlocked={true}
                {...props}
              />
            )}
          </Field>
        </div>
        <div className="input-content">
          <div className="input-label">
            <span>{t("register.emailConfirmation")}</span>
            <div className="required-indicator">*</div>
            <div className="tooltip-container">
              <Tooltip
                text={t("createPqrs.indicators.email")}
              />
            </div>
          </div>
          <div className="double-input">
            <Field name="emailConfirmation">
              {(props: FieldProps) => (
                <InputForm
                  type="text"
                  copyPasteBlocked={true}
                  {...props}
                />
              )}
            </Field>
          </div>
        </div>
      </div>

      <div className="inputs-flex inputs-flex--password">
        <div className="input-content">
          <div className="input-label">
            <span>{t("register.password")}</span>
            <div className="required-indicator">*</div>
            <div className="tooltip-container">
              <Tooltip
                text={t("createPqrs.indicators.password")}
              />
            </div>
          </div>
          <div className="double-input">
            <Field name="password">
              {(props: FieldProps) => (
                <InputForm
                  type="password"
                  copyPasteBlocked={true}
                  {...props}
                />
              )}
            </Field>
          </div>
        </div>
        <div className="input-content">
          <div className="input-label">
            <span>{t("register.passwordConfirmation")}</span>
            <div className="required-indicator">*</div>
            <div className="tooltip-container">
              <Tooltip
                text={t("createPqrs.indicators.password")}
              />
            </div>
          </div>
          <div className="double-input">
            <Field name="passwordConfirmation">
              {(props: FieldProps) => (
                <InputForm
                  type="password"
                  copyPasteBlocked={true}
                  {...props}
                />
              )}
            </Field>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterForm;
