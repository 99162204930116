import { gql } from "@apollo/client";

export const CONSULT_PQRS = gql`
  query CONSULT_PQRS (
    $numeroRadicado: String!
    $captchaToken: String
  ) {
    pqrsPorNumeroRadicado(
      numeroRadicado: $numeroRadicado
      captchaToken: $captchaToken
    ) {
      actualizadoEn
      agregadoEn
      comentario
      estado
      peticion
      sla
      numeroRadicado
      comentario
    }
  }
`

export const CONSULT_ALL_PQRS = gql`
  query CONSULT_ALL_PQRS (
    $offset: Int
  ) {
    pmcpqrs(order_by: {id: desc}, limit: 10, offset: $offset) {
      id
      estado
      numeroRadicado
      agregadoEn
      agregadoPor
      segundoContactoId
      contactoId
      comentario
      pmccontacto {
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        email
      }
      pmccontactoBySegundocontactoid {
        id
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        email
      }
      pmchistorials(order_by: {actualizadoEn: desc}, limit: 1) {
        actualizadoEn
        actualizadoPor
        comentario
        comentarioInterno
        estado
        reasignadoA
        reasignadoEn
        reasignadoPor
        aprobacion
      }
      pmccentrofacilitador {
        tipoCentro
        ubicacion
      }
      pmctramiteyservicio {
        tipoTramite
        nombre
        id
      }
      pmcsubcategorium {
        id
        subcategoria
      }
      pmccategorium {
        id
        categoria
        pmctipopeticion {
          id
          nombre
        }
      }
    }
    pmcpqrs_aggregate {
      aggregate {
        count
      }
    }
  }
`

export const CONSULT_ALL_PQRS_FILTERED = gql`
  query CONSULT_ALL_PQRS(
    $offset: Int, 
    $requestStatus: String, 
    $settledNumber: String
  ) {
    pmcpqrs(
      order_by: {
        id: desc
      }, 
      limit: 10, 
      offset: $offset, 
      where: {
        estado: {
          _eq: $requestStatus
        }, 
        numeroRadicado: {
          _ilike: $settledNumber
        }
      }
    ) {
      id
      estado
      numeroRadicado
      agregadoEn
      agregadoPor
      segundoContactoId
      contactoId
      comentario
      pmccontacto {
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        email
      }
      pmccontactoBySegundocontactoid {
        id
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        email
      }
      pmchistorials(order_by: {actualizadoEn: desc}, limit: 1) {
        actualizadoEn
        actualizadoPor
        comentario
        comentarioInterno
        estado
        reasignadoA
        reasignadoEn
        reasignadoPor
        aprobacion
      }
      pmccentrofacilitador {
        tipoCentro
        ubicacion
      }
      pmctramiteyservicio {
        tipoTramite
        nombre
        id
      }
      pmcsubcategorium {
        id
        subcategoria
      }
      pmccategorium {
        id
        categoria
        pmctipopeticion {
          id
          nombre
        }
      }
    }
    pmcpqrs_aggregate(where: {estado: {_eq: $requestStatus}, numeroRadicado: {_ilike: $settledNumber}}) {
      aggregate {
        count
      }
    }
  }
`

export const CONSULT_ALL_PQRS_FILTERED_STATUS = gql`
  query CONSULT_ALL_PQRS(
    $offset: Int, 
    $requestStatus: String
  ) {
    pmcpqrs(
      order_by: {
        id: desc
      }, 
      limit: 10, 
      offset: $offset, 
      where: {
        estado: {
          _eq: $requestStatus
        }
      }
    ) {
      id
      estado
      numeroRadicado
      agregadoEn
      agregadoPor
      segundoContactoId
      contactoId
      comentario
      pmccontacto {
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        email
      }
      pmccontactoBySegundocontactoid {
        id
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        email
      }
      pmchistorials(order_by: {actualizadoEn: desc}, limit: 1) {
        actualizadoEn
        actualizadoPor
        comentario
        comentarioInterno
        estado
        reasignadoA
        reasignadoEn
        reasignadoPor
        aprobacion
      }
      pmccentrofacilitador {
        tipoCentro
        ubicacion
      }
      pmctramiteyservicio {
        tipoTramite
        nombre
        id
      }
      pmcsubcategorium {
        id
        subcategoria        
      }
      pmccategorium {
        id
        categoria
        pmctipopeticion {
          id
          nombre
        }
      }
    }
    pmcpqrs_aggregate(where: {estado: {_eq: $requestStatus}}) {
      aggregate {
        count
      }
    }
  }
`

export const CONSULT_ALL_PQRS_FILTERED_REQUEST_NUMBER = gql`
  query CONSULT_ALL_PQRS(
    $offset: Int,
    $settledNumber: String
  ) {
    pmcpqrs(
      order_by: {
        id: desc
      }, 
      limit: 10, 
      offset: $offset, 
      where: {
        numeroRadicado: {
          _ilike: $settledNumber
        }
      }
    ) {
      id
      estado
      numeroRadicado
      agregadoEn
      agregadoPor
      segundoContactoId
      contactoId
      comentario
      pmccontacto {
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        email
      }
      pmccontactoBySegundocontactoid {
        id
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        email
      }
      pmchistorials(order_by: {actualizadoEn: desc}, limit: 1) {
        actualizadoEn
        actualizadoPor
        comentario
        comentarioInterno
        estado
        reasignadoA
        reasignadoEn
        reasignadoPor
        aprobacion
      }
      pmccentrofacilitador {
        tipoCentro
        ubicacion
      }
      pmctramiteyservicio {
        tipoTramite
        nombre
        id
      }
      pmcsubcategorium {
        id
        subcategoria
      }
      pmccategorium {
        id
        categoria
        pmctipopeticion {
          id
          nombre
        }
      }
    }
    pmcpqrs_aggregate(where: {numeroRadicado: {_ilike: $settledNumber}}) {
      aggregate {
        count
      }
    }
  }
`

export const GET_DOCUMENTS_BY_PQRS = gql`
  query GET_DOCUMENTS_BY_PQRS(
    $pqrsId: Int!
  ) {
    archivosPqrs(pqrsId: $pqrsId) {
      message
    }
  }
`;