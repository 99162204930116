import * as S from "./LoadingModal.style";

const migracionTitle = `${process.env.REACT_APP_STORAGE_ASSETS}/migracion-title.png`;
const migracionIcon = `${process.env.REACT_APP_STORAGE_ASSETS}/migracion-icon.png`;

interface  Props {
  title: string, 
  description?: string
}

function LoadingModal({title, description}: Props) {
  return(
    <S.LoadingModal>
      <div className="loading-modal-box">
        <div className="loading-modal-box__imgs">
          <img className="loading-modal-box__img-icon" alt="" src={migracionIcon} /> 
          <img className="loading-modal-box__img-title" alt="" src={migracionTitle} />
        </div>
        <div className="loading-modal-box__text">
          {title}
          <div className="points-container">
            <div className="dot-pulse"></div> 
          </div>
        </div>
      </div>
    </S.LoadingModal>
  )
}

export default LoadingModal;