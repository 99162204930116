
import { gql } from "@apollo/client";

export const QUERY_GET_CIUDADES_BY_DEPARTAMENTO = gql`
  query QUERY_GET_CIUDADES_BY_DEPARTMENT(
    $departamentoId: Int!
  ) {
  pmcciudad(where: {departamentoid: {_eq: $departamentoId}}) {
    ciudad
    id
    iso
    departamentoid
    paisid
  }
}
`
  
export const QUERY_GET_CIUDADES_BY_PAIS = gql`
  query QUERY_GET_CIUDADES_BY_PAIS(
    $paisId: Int!
  ) {
  pmcciudad(where: {paisid: {_eq: $paisId}}) {
    ciudad
    id
    iso
    departamentoid
    paisid
  }
}
`
  