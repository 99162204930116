import { useSelector, useDispatch } from 'react-redux';
import { User } from '../models/dbModels/User';
import { userDataChange } from '../redux/reducers/authSlice';
import { RootState } from '../redux/store';

export default function useUserData() {
  const dispatch = useDispatch();
  const { userData } = useSelector((state: RootState) => state.auth);

  const saveUserData = (user : User) => {
    if( user ) {
      dispatch(userDataChange(user));       
    } 
  }

  return {
    userData: userData,
    saveUserData: saveUserData
  };
}