import { useEffect, useState } from "react";
import { Field, FieldProps, Formik, FormikHelpers } from "formik";
import Button from "../../shared/button";
import * as S from "./ChangePassword.style";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import InputForm from "../../shared/inputForm";
import { useLocation,  } from "react-router-dom";

function ChangePassword() {
  // const history = useHistory(); 
  const location = useLocation();
  const {t, i18n: { language } } = useTranslation("global");
  const [ linkInfo, setLinkInfo ] = useState<object>();
  const [ passChanged, setPassChanged ] = useState<boolean>(false);

  useEffect(() => {
    const searchLocation = location.search;
    const params = new URLSearchParams(searchLocation);
    setLinkInfo({ 
      token: params.get("token"),
      email: params.get("email")
    })
    if (!params.get("token") || !params.get("email")) {
      // history?.push('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  interface  changePassFields {
    password: string,
    passwordConfirmation: string
  }

  const changePassFormInitialValues: changePassFields = {
    password: '',
    passwordConfirmation: ''
  }

  const handleSubmit = async (values: changePassFields, { resetForm }: FormikHelpers<changePassFields>) => {
    setPassChanged(true);
  };

  return (
    <S.ChangePass>
      <div className="change-pass">
        <div className="change-pass__left">
          <div className="box">
            <i className="box__icon icon icon-reestablecer-contrasea"/>
            <div className="box__text">{t("changePass.pretitle")}</div>
            <div className="box__text-big">{t("changePass.title")}</div>
          </div>
        </div>
        <div className="change-pass__right">
          {
            passChanged 
            ? <>
              <div className="message-box">
                <div className="message-box__title">
                  <i className="message-box__title icon icon-solicitud-radicada"></i>
                  CAMBIO DE CONTRASEÑA EXITOSO
                </div>
                <div className="message-box__message">
                  A su correo electrónico debió llegar un mensaje con las
                  instrucciones para reestablecer la contraseña.
                </div>
              </div>
              <div className="button-container">
                <Button 
                  text={t("changePass.continue")}
                  fontSize={17}
                />
              </div>
            </>
            : <Formik
              initialValues={changePassFormInitialValues}
              validationSchema={Yup.object({
                password: Yup.string()
                  .required(`${t("validation.password")}`)
                  .matches(
                    /^.*(?=.{6,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/i,
                    `${t("validation.matches")}`
                  ),
                passwordConfirmation: Yup.string()
                  .required(`${t("validation.confirmPass")}`)
                  .when("password", {
                    is: (password: string) => (password && password.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                      [Yup.ref("password")],
                      `${t("validation.match")}`
                    ),
                  }),
              })}
              onSubmit={handleSubmit}
            >
              {({handleSubmit, values, handleChange}) => (
                  <form className="change-pass-form" onSubmit={handleSubmit}>
                    <div className="inputs-container">

                      <div className="inputs-flex inputs-flex--password">

                        <div className="input-content">
                          <div className="input-label">
                            <span>{t("changePass.password")}</span>
                          </div>
                          <div className="double-input">
                          <Field name="password">
                            {(props: FieldProps) => (
                              <InputForm 
                                type="password"
                                {...props}
                              />  
                            )}
                          </Field>
                          </div>
                        </div>

                        <div className="input-content">
                          <div className="input-label">
                            <span>{t("changePass.passwordConfirmation")}</span>
                          </div>
                          <div className="double-input">
                          <Field name="passwordConfirmation">
                            {(props: FieldProps) => (
                              <InputForm 
                                type="password"
                                {...props}
                              />  
                            )}
                          </Field>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="button-container">
                      <Button 
                        type="submit"
                        text={t("changePass.buttonText")}
                        fontSize={17}
                      />
                    </div>
                  </form>
              )}
            </Formik>
          }
        </div>
      </div>
    </S.ChangePass>
  );
}

export default ChangePassword;
